import React, { useRef } from "react";
import axios from "axios";
import ProfileMenu from "../Profile/ProfileMenu";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Select from "react-select";
import { getCategoryList } from "../../store/action/categoryAction";
import { getActivityList } from "../../store/action/activityAction";
import { getTagList } from "../../store/action/tagAction";
import { getEventDetail } from "../../store/action/eventAction";

const loadScript = (url, callback) => {
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.src = url;

  script.onload = callback;

  document.head.appendChild(script);
};

function EditEvent() {
  const dispatch = useDispatch();
  // const { iEventId } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { iEventId } = state || {};

  // console.log("iEventId------------------>", iEventId);

  const autocompleteRef = useRef(null);
  const autocomplete = useRef(null);
  const mapRef = useRef(null);
  const map = useRef(null);
  // const marker = useRef(null);

  const vUniqueCode =
    // sessionStorage.getItem("vUniqueCode") ||
    localStorage.getItem("vUniqueCode");

  const [selectedLocation, setSelectedLocation] = React.useState(null);

  const [marker, setMarker] = React.useState(null);

  const [showMap, setShowMap] = React.useState(true);
  const [latlng, setLatlng] = React.useState({ lat: 0, lng: 0 });
  const [latitude, setLatitude] = React.useState("");
  const [longitude, setLongitude] = React.useState("");
  const [placeId, setPlaceId] = React.useState("");
  const [country, setCountry] = React.useState("");

  const { categoryList } = useSelector((state) => state.category);
  const { activityList } = useSelector((state) => state.activityList);
  const { tagList } = useSelector((state) => state.tagList);
  const { eventDetail } = useSelector((state) => state.eventDetail);

  const [updateSuccess, setUpdateSuccess] = React.useState(false);

  // variables for Edit event
  const [categoryId, setCategoryId] = React.useState("");
  const [activityId, setActivityId] = React.useState("");
  const [event, setEvent] = React.useState("");
  const [limit, setLimit] = React.useState("");
  const [type, setType] = React.useState("");
  const [amount, setAmount] = React.useState("");

  const [location, setLocation] = React.useState("");
  // const [eventPurpose, setEventPurpose] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [eventDate, setEventDate] = React.useState("");
  const [selectedTags, setSelectedTags] = React.useState([]);
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [imageFileName, setImageFileName] = React.useState("");
  const [profilePictureUrl, setProfilePictureUrl] = React.useState("");

  // handle functions
  const handleTagId = (selectedOptions) => {
    setSelectedTags(selectedOptions);
  };
  const handleCategoryChange = (event) => {
    setCategoryId(event.target.value);
  };

  const handleActivityChange = (event) => {
    setActivityId(event.target.value);
  };
  const handleEventChange = (event) => {
    setEvent(event.target.value);
  };
  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };
  const handleLocationChange = (event) => {
    setLocation(event.target.value);

    setShowMap(false);
  };
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };
  const handleTypeChange = (event) => {
    if (event.target.value === "Public") {
      setAmount("");
    }
    setType(event.target.value);
  };
  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };
  // const handleEventPurposeChange = (event) => {
  //   setEventPurpose(event.target.value);
  // };
  const handleEventDateChange = (event) => {
    setEventDate(event.target.value);
  };
  const handleImage = (event) => {
    handleImageChange(event);
  };

  // for event details
  React.useEffect(() => {
    // if (!eventDetail) {
      dispatch(
        getEventDetail({
          iEventId: iEventId,
          // vUniqueCode: vUniqueCode,
        })
      );
    // }
  }, [dispatch, iEventId]);

  // React.useEffect(() => {
  //   if (!eventDetail && vUniqueCode) {
  //     dispatch(
  //       getEventDetail({
  //         iEventId: iEventId,
  //         vUniqueCode: vUniqueCode,
  //       })
  //     );
  //   }
  // }, [dispatch, iEventId, vUniqueCode]);

  // handle image selection
  const allowedImageTypes = React.useMemo(
    () => ["image/jpeg", "image/png", "image/jpg"],
    []
  );

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (allowedImageTypes.includes(file.type)) {
        setSelectedImage(file);
        setImageFileName(file.name);
        const reader = new FileReader();
        reader.onload = (e) => {
          setProfilePictureUrl(e.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert(
          "Unsupported file type. Please select a .jpg, .jpeg, or .png file."
        );
        e.target.value = null;
      }
    }
  };

  // for prefill profile data
  React.useEffect(() => {
    if (eventDetail) {
      setCategoryId(eventDetail.iCategoryId);
      setActivityId(eventDetail.iActivityId);
      setEvent(eventDetail.vEvent);
      setLimit(eventDetail.vLimit);
      setType(eventDetail.eType);
      setAmount(eventDetail.vAmount);
      setLocation(eventDetail.vLocation);
      setDescription(eventDetail.tDescription);
      // setEventPurpose(eventDetail.eEventPurpose);
      let tags = [];
      for (let event of eventDetail.tags) {
        tags.push({ value: event.iTagId, label: event.vTag });
      }
      setSelectedTags(tags);

      setEventDate(new Date(eventDetail.dtEvent).toISOString().split("T")[0]);
      setProfilePictureUrl(eventDetail.vImage);
      setLatitude(eventDetail.vLatitude);
      setLongitude(eventDetail.vLongitude);
      setPlaceId(eventDetail.vPlaceId);
      setCountry(eventDetail.vCountry);
    }
  }, [eventDetail, setSelectedTags]);

  // for CategoryList
  React.useEffect(() => {
    if (!categoryList) {
      dispatch(
        getCategoryList({
          eFeature: "Yes",
          vCategory: "",
        })
      );
    }
  }, [categoryList, dispatch]);

  // for Activity List
  React.useEffect(() => {
    if (categoryId !== "") {
      dispatch(
        getActivityList({
          eFeature: "Yes",
          iCategoryId: categoryId,
        })
      );
    }
  }, [categoryId, dispatch]);

  // for Tag List
  React.useEffect(() => {
    if (!tagList) {
      dispatch(
        getTagList({
          eFeature: "",
        })
      );
    }
  }, [tagList, dispatch]);

  // for edit event data
  const handleEditEvent = React.useCallback(
    async (e) => {
      e.preventDefault();
      // const vUniqueCode = sessionStorage.getItem("vUniqueCode");
      const vUniqueCode = localStorage.getItem("vUniqueCode");

      const formData = new FormData();
      formData.append("vUniqueCode", vUniqueCode);
      formData.append("iEventId", iEventId);
      formData.append("iCategoryId", categoryId);
      formData.append("iActivityId", activityId);
      formData.append("vEvent", event);
      formData.append("vLimit", limit);
      formData.append("eType", type);
      formData.append("vAmount", amount);
      formData.append("vLocation", location);
      // formData.append("eEventPurpose", eventPurpose);
      formData.append("tDescription", description);
      formData.append("dtEvent", eventDate);

      formData.append(
        "iTagIds",
        JSON.stringify(selectedTags.map((tag) => tag.value))
      );

      formData.append("vImage", selectedImage);
      formData.append("vWebpImage", imageFileName);

      formData.append("vLatitude", latitude);
      formData.append("vLongitude", longitude);
      formData.append("vPlaceId", placeId);
      formData.append("vCountry", country);

      if (
        categoryId.length > 0 ||
        activityId.length > 0 ||
        event.length > 0 ||
        limit.length > 0 ||
        type.length > 0 ||
        location.length > 0 ||
        // eventPurpose.length > 0 ||
        description.length > 0 ||
        eventDate.length > 0 ||
        selectedTags.length > 0 ||
        selectedImage
      ) {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/event/update`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (response.status === 200) {
            setUpdateSuccess(true);

            setTimeout(() => {
              setUpdateSuccess(false);
              navigate("/my-event");
              window.location.reload();
            }, 1500);
          }
        } catch (error) {}
      }
    },
    [
      iEventId,
      categoryId,
      activityId,
      event,
      limit,
      type,
      amount,
      location,
      // eventPurpose,
      description,
      eventDate,
      selectedTags,
      selectedImage,
      imageFileName,
    ]
  );

  React.useEffect(() => {
    const handlePlaceSelect = () => {
      const addressObject = autocomplete.current.getPlace();

      if (addressObject && addressObject.formatted_address) {
        const newLatlng = {
          lat: addressObject.geometry.location.lat(),
          lng: addressObject.geometry.location.lng(),
        };
        setLatlng(newLatlng);
        setLatitude(newLatlng.lat);
        setLongitude(newLatlng.lng);

        setLocation(addressObject.formatted_address);

        const placeId = addressObject.place_id;
        setPlaceId(placeId);

        const countryComponent = addressObject.address_components.find(
          (component) => component.types.includes("country")
        );

        // console.log(" countryComponent", countryComponent);

        if (countryComponent) {
          const country = countryComponent.long_name;

          setCountry(country);
        } else {
          setCountry(" ");
        }

        setMarker((prevMarker) => {
          if (prevMarker) {
            prevMarker.setMap(null);
          }
          const newMarker = new window.google.maps.Marker({
            position: newLatlng,
            map: map.current,
          });

          // Check if map.current is not null before using setCenter and setZoom
          if (map.current) {
            map.current.setCenter(newLatlng);
            map.current.setZoom(12); // Choose an appropriate zoom level
          }

          return newMarker;
        });

        setSelectedLocation(newLatlng);

        // ... rest of your code
      } else {
        // console.error("Invalid address object:", addressObject);
      }
    };

    const handleScriptLoad = () => {
      if (window.google && window.google.maps && window.google.maps.places) {
        autocomplete.current = new window.google.maps.places.Autocomplete(
          autocompleteRef.current,
          {}
        );
        autocomplete.current.addListener("place_changed", handlePlaceSelect);

        if (mapRef.current) {
          map.current = new window.google.maps.Map(mapRef.current, {
            center: { lat: latlng.lat, lng: latlng.lng },
            zoom: 2,
          });

          if (showMap) {
            marker.current = new window.google.maps.Marker({
              position: { lat: latlng.lat, lng: latlng.lng },
              map: map.current,
            });
          }
        } else {
          // console.error("mapRef.current is null or undefined");
        }
      } else {
        // console.error("Google Maps API not loaded properly");
      }
    };

    const loadGoogleMapsScript = () => {
      if (!window.google || !window.google.maps || !window.google.maps.places) {
        loadScript(
          `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`,
          () => handleScriptLoad()
        );
      } else {
        handleScriptLoad();
      }
    };

    loadGoogleMapsScript();

    // Cleanup function to remove the script when the component unmounts
    return () => {
      if (mapRef.current && mapRef.current.parentNode === document.head) {
        document.head.removeChild(mapRef.current);
      }
    };
  }, [showMap]);

  return (
    <React.Fragment>
      <div className="profile-layer">
        <div className="profile-page">
          <div className="container-fluid">
            <div className="row g-4">
              <div className="col-lg-2 col-md-3">
                <ProfileMenu />
              </div>

              <div className="col-lg-7 col-md-9 col-12">
                <div className="add-event-form white-card attend-event-page">
                  <div className="title-top">
                    <h1>Edit Event</h1>
                  </div>

                  <form onSubmit={handleEditEvent}>
                    <div className="row g-xl-4 g-3">
                      {/* select group */}
                      {/* <div className="col-12">
                               <div className="form-group">
                                  <label>Group</label>
                                  <select
                                     className="form-select form-control"
                                     aria-label="Default select example"
                                     id="exampleType"
                                  >
                                     <option value="">Select Group</option>
                                     <option value="User">User</option>
                                     <option value="Business">Business</option>
                                  </select>
                               </div>
                            </div> */}

                      {/* select category */}
                      <div className="col-12">
                        <div className="form-group">
                          <label>Category</label>
                          <select
                            className="form-select form-control"
                            aria-label="Default select example"
                            id="exampleType"
                            value={categoryId}
                            onChange={handleCategoryChange}
                          >
                            <option value="Select category">
                              Select category
                            </option>
                            {categoryList &&
                              categoryList.map((categoryType) => (
                                <option
                                  key={categoryType.iCategoryId}
                                  value={categoryType.iCategoryId}
                                >
                                  {categoryType.vCategory}
                                </option>
                              ))}
                            {/* <option value="User">User</option>
                                        <option value="Business">Business</option> */}
                          </select>
                        </div>
                      </div>

                      {/* select activity */}
                      <div className="col-12">
                        <div className="form-group">
                          <label>Activity</label>
                          <select
                            className="form-select form-control"
                            aria-label="Default select example"
                            id="exampleType"
                            value={activityId}
                            onChange={handleActivityChange}
                          >
                            <option value="Select activity">
                              Select activity
                            </option>
                            {activityList &&
                              activityList.map((activityType) => (
                                <option
                                  key={activityType.iActivityId}
                                  value={activityType.iActivityId}
                                >
                                  {activityType.vActivity}
                                </option>
                              ))}
                            {/* <option value="User">User</option>
                                     <option value="Business">Business</option> */}
                          </select>
                        </div>
                      </div>

                      {/* enter event */}
                      <div className="col-12">
                        <div className="form-group">
                          <label>Event</label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputFirstName"
                            placeholder="Enter Event"
                            value={event}
                            onChange={handleEventChange}
                          />
                        </div>
                      </div>

                      {/* enter location */}
                      <div className="col-12">
                        <div className="form-group">
                          <label>Location</label>
                          <input
                            ref={autocompleteRef}
                            className="form-control"
                            onChange={handleLocationChange}
                            placeholder={"Search Places.."}
                            value={location}
                          />
                          {showMap ? (
                            <div>
                              {eventDetail &&
                                eventDetail.vLatitude &&
                                eventDetail.vLongitude && (
                                  <iframe
                                    title="google map"
                                    width="450"
                                    height="250"
                                    style={{
                                      border: "0",
                                      width: "100%",
                                      height: "370px",
                                      marginTop: "20px",
                                    }}
                                    src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&q=${eventDetail.vLatitude},${eventDetail.vLongitude}`}
                                  ></iframe>
                                )}
                            </div>
                          ) : (
                            <div
                              ref={mapRef}
                              className="gmap_iframe"
                              style={{
                                width: "100%",
                                height: "370px",
                                marginTop: "20px",
                              }}
                            ></div>
                          )}
                        </div>
                      </div>

                      {/* enter description */}
                      <div className="col-12">
                        <div className="form-group">
                          <label>Description</label>
                          <textarea
                            name="description"
                            className="form-control"
                            placeholder="Enter Description"
                            value={description}
                            onChange={handleDescriptionChange}
                          ></textarea>
                        </div>
                      </div>

                      {/* select type */}
                      <div
                        className={
                          type === "Premium" ? "col-md-6 col-12" : "col-12"
                        }
                      >
                        <div className="form-group">
                          <label>Type</label>
                          <select
                            className="form-select form-control"
                            aria-label="Default select example"
                            id="exampleType"
                            value={type}
                            onChange={handleTypeChange}
                          >
                            <option value="">Select Type</option>
                            <option value="Public">Public</option>
                            {/* <option value="Private">Private</option> */}
                            <option value="Premium">Premium</option>
                          </select>
                        </div>
                      </div>

                      {/* enter Amount */}
                      {type === "Premium" ? (
                        <div className="col-md-6 col-12">
                          <div className="form-group">
                            <label>Amount</label>
                            <input
                              type="text"
                              className="form-control"
                              id="exampleInputFirstName"
                              placeholder="Enter Amount"
                              value={amount}
                              onChange={handleAmountChange}
                            />
                          </div>
                        </div>
                      ) : null}

                      {/* enter limit */}
                      <div className="col-12">
                        <div className="form-group">
                          <label>Number of Participant</label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputFirstName"
                            placeholder="Number of Participant"
                            value={limit}
                            onChange={handleLimitChange}
                          />
                        </div>
                      </div>

                      {/* select event purpose */}
                      {/* <div className="col-12">
                        <div className="form-group">
                          <label>Event Purpose</label>
                          <select
                            className="form-select form-control"
                            aria-label="Default select example"
                            id="exampleType"
                            value={eventPurpose}
                            onChange={handleEventPurposeChange}
                          >
                            <option value="">Select Event</option>
                            <option value="Normal">Normal</option>
                            <option value="OneToOne">OneToOne</option>
                          </select>
                        </div>
                      </div> */}

                      {/* <div className="col-12">
                        <div className="form-group">
                          <label>Event Purpose..............</label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleType"
                            placeholder="Enter Event Purpose"
                            value={eventPurpose}
                            onChange={handleEventPurposeChange}
                          />
                        </div>
                      </div> */}

                      {/* enter date */}
                      <div className="col-md-6 col-12">
                        <div className="form-group">
                          <label>Event Date</label>
                          <input
                            type="date"
                            className="form-control"
                            id="exampleInputFirstName"
                            placeholder="Enter Date"
                            value={eventDate}
                            onChange={handleEventDateChange}
                            min={new Date().toISOString().split("T")[0]}
                          />
                        </div>
                      </div>

                      {/* select tag */}
                      <div className="col-md-6 col-12">
                        <div className="form-group">
                          <label>Tag List</label>
                          <Select
                            isMulti
                            value={selectedTags}
                            onChange={handleTagId}
                            className="form-control multi-select-control"
                            // defaultValue={tagId}
                            // onChange={(selectedOption) => setTagId(selectedOption)}
                            options={
                              tagList &&
                              tagList.map((tagType) => ({
                                value: tagType.iTagId,
                                label: tagType.vTag,
                              }))
                            }
                          />
                        </div>
                      </div>

                      {/* upload image */}
                      <div className="col-md-6 col-12">
                        <div className="form-group">
                          <label>Image</label>
                          <div className="input-image-box">
                            <input
                              className="form-control"
                              type="file"
                              onChange={handleImage}
                              accept=".jpg, .jpeg, .png"
                            />
                            <p>Upload Image</p>
                          </div>
                          <br />
                          {/* {imageError && ( */}
                          {/* <div
                            className="error-msg"
                            style={{
                              color: "red",
                              fontSize: "14px",
                              marginLeft: "20px",
                            }}
                          > */}
                          {/* {imageError} */}
                        </div>
                        {/* )} */}
                        {imageFileName && (
                          <p
                            style={{
                              fontSize: "13px",
                              marginLeft: "16px",
                              color: "#1e90ff",
                            }}
                          >
                            Selected image: {imageFileName}
                          </p>
                        )}
                        {profilePictureUrl && (
                          <div className="image-preview">
                            <img
                              src={profilePictureUrl}
                              alt="event img"
                              style={{
                                maxWidth: "100%",
                                maxHeight: "80px",
                                marginLeft: "15px",
                                borderRadius: "15px 15px 0px 15px",
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <br />

                    {/* <div className="col-md-6 col-12">
                               <div className="form-group">
                                  <label>Image</label>
                                  <div className="input-image-box">
                                     <input
                                        className="form-control"
                                        type="file"
                                        onChange={handleImageChange}
                                     />
                                     <p>Upload Image</p>
                                  </div>
                                  {imageFileName && (
                                     <p style={{ fontSize: "13px", marginLeft: "16px" }}>selected image: {imageFileName}</p>
                                  )}
                               </div>
                            </div> */}

                    <div className="col-12">
                      <button type="submit" className="main-btn">
                        Submit
                      </button>
                    </div>
                    {/* {addEventSuccess && (
                        <p style={{ color: "green" }}>
                          New event created successfully.
                        </p>
                      )} */}
                    {updateSuccess && (
                      <p style={{ color: "green" }}>
                        Your event is updated successfully
                      </p>
                    )}
                    {/* </div> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default EditEvent;
