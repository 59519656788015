import React from "react";
import { Link } from "react-router-dom";

const UpdatePasswordPage = () => {
  return (
    <div className="highlight-msg">
      <Link to={"/"} className="logo-box">
        <img src="/assets/images/logo.png" alt="" />
      </Link>
      <div className="text">
        <h1 className="success-color">Success !!</h1>
        <p>Your password has been successfully updated. Now you can login with your new password and access your account</p>
        <Link to={"#"} className="main-btn">Continue button</Link>
      </div>
    </div>
  );
};

export default UpdatePasswordPage;
