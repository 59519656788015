import React from "react";
import Container from "../components/layouts/Container";
import AboutSection from "../components/Common/AboutSection";
import MissionVision from "../components/Common/MissionVision";
import WhyUs from "../components/Common/WhyUs";
import CoreValue from "../components/Common/CoreValue";

const AboutPage = () => {

  React.useEffect(() => {
    document.title = "About-us | WellNas";
  },);

  return (
    <React.Fragment>
      <div className="dark-header">
        <Container>

          <AboutSection />
          <MissionVision />
          <WhyUs />
          <CoreValue />

        </Container>
      </div>
    </React.Fragment>
  );
}

export default AboutPage;