import React from "react";
import { useParams } from 'react-router';
import { useNavigate } from "react-router-dom";
import axios from "axios";


const VerifyPage = () => {

    const navigate = useNavigate();
    const { vUniqueCode } = useParams();

    React.useEffect(() => {
        const fetchData = async () => {
            const values = {
                vUniqueCode: vUniqueCode
            }
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/verify`, values
                );

                if (response.status === 200) {
                    navigate('/account-verified');
                } else navigate("/error")
            } catch (error) {
            }
        };
        fetchData();
    }, [vUniqueCode, navigate]);


    return (
        <React.Fragment>

        </React.Fragment>
    );
}

export default VerifyPage;
