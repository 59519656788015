export const GROUP_LIST_REQUEST = "GROUP_LIST_REQUEST"
export const GROUP_LIST_SUCCESS = "GROUP_LIST_SUCCESS"
export const GROUP_LIST_FAILURE = "GROUP_LIST_FAILURE"

export const MY_GROUP_REQUEST = "MY_GROUP_REQUEST"
export const MY_GROUP_SUCCESS = "MY_GROUP_SUCCESS"
export const MY_GROUP_FAILURE = "MY_GROUP_FAILURE"

export const ADD_NEW_GROUP_REQUEST = "ADD_NEW_GROUP_REQUEST"
export const ADD_NEW_GROUP_SUCCESS = "ADD_NEW_GROUP_SUCCESS"
export const ADD_NEW_GROUP_FAILURE = "ADD_NEW_GROUP_FAILURE"