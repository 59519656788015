import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPageListAbout } from "../../store/action/aboutAction";

const AboutSection = () => {
  React.useEffect(() => {
    document.title = "About-us | WellNas";
  });

  // const errorImg = "/assets/images/no_image.png";

//   const errorImg = "/assets/images/no-image-icon.png";
const errorImg = "/assets/images/no-image.png";

  const dispatch = useDispatch();
  const { pageListAbout } = useSelector((state) => state.pageListAbout);

  // for About pageList
  React.useEffect(() => {
    if (!pageListAbout) {
      dispatch(
        getPageListAbout({
          eSection: "About",
        })
      );
    }
  }, [pageListAbout, dispatch]);

  return (
    <React.Fragment>
      <section
        className="inner-page-breadcamp"
        style={{ backgroundImage: "url(assets/images/breadcamp-image.png)" }}
      >
        <div className="container">
          <div className="brad-camp-detail">
            <p className="sub-title">About us</p>
          </div>
        </div>
      </section>

      <section className="about-detail p-100">
        <div className="container">
          {pageListAbout && (
            <div className="row">
              <div className="col-md-5">
                <h1 className="sub-title">{pageListAbout.vTitle}</h1>
                <div className="text-center w-100">
                  <img
                    className="about-detail-img"
                    src={pageListAbout ? pageListAbout.vWebpImage : errorImg}
                    alt="about img"
                    width="160"
                    height="160"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = errorImg;
                    }}
                    style={{
                      objectFit:
                        pageListAbout.vWebpImage.split("/").pop() === "null"
                          ? "contain"
                          : "cover",
                    }}
                  />
                </div>
              </div>
              <div className="col-md-7">
                <div className="about-detail-contain">
                  <p className="peregrap-style">{pageListAbout.tDescription}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </React.Fragment>
  );
};

export default AboutSection;
