import {
   BANNER_LIST_REQUEST,
   BANNER_LIST_SUCCESS,
   BANNER_LIST_FAILURE,

} from "../constants/bannerConstant";

import axios from "axios";


export const getBannerList = (data) => async (dispatch) => {
   try {
      dispatch({ type: BANNER_LIST_REQUEST });
      const resData = await axios.post(
         `${process.env.REACT_APP_API_URL}/api/banner/get_all_data`, data
      );
      dispatch({ type: BANNER_LIST_SUCCESS, payload: resData.data.data });
   } catch (err) {
      dispatch({ type: BANNER_LIST_FAILURE });
   }
};