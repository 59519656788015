import React from "react";

import Register from "../components/Common/Register";

const RegisterPage = () => {
  return (


      <Register />


  );
}

export default RegisterPage;
