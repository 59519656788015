import React from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { getCustomerEvent } from "../../store/action/customerAction";

const UserInformation = () => {
  // const errorImg = "/assets/images/no_image.png";
  //   const errorImg = "/assets/images/no-image-icon.png";
  const errorImg = "/assets/images/no-image.png";

  const { state } = useLocation();
  // const { vUniqueCode } = state;
  const vUniqueCode = state?.vUniqueCode ?? null;

  // console.log("*****", vUniqueCode);

  // console.log("state------------------------>", state);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { customerEvent } = useSelector((state) => state.customerEvent);

  // dateTime format
  const formatDateTime = (dateTimeString) => {
    const options = {
      weekday: "short",
      month: "short",
      day: "numeric",
    };

    const formattedDate = new Date(dateTimeString).toLocaleString(
      "en-US",
      options
    );
    const commaIndex = formattedDate.indexOf(
      ",",
      formattedDate.indexOf(",") + 1
    );
    if (commaIndex !== -1) {
      return (
        formattedDate.substring(0, commaIndex) +
        " -" +
        formattedDate.substring(commaIndex + 1)
      );
    }
    return formattedDate;
  };

  // for handle event Detail
  const handleDetailClick = (event) => {
    navigate(`/event-details/${event.vEvent.replace(/\s+/g, "-")}`, {
      state: { iEventId: event.iEventId },
    });
  };

  // for customer info
  const fetchCustomerEvent = React.useCallback(() => {
    if (vUniqueCode) {
      dispatch(getCustomerEvent({ vUniqueCode: vUniqueCode }));
    }
  }, [dispatch, vUniqueCode]);

  React.useEffect(() => {
    if (vUniqueCode) {
      fetchCustomerEvent();
    }
  }, [vUniqueCode, fetchCustomerEvent]);

  return (
    <React.Fragment>
      <div className="row justify-content-center">
        <div className="col-lg-7 col-md-9 col-12">
          <div className="profile-details">
            {/* profile part */}
            <div className="user-box white-card">
              {customerEvent && (
                <div className="details-box">
                  <div className="image-box">
                    <img
                      src={customerEvent ? customerEvent.vWebpImage : errorImg}
                      alt={customerEvent.vImageAlt}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = errorImg;
                      }}
                      style={{
                        objectFit:
                          customerEvent.vWebpImage.split("/").pop() === "null"
                            ? "contain"
                            : "cover",
                      }}
                    />
                  </div>
                  <div className="text">
                    <h2>
                      {customerEvent.vFirstName} {customerEvent.vLastName}
                    </h2>
                    <div className="location">
                      <i className="fal fa-map-marker-alt"></i>
                      <span style={{ fontSize: "13px" }}>
                        {customerEvent.vLocation}
                      </span>
                    </div>
                    <ul className="connect-us">
                      <li>
                        <Link to={"#"}>
                          <i className="fal fa-envelope"></i>
                          <span style={{ fontSize: "13px" }}>
                            {customerEvent.vEmail}
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to={"#"}>
                          <i className="fal fa-phone-alt"></i>
                          <span style={{ fontSize: "13px" }}>
                            {customerEvent.vCountryCode} {customerEvent.vPhone}
                          </span>
                        </Link>
                      </li>
                    </ul>

                    {/* {console.log("customerEvent", customerEvent)} */}

                    {/* social media links */}
                    <ul className="social">
                      {customerEvent.vFacebook && (
                        <li>
                          <Link to={customerEvent.vFacebook}>
                            <img
                              src="/assets/images/facebook-icon.png"
                              alt=""
                            />
                          </Link>
                        </li>
                      )}
                      {customerEvent.vTwitter && (
                        <li>
                          <Link to={customerEvent.vTwitter}>
                            <img src="/assets/images/twitter-icon.png" alt="" />
                          </Link>
                        </li>
                      )}
                      {customerEvent.vLinkedIn && (
                        <li>
                          <Link to={customerEvent.vLinkedIn}>
                            <img src="/assets/images/linkd-icon.png" alt="" />
                          </Link>
                        </li>
                      )}
                      {customerEvent.vInstagram && (
                        <li>
                          <Link to={customerEvent.vInstagram}>
                            <img
                              src="/assets/images/instagram-icon.png"
                              alt=""
                            />
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              )}
            </div>

            <div className="user-details white-card">
              {/* about part */}
              {customerEvent && (
                <div className="box">
                  <div className="title-label">About</div>
                  <div className="inner-box">
                    <div className="description">{customerEvent.tAbout}</div>
                  </div>
                </div>
              )}

              <div className="box user-profile-event">
                <div className="title-label">Events</div>
                <div className="inner-box">
                  <div className="row gy-4">
                    {/* event part */}
                    {customerEvent && customerEvent.events.length > 0 ? (
                      customerEvent.events.map((custEventList, eventList) => {
                        return (
                          <div
                            className="col-xl-4 col-lg-6 col-sm-6"
                            key={eventList}
                            onClick={() => handleDetailClick(custEventList)}
                            style={{ cursor: "pointer" }}
                          >
                            <div className="event-box">
                              <div className="img-box">
                                <img
                                  src={
                                    custEventList
                                      ? custEventList.vWebpImage
                                      : errorImg
                                  }
                                  alt={custEventList.vImageAlt}
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = errorImg;
                                  }}
                                  style={{
                                    objectFit:
                                      custEventList.vWebpImage
                                        .split("/")
                                        .pop() === "null"
                                        ? "contain"
                                        : "cover",
                                  }}
                                />
                                {/* <a class="redirect-btn" href="/">
                                                   <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="37"
                                                      height="37"
                                                      viewBox="0 0 37 37"
                                                      fill="none"
                                                   >
                                                      <circle
                                                         cx="18.5"
                                                         cy="18.5"
                                                         r="18.5"
                                                         fill="white"
                                                      ></circle>
                                                      <path
                                                         d="M20.3889 15.7333V12L27 18.5333L20.3889 25.0667V21.3333C15.6667 21.3333 12.3611 22.7333 10 26L10.7556 23.2L10.9444 22.8267C11.7019 20.8975 12.9855 19.2135 14.6526 17.9614C16.3197 16.7093 18.3052 15.9381 20.3889 15.7333Z"
                                                         fill="#616161"
                                                      ></path>
                                                   </svg>
                                                </a> */}
                              </div>
                              <div className="contain">
                                <p className="date-time">
                                  {formatDateTime(custEventList.dtEvent)}
                                </p>
                                <h2>{custEventList.tDescription}</h2>
                                <p className="location">
                                  {custEventList.vEvent} -{" "}
                                  {custEventList.vLocation}
                                </p>
                                <p className="attendees">
                                  {custEventList.attendees_count} attendees
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div>No events available</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UserInformation;

// extra code
/* <>
   <div className="col-xl-4 col-lg-6 col-sm-6">
      <div className="event-box">
         <div className="img-box">
            <img src="assets/images/event-3.png" alt="" />
            <a class="redirect-btn" href="/">
               <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="37"
                  height="37"
                  viewBox="0 0 37 37"
                  fill="none"
               >
                  <circle
                     cx="18.5"
                     cy="18.5"
                     r="18.5"
                     fill="white"
                  ></circle>
                  <path
                     d="M20.3889 15.7333V12L27 18.5333L20.3889 25.0667V21.3333C15.6667 21.3333 12.3611 22.7333 10 26L10.7556 23.2L10.9444 22.8267C11.7019 20.8975 12.9855 19.2135 14.6526 17.9614C16.3197 16.7093 18.3052 15.9381 20.3889 15.7333Z"
                     fill="#616161"
                  ></path>
               </svg>
            </a>
         </div>
         <div class="contain">
            <p class="date-time">Wed, Dec 20</p>
            <h2>Lorem ipsum dolor sit amet consectetur. Magna dignissim eu.</h2>
            <p class="location">The Side Hustle - New York, NY</p>
            <p class="attendees">27 attendees</p>
         </div>
      </div>
   </div>
   <div className="col-xl-4 col-lg-6 col-sm-6">
      <div className="event-box">
         <div className="img-box">
            <img src="assets/images/event-3.png" alt="" />
            <a class="redirect-btn" href="/">
               <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="37"
                  height="37"
                  viewBox="0 0 37 37"
                  fill="none"
               >
                  <circle
                     cx="18.5"
                     cy="18.5"
                     r="18.5"
                     fill="white"
                  ></circle>
                  <path
                     d="M20.3889 15.7333V12L27 18.5333L20.3889 25.0667V21.3333C15.6667 21.3333 12.3611 22.7333 10 26L10.7556 23.2L10.9444 22.8267C11.7019 20.8975 12.9855 19.2135 14.6526 17.9614C16.3197 16.7093 18.3052 15.9381 20.3889 15.7333Z"
                     fill="#616161"
                  ></path>
               </svg>
            </a>
         </div>
         <div class="contain">
            <p class="date-time">Wed, Dec 20</p>
            <h2>Lorem ipsum dolor sit amet consectetur. Magna dignissim eu.</h2>
            <p class="location">The Side Hustle - New York, NY</p>
            <p class="attendees">27 attendees</p>
         </div>
      </div>
   </div>
   <div className="col-xl-4 col-lg-6 col-sm-6">
      <div className="event-box">
         <div className="img-box">
            <img src="assets/images/event-3.png" alt="" />
            <a class="redirect-btn" href="/">
               <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="37"
                  height="37"
                  viewBox="0 0 37 37"
                  fill="none"
               >
                  <circle
                     cx="18.5"
                     cy="18.5"
                     r="18.5"
                     fill="white"
                  ></circle>
                  <path
                     d="M20.3889 15.7333V12L27 18.5333L20.3889 25.0667V21.3333C15.6667 21.3333 12.3611 22.7333 10 26L10.7556 23.2L10.9444 22.8267C11.7019 20.8975 12.9855 19.2135 14.6526 17.9614C16.3197 16.7093 18.3052 15.9381 20.3889 15.7333Z"
                     fill="#616161"
                  ></path>
               </svg>
            </a>
         </div>
         <div class="contain">
            <p class="date-time">Wed, Dec 20</p>
            <h2>Lorem ipsum dolor sit amet consectetur. Magna dignissim eu.</h2>
            <p class="location">The Side Hustle - New York, NY</p>
            <p class="attendees">27 attendees</p>
         </div>
      </div>
   </div>
   <div className="col-xl-4 col-lg-6 col-sm-6">
      <div className="event-box">
         <div className="img-box">
            <img src="assets/images/event-3.png" alt="" />
            <a class="redirect-btn" href="/">
               <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="37"
                  height="37"
                  viewBox="0 0 37 37"
                  fill="none"
               >
                  <circle
                     cx="18.5"
                     cy="18.5"
                     r="18.5"
                     fill="white"
                  ></circle>
                  <path
                     d="M20.3889 15.7333V12L27 18.5333L20.3889 25.0667V21.3333C15.6667 21.3333 12.3611 22.7333 10 26L10.7556 23.2L10.9444 22.8267C11.7019 20.8975 12.9855 19.2135 14.6526 17.9614C16.3197 16.7093 18.3052 15.9381 20.3889 15.7333Z"
                     fill="#616161"
                  ></path>
               </svg>
            </a>
         </div>
         <div class="contain">
            <p class="date-time">Wed, Dec 20</p>
            <h2>Lorem ipsum dolor sit amet consectetur. Magna dignissim eu.</h2>
            <p class="location">The Side Hustle - New York, NY</p>
            <p class="attendees">27 attendees</p>
         </div>
      </div>
   </div>
   <div className="col-xl-4 col-lg-6 col-sm-6">
      <div className="event-box">
         <div className="img-box">
            <img src="assets/images/event-3.png" alt="" />
            <a class="redirect-btn" href="/">
               <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="37"
                  height="37"
                  viewBox="0 0 37 37"
                  fill="none"
               >
                  <circle
                     cx="18.5"
                     cy="18.5"
                     r="18.5"
                     fill="white"
                  ></circle>
                  <path
                     d="M20.3889 15.7333V12L27 18.5333L20.3889 25.0667V21.3333C15.6667 21.3333 12.3611 22.7333 10 26L10.7556 23.2L10.9444 22.8267C11.7019 20.8975 12.9855 19.2135 14.6526 17.9614C16.3197 16.7093 18.3052 15.9381 20.3889 15.7333Z"
                     fill="#616161"
                  ></path>
               </svg>
            </a>
         </div>
         <div class="contain">
            <p class="date-time">Wed, Dec 20</p>
            <h2>Lorem ipsum dolor sit amet consectetur. Magna dignissim eu.</h2>
            <p class="location">The Side Hustle - New York, NY</p>
            <p class="attendees">27 attendees</p>
         </div>
      </div>
   </div>
</> */
