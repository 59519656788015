import React from "react";
import Container from "../components/layouts/Container";
import ActivityListing from "../components/Activity/ActivityListing";


const ActivityPage = () => {
   return (
      <React.Fragment>
         <div className="dark-header">
            <Container>
               <div className="dark-header">
                  <ActivityListing />
               </div>
            </Container>
         </div>
      </React.Fragment>
   );
}

export default ActivityPage;