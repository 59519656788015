import {
  SEARCH_LIST_REQUEST,
  SEARCH_LIST_SUCCESS,
  SEARCH_LIST_FAILURE,
} from "../constants/searchConstant";

export function searchListReducer(state = {}, action) {
  switch (action.type) {
    case SEARCH_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SEARCH_LIST_SUCCESS:
      return {
        loading: false,
        searchListData: action.payload,
      };
    case SEARCH_LIST_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
