import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { getEventList } from "../../store/action/eventAction";
import { getCategoryList } from "../../store/action/categoryAction";
import { getActivityList } from "../../store/action/activityAction";
import SkeletonOneToOneEventList from "../../Skeleton/SkeletonOneToOneList";
import axios from "axios";
import { EVENT_LIST_SUCCESS } from "../../store/constants/eventConstant";
import { getCustomerProfile } from "../../store/action/customerAction";

const EventListing = () => {
  React.useEffect(() => {
    document.title = "Events | WellNas";
  });

  const errorImg = "/assets/images/no-image.png";

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const vUniqueCode = localStorage.getItem("vUniqueCode");

  const { eventList } = useSelector((state) => state.event);

  const { customerProfile } = useSelector((state) => state.customerProfile);
  const { categoryList } = useSelector((state) => state.category);
  const { activityList } = useSelector((state) => state.activityList);

  const [limit, setLimit] = React.useState(6);
  const [events, setEvents] = React.useState([]);
  const [showFullAddress, setShowFullAddress] = React.useState(null);
  const [addressVisibility, setAddressVisibility] = React.useState({});

  const [activeType, setActiveType] = React.useState("events");
  const [selectedFilter, setSelectedFilter] = React.useState("Any type");

  const [selectedOrder, setSelectedOrder] = React.useState("");

  const [latLngData, setLatLngData] = React.useState([]);

  // console.log(selectedOrder);
  const [loading, setLoading] = React.useState(true);
  const [filterLoading, setFilterLoading] = React.useState(true);

  const initialState = location.state
    ? location.state.vCategory
    : "Any category";
  const [selectedCategory, setSelectedCategory] = React.useState(initialState);

  const startState = location.state ? location.state.vActivity : "Any activity";

  const [selectedActivity, setSelectedActivity] = React.useState(startState);

  React.useEffect(() => {
    if (location.state && location.state.vActivity) {
      setSelectedActivity(location.state.vActivity);
    } else {
      setSelectedActivity("Any activity");
    }
  }, [location]);

  React.useEffect(() => {
    if (location.state && location.state.vCategory) {
      setSelectedCategory(location.state.vCategory);
    } else {
      setSelectedCategory("Any category");
    }
  }, [location]);

  // dateTime format
  const formatDateTime = (dateTimeString) => {
    const options = {
      weekday: "short",
      month: "short",
      day: "numeric",
    };

    const formattedDate = new Date(dateTimeString).toLocaleString(
      "en-US",
      options
    );
    const commaIndex = formattedDate.indexOf(
      ",",
      formattedDate.indexOf(",") + 1
    );
    if (commaIndex !== -1) {
      return (
        formattedDate.substring(0, commaIndex) +
        " -" +
        formattedDate.substring(commaIndex + 1)
      );
    }
    return formattedDate;
  };

  const handleAddressClick = (eventId) => {
    setAddressVisibility((prevVisibility) => ({
      ...prevVisibility,
      [eventId]: !prevVisibility[eventId],
    }));

    setShowFullAddress((prevShowFullAddress) =>
      prevShowFullAddress === eventId ? null : eventId
    );
  };

  React.useEffect(() => {
    const vUniqueCode = localStorage.getItem("vUniqueCode");
    if (!customerProfile) {
      if (vUniqueCode) {
        dispatch(
          getCustomerProfile({
            vUniqueCode: vUniqueCode,
          })
        );
      }
    }
  }, [customerProfile, dispatch]);

  const resetFilters = () => {
    setSelectedFilter("Any type");
    setSelectedCategory("Any category");
    setSelectedActivity("Any activity");
    setSelectedOrder("");
  };

  const handleDetailClick = (event) => {
    navigate(`/event-details/${event.vEvent.replace(/\s+/g, "-")}`, {
      state: { iEventId: event.iEventId, EventName: event.vEvent },
    });
  };

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  React.useEffect(() => {
    const vUniqueCode = localStorage.getItem("vUniqueCode");

    if (vUniqueCode) {
      if (!eventList) {
        const actionPayload = {
          vUniqueCode: vUniqueCode,
          eFeature: "",
          vLocation: "",
          eType: selectedFilter !== "Any type" ? selectedFilter : "",
          vCategory:
            selectedCategory !== "Any category" ? selectedCategory : "",
          iCategoryId: "",
          vActivity:
            selectedActivity !== "Any activity" ? selectedActivity : "",
          iActivityId: "",
          vColumn: "",
          vOrder: "",
        };

        dispatch(getEventList(actionPayload));
      }
    } else {
      if (!eventList) {
        const actionPayload = {
          vUniqueCode: "",
          eFeature: "",
          vLocation: "",
          eType: selectedFilter !== "Any type" ? selectedFilter : "",
          vCategory:
            selectedCategory !== "Any category" ? selectedCategory : "",
          iCategoryId: "",
          vActivity:
            selectedActivity !== "Any activity" ? selectedActivity : "",
          iActivityId: "",
          vColumn: "",
          vOrder: "",
        };

        dispatch(getEventList(actionPayload));
      }
    }
  }, [eventList, selectedCategory, selectedActivity, dispatch, selectedFilter]);

  const HandleOrderChange = (e) => {
    setSelectedOrder(e.target.value);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        setFilterLoading(true); // Start loading
        const vUniqueCode = localStorage.getItem("vUniqueCode");
        let actionParams = {
          vUniqueCode: vUniqueCode ? vUniqueCode : "",
          eFeature: "",
          vLocation: "",
          eType: selectedFilter !== "Any type" ? selectedFilter : "",
          vCategory:
            selectedCategory !== "Any category" ? selectedCategory : "",
          iCategoryId: "",
          vActivity:
            selectedActivity !== "Any activity" ? selectedActivity : "",
          iActivityId: "",
          vColumn: "",
          vOrder: selectedOrder ? selectedOrder : "",
        };

        let response;
        if (selectedOrder === "ASC" || selectedOrder === "DESC") {
          actionParams.vColumn = "vEvent";
          response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/event/get_all_data`,
            actionParams
          );
        } else if (selectedOrder === "Newest") {
          response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/event/get_all_data`,
            actionParams
          );

          if (response.data && response.data.data) {
            response.data.data.sort(
              (a, b) => new Date(b.dtAddedDate) - new Date(a.dtAddedDate)
            );
          }
        } else {
          response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/event/get_all_data`,
            actionParams
          );
        }

        if (response && response.data) {
          dispatch({ type: EVENT_LIST_SUCCESS, payload: response.data.data });
        }

        setFilterLoading(false);
      } catch (error) {
        // console.error("Error fetching data:", error);
        setFilterLoading(false);
      }
    };

    fetchData();
  }, [
    selectedOrder,
    selectedCategory,
    selectedActivity,
    selectedFilter,
    dispatch,
    setFilterLoading,
  ]);

  React.useEffect(() => {
    if (!categoryList) {
      dispatch(
        getCategoryList({
          eFeature: "",
          vCategory: "",
        })
      );
    }
  }, [categoryList, dispatch]);

  React.useEffect(() => {
    if (!activityList) {
      dispatch(
        getActivityList({
          iCategoryId: "",
          eFeature: "",
        })
      );
    }
  }, [activityList, dispatch]);

  React.useEffect(() => {
    if (
      location.state &&
      location.state.vCategory &&
      !location.state.vActivity
    ) {
      setSelectedCategory(location.state.vCategory);
      setSelectedActivity("Any activity");
    }
    if (
      location.state &&
      location.state.vActivity &&
      !location.state.vCategory
    ) {
      setSelectedActivity(location.state.vActivity);
      setSelectedCategory("Any category");
    }
  }, [location.state]);

  React.useEffect(() => {
    if (location.state) {
      if (location.state.vCategory && !location.state.vActivity) {
        setSelectedCategory(location.state.vCategory);
        setSelectedActivity("Any activity");
      } else if (location.state.vActivity && !location.state.vCategory) {
        setSelectedActivity(location.state.vActivity);
        setSelectedCategory("Any category");
      } else if (!location.state.vCategory && !location.state.vActivity) {
        setSelectedCategory("Any category");
        setSelectedActivity("Any activity");
      }
    }
  }, [location.state]);

  const filterEvents = () => {
    return (
      eventList &&
      Array.isArray(eventList) &&
      eventList.filter((event) => {
        const filterByType =
          selectedFilter === "Any type" || event.eType === selectedFilter;

        const filterByCategory =
          selectedCategory === "Any category" ||
          event.categories.some(
            (category) => category.vCategory === selectedCategory
          );

        const filterByActivity =
          selectedActivity === "Any activity" ||
          event.activities.some(
            (activity) => activity.vActivity === selectedActivity
          );

        return filterByType && filterByCategory && filterByActivity;
      })
    );
  };

  // handle View all button
  const handleViewAllClick = () => {
    setLimit(null);
  };

  React.useEffect(() => {
    const latLongArray = [];

    if (eventList) {
      const filteredEvents = filterEvents();

      if (Array.isArray(filteredEvents)) {
        filteredEvents.forEach((event) => {
          const latLongObject = {
            eventId: event.iEventId,
            location: event.vLocation,
            latitude: event.vLatitude,
            longitude: event.vLongitude,
            events: event.vEvent,
          };
          latLongArray.push(latLongObject);
        });
      }

      setLatLngData(latLongArray);
    }
  }, [
    eventList,
    selectedFilter,
    selectedCategory,
    selectedActivity,
    setLatLngData,
  ]);

  const filteredEvents = filterEvents();

  React.useEffect(() => {
    const vUniqueCode = localStorage.getItem("vUniqueCode");
    const mapElement = document.getElementById("map");

    if (window.google && mapElement && !loading) {
      if (window.google && mapElement) {
        const map = new window.google.maps.Map(document.getElementById("map"), {
          center: { lat: 0, lng: 0 },
          zoom: 2,
        });

        const infoBox = new window.google.maps.InfoWindow();

        const validLocations = latLngData.filter(
          (location) =>
            location.latitude !== null &&
            location.latitude !== "" &&
            location.longitude !== null &&
            location.longitude !== ""
        );

        if (customerProfile && vUniqueCode) {
          if (
            selectedActivity === "Any activity" &&
            selectedOrder === "" &&
            selectedCategory === "Any category" &&
            selectedFilter === "Any type"
          ) {
            const lat = parseFloat(customerProfile.vLatitude);
            const lng = parseFloat(customerProfile.vLongitude);

            if (!isNaN(lat) && !isNaN(lng)) {
              map.setCenter({ lat, lng });
              map.setZoom(4);
            }
          } else if (filterEvents() && filterEvents().length === 1) {
            const lat = parseFloat(filterEvents()[0].vLatitude);
            const lng = parseFloat(filterEvents()[0].vLongitude);

            if (!isNaN(lat) && !isNaN(lng)) {
              map.setCenter({ lat, lng });
              map.setZoom(4);
            }
          } else if (
            filteredEvents &&
            filteredEvents.length > 1 &&
            filteredEvents &&
            filteredEvents.length < 3
          ) {
            const event = [...filteredEvents].sort((a, b) =>
              a.vLocation.localeCompare(b.vLocation)
            );

            const lat = parseFloat(event[0].vLatitude);
            const lng = parseFloat(event[0].vLongitude);
            if (!isNaN(lat) && !isNaN(lng)) {
              map.setCenter({ lat, lng });
              map.setZoom(4);
            }
          } else if (filteredEvents && filteredEvents.length >= 3) {
            const sameCountry =
              filteredEvents &&
              filteredEvents.reduce((acc, obj) => {
                const countryKey = obj.vCountry;

                const countryEntry = acc.find(
                  (entry) => entry[0].vCountry === countryKey
                );

                if (countryEntry) {
                  countryEntry.push(obj);
                } else {
                  acc.push([obj]);
                }

                return acc;
              }, []);

            const maxDataArray = sameCountry.reduce(
              (max, current) => (current.length > max.length ? current : max),
              []
            );

            const latLongArray = [];

            if (maxDataArray) {
              maxDataArray.forEach((event) => {
                const latLongObject = {
                  eventId: event.iEventId,
                  location: event.vLocation,
                  latitude: event.vLatitude,
                  longitude: event.vLongitude,
                  events: event.vEvent,
                };

                latLongArray.push(latLongObject);
              });
            }

            latLongArray.sort(
              (a, b) => parseFloat(a.latitude) - parseFloat(b.latitude)
            );

            if (latLongArray && latLongArray.length > 0) {
              latLongArray.forEach((location) => {
                if (
                  location &&
                  location.vLatitude !== "" &&
                  location.vLongitude !== ""
                ) {
                  const lat = parseFloat(location.latitude);
                  const lng = parseFloat(location.longitude);

                  if (!isNaN(lat) && !isNaN(lng)) {
                    map.setCenter({ lat, lng });
                    map.setZoom(4);
                  } else {
                    // console.error("Invalid coordinates for location:", location);
                  }
                }
              });
            }
          }
        } else {
          if (
            selectedActivity === "Any activity" &&
            selectedOrder === "" &&
            selectedCategory === "Any category" &&
            selectedFilter === "Any type"
          ) {
            if (filteredEvents && filteredEvents.length > 0) {
              const sameCountry =
                filteredEvents &&
                filteredEvents.reduce((acc, obj) => {
                  const countryKey = obj.vCountry;

                  const countryEntry = acc.find(
                    (entry) => entry[0].vCountry === countryKey
                  );

                  if (countryEntry) {
                    countryEntry.push(obj);
                  } else {
                    acc.push([obj]);
                  }

                  return acc;
                }, []);

              const maxDataArray = sameCountry.reduce(
                (max, current) => (current.length > max.length ? current : max),
                []
              );

              const latLongArray = [];

              if (maxDataArray) {
                maxDataArray.forEach((event) => {
                  const latLongObject = {
                    eventId: event.iEventId,
                    location: event.vLocation,
                    latitude: event.vLatitude,
                    longitude: event.vLongitude,
                    events: event.vEvent,
                  };

                  latLongArray.push(latLongObject);
                });
              }

              latLongArray.sort(
                (a, b) => parseFloat(a.latitude) - parseFloat(b.latitude)
              );

              if (latLongArray && latLongArray.length > 0) {
                latLongArray.forEach((location) => {
                  // console.log("location", location);
                  if (
                    location &&
                    location.vLatitude !== "" &&
                    location.vLongitude !== ""
                  ) {
                    const lat = parseFloat(location.latitude);
                    const lng = parseFloat(location.longitude);

                    if (!isNaN(lat) && !isNaN(lng)) {
                      map.setCenter({ lat, lng });
                      map.setZoom(4);
                    } else {
                      // console.error("Invalid coordinates for location:", location);
                    }
                  }
                });
              }
            }
          } else if (filterEvents() && filterEvents().length === 1) {
            const lat = parseFloat(filterEvents()[0].vLatitude);
            const lng = parseFloat(filterEvents()[0].vLongitude);

            if (!isNaN(lat) && !isNaN(lng)) {
              map.setCenter({ lat, lng });
              map.setZoom(4);
            }
          } else if (
            filteredEvents &&
            filteredEvents.length > 1 &&
            filteredEvents &&
            filteredEvents.length < 3
          ) {
            const event = [...filteredEvents].sort((a, b) =>
              a.vLocation.localeCompare(b.vLocation)
            );

            const lat = parseFloat(event[0].vLatitude);
            const lng = parseFloat(event[0].vLongitude);
            if (!isNaN(lat) && !isNaN(lng)) {
              map.setCenter({ lat, lng });
              map.setZoom(4);
            }
          } else if (filteredEvents && filteredEvents.length >= 3) {
            const sameCountry =
              filteredEvents &&
              filteredEvents.reduce((acc, obj) => {
                const countryKey = obj.vCountry;

                const countryEntry = acc.find(
                  (entry) => entry[0].vCountry === countryKey
                );

                if (countryEntry) {
                  countryEntry.push(obj);
                } else {
                  acc.push([obj]);
                }

                return acc;
              }, []);

            const maxDataArray = sameCountry.reduce(
              (max, current) => (current.length > max.length ? current : max),
              []
            );

            const latLongArray = [];

            if (maxDataArray) {
              maxDataArray.forEach((event) => {
                const latLongObject = {
                  eventId: event.iEventId,
                  location: event.vLocation,
                  latitude: event.vLatitude,
                  longitude: event.vLongitude,
                  events: event.vEvent,
                };

                latLongArray.push(latLongObject);
              });
            }

            latLongArray.sort(
              (a, b) => parseFloat(a.latitude) - parseFloat(b.latitude)
            );

            if (latLongArray && latLongArray.length > 0) {
              latLongArray.forEach((location) => {
                if (
                  location &&
                  location.vLatitude !== "" &&
                  location.vLongitude !== ""
                ) {
                  const lat = parseFloat(location.latitude);
                  const lng = parseFloat(location.longitude);

                  if (!isNaN(lat) && !isNaN(lng)) {
                    map.setCenter({ lat, lng });
                    map.setZoom(4);
                  } else {
                    // console.error("Invalid coordinates for location:", location);
                  }
                }
              });
            }
          }
        }

        if (validLocations.length > 0) {
          validLocations.forEach((location) => {
            const trimmedLatitude = parseFloat(location.latitude);
            const trimmedLongitude = parseFloat(location.longitude);
            const eventName = location.events;

            let marker = new window.google.maps.Marker({
              position: {
                lat: trimmedLatitude,
                lng: trimmedLongitude,
              },
              map: map,
              title: location.location,
            });

            marker.addListener("click", () => {
              infoBox.setContent(`
            <div>
              <h6>${eventName}</h6>
              <h7>${location.location}</h7>
            </div>
          `);

              infoBox.open(map, marker);
            });
          });
        }
      }
    }
  }, [
    latLngData,
    loading,
    selectedCategory,
    selectedActivity,
    selectedFilter,
    selectedOrder,
    customerProfile,
    filteredEvents,
  ]);

  const handleFilterClick = () => {
    navigate(`/events`);
    window.location.reload();
  };

  return (
    <React.Fragment>
      <section
        className="inner-page-breadcamp event-banner"
        style={{ backgroundImage: "url(/assets/images/event-banner.png)" }}
      >
        <div className="container">
          <div className="brad-camp-detail">
            <h1 className="sub-title">Our Events</h1>
          </div>
        </div>
      </section>

      <section className="event-tab">
        <div className="up-area">
          <div className="container">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    activeType === "events" ? "active" : ""
                  }`}
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#home"
                  type="button"
                  role="tab"
                  aria-controls="home"
                  aria-selected={activeType === "events"}
                  onClick={() => setActiveType("events")}
                >
                  Events
                </button>
              </li>
            </ul>
          </div>
        </div>

        <div className="filtter-area">
          <div className="container">
            {(filterEvents() && filterEvents().length > 0) || filterLoading ? (
              <>
                <div className="filtter-categ">
                  <div className="left-side">
                    <div className="fillter-box activity-filter">
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        value={selectedActivity}
                        onChange={(e) => setSelectedActivity(e.target.value)}
                      >
                        <option value="Any activity">Any activity</option>
                        {activityList &&
                          activityList.map((activityType) => (
                            <option
                              key={activityType.iActivityId}
                              value={activityType.vActivity}
                            >
                              {activityType.vActivity}
                            </option>
                          ))}
                      </select>
                    </div>

                    {activeType === "events" && (
                      <div className="fillter-box">
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          value={selectedFilter}
                          onChange={(e) => setSelectedFilter(e.target.value)}
                        >
                          <option value="Any type">Any type</option>
                          <option value="Public">Public</option>

                          <option value="Premium">Premium</option>
                        </select>
                      </div>
                    )}

                    <div className="fillter-box category-filter">
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        value={selectedCategory}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                      >
                        <option value="Any category">Any category</option>
                        {categoryList &&
                          categoryList.map((categoryType) => (
                            <option
                              key={categoryType.iCategoryId}
                              value={categoryType.vCategory}
                            >
                              {categoryType.vCategory}
                            </option>
                          ))}
                      </select>
                    </div>

                    <div className="fillter-box">
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        value={selectedOrder}
                        onChange={HandleOrderChange}
                      >
                        <option Value="Sort by: Relevance">
                          Sort by: Relevance
                        </option>
                        <option value="ASC">ASC</option>
                        <option value="DESC">DESC</option>
                        <option value="Newest">Newest</option>
                      </select>
                    </div>
                  </div>

                  <div className="right-side">
                    <div className="fillter-box">
                      <button
                        type="submit"
                        className="fillter-btn"
                        onClick={resetFilters}
                      >
                        Clear all
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </section>

      <section className="event-tab-contain-area pb-60">
        <div className="container">
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              {loading && filterEvents() && filterEvents().length > 0 ? (
                // Show Skeleton while loading
                <SkeletonOneToOneEventList count={9} />
              ) : (
                <div className="row gy-5">
                  <div
                    className={
                      filterEvents() && filterEvents().length > 0
                        ? "col-lg-9"
                        : "col-lg-12"
                    }
                  >
                    <div className="row gy-4">
                      {
                        selectedActivity ||
                        selectedCategory ||
                        selectedFilter ||
                        selectedOrder ? (
                          filterLoading ? (
                            <div className="spinner-container">
                              <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                            </div>
                          ) : !loading &&
                            filterEvents() &&
                            filterEvents().length > 0 &&
                            eventList.length > 0 ? (
                            <div className="col-12">
                              <div className="row gy-4">
                                {filterEvents()
                                  .slice(
                                    0,
                                    limit !== null
                                      ? limit
                                      : eventList && eventList.length
                                  )
                                  .map((event, index) => (
                                    <div
                                      className="col-xl-4 col-lg-6 col-sm-6"
                                      key={index}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <div className="event-box">
                                        <div className="img-box">
                                          <img
                                            src={
                                              event
                                                ? event.vWebpImage
                                                : errorImg
                                            }
                                            alt={event.vImageAlt}
                                            height="370"
                                            width="314"
                                            onError={({ currentTarget }) => {
                                              currentTarget.onerror = null;
                                              currentTarget.src = errorImg;
                                            }}
                                            onClick={() =>
                                              handleDetailClick(event)
                                            }
                                            style={{
                                              objectFit:
                                                event.vWebpImage
                                                  .split("/")
                                                  .pop() === "null"
                                                  ? "contain"
                                                  : "cover",
                                            }}
                                          />
                                          <Link
                                            to={`/event-details/${event.vEvent.replace(
                                              /\s+/g,
                                              "-"
                                            )}`}
                                            state={{ iEventId: event.iEventId }}
                                            className="redirect-btn"
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="37"
                                              height="37"
                                              viewBox="0 0 37 37"
                                              fill="none"
                                            >
                                              <circle
                                                cx="18.5"
                                                cy="18.5"
                                                r="18.5"
                                                fill="white"
                                              />
                                              <path
                                                d="M20.3889 15.7333V12L27 18.5333L20.3889 25.0667V21.3333C15.6667 21.3333 12.3611 22.7333 10 26L10.7556 23.2L10.9444 22.8267C11.7019 20.8975 12.9855 19.2135 14.6526 17.9614C16.3197 16.7093 18.3052 15.9381 20.3889 15.7333Z"
                                                fill="#616161"
                                              />
                                            </svg>
                                          </Link>
                                        </div>
                                        <div className="contain">
                                          <p
                                            className="date-time"
                                            onClick={() =>
                                              handleDetailClick(event)
                                            }
                                          >
                                            {formatDateTime(event.dtEvent)}
                                          </p>
                                          <h2
                                            onClick={() =>
                                              handleDetailClick(event)
                                            }
                                          >
                                            {event.vEvent}
                                          </h2>
                                          <p
                                            className="description"
                                            onClick={() =>
                                              handleDetailClick(event)
                                            }
                                          >
                                            {event.tDescription}
                                          </p>
                                          {event.categories &&
                                            event.categories.map(
                                              (eventCate, category) => {
                                                return (
                                                  <>
                                                    <p
                                                      className="location"
                                                      key={category}
                                                      style={{
                                                        // marginBottom: "10px",
                                                        fontWeight: "bold",
                                                        color: "#fd4700",
                                                      }}
                                                    >
                                                      {eventCate.vCategory}
                                                    </p>
                                                    <p
                                                      className="point-location"
                                                      onClick={() => {
                                                        handleAddressClick(
                                                          event.iEventId
                                                        );
                                                      }}
                                                    >
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        className="bi bi-geo-alt-fill"
                                                        viewBox="0 0 16 16"
                                                      >
                                                        <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                                                      </svg>
                                                      &nbsp;
                                                      {addressVisibility[
                                                        event.iEventId
                                                      ]
                                                        ? event.vLocation
                                                        : event.vLocation.slice(
                                                            0,
                                                            20
                                                          ) +
                                                          (event.vLocation
                                                            .length > 20
                                                            ? "..."
                                                            : "")}
                                                    </p>
                                                  </>
                                                );
                                              }
                                            )}
                                          <p
                                            className="attendees"
                                            onClick={() =>
                                              handleDetailClick(event)
                                            }
                                          >
                                            {event.attendees_count} attendees
                                          </p>
                                          <p
                                            className="attendees"
                                            onClick={() =>
                                              handleDetailClick(event)
                                            }
                                          >
                                            Host by: {event.host}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          ) : (!filterLoading && !filterEvents()) ||
                            filterEvents().length === 0 ? (
                            // selectedActivity === "Any activity" ||
                            // selectedCategory === "Any category" ||
                            // selectedFilter === "Any type" ||
                            // selectedOrder === "Sort by: Relevance" ? (
                            <div className="no-data-found">
                              <div className="spinner-container">
                                <div class="spinner-border" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>
                              </div>

                              <div class="search-button">
                                <button
                                  class="main-search-btn"
                                  onClick={handleFilterClick}
                                >
                                  Clear Filter
                                </button>
                              </div>
                            </div>
                          ) : (
                            // <>hellllllllllllo</>
                            <></>
                          )
                        ) : (
                          !loading &&
                          filterEvents() &&
                          filterEvents().length > 0 &&
                          eventList.length > 0 && (
                            <div className="col-12">
                              <div className="row gy-4">
                                {filterEvents()
                                  .slice(
                                    0,
                                    limit !== null
                                      ? limit
                                      : eventList && eventList.length
                                  )
                                  .map((event, index) => (
                                    <div
                                      className="col-xl-4 col-lg-6 col-sm-6"
                                      key={index}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <div className="event-box">
                                        <div className="img-box">
                                          <img
                                            src={
                                              event
                                                ? event.vWebpImage
                                                : errorImg
                                            }
                                            alt={event.vImageAlt}
                                            height="370"
                                            width="314"
                                            onError={({ currentTarget }) => {
                                              currentTarget.onerror = null;
                                              currentTarget.src = errorImg;
                                            }}
                                            onClick={() =>
                                              handleDetailClick(event)
                                            }
                                            style={{
                                              objectFit:
                                                event.vWebpImage
                                                  .split("/")
                                                  .pop() === "null"
                                                  ? "contain"
                                                  : "cover",
                                            }}
                                          />
                                          <Link
                                            to={`/event-details/${event.vEvent.replace(
                                              /\s+/g,
                                              "-"
                                            )}`}
                                            state={{ iEventId: event.iEventId }}
                                            className="redirect-btn"
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="37"
                                              height="37"
                                              viewBox="0 0 37 37"
                                              fill="none"
                                            >
                                              <circle
                                                cx="18.5"
                                                cy="18.5"
                                                r="18.5"
                                                fill="white"
                                              />
                                              <path
                                                d="M20.3889 15.7333V12L27 18.5333L20.3889 25.0667V21.3333C15.6667 21.3333 12.3611 22.7333 10 26L10.7556 23.2L10.9444 22.8267C11.7019 20.8975 12.9855 19.2135 14.6526 17.9614C16.3197 16.7093 18.3052 15.9381 20.3889 15.7333Z"
                                                fill="#616161"
                                              />
                                            </svg>
                                          </Link>
                                        </div>
                                        <div className="contain">
                                          <p
                                            className="date-time"
                                            onClick={() =>
                                              handleDetailClick(event)
                                            }
                                          >
                                            {formatDateTime(event.dtEvent)}
                                          </p>
                                          <h2
                                            onClick={() =>
                                              handleDetailClick(event)
                                            }
                                          >
                                            {event.vEvent}
                                          </h2>
                                          <p
                                            className="description"
                                            onClick={() =>
                                              handleDetailClick(event)
                                            }
                                          >
                                            {event.tDescription}
                                          </p>
                                          {event.categories &&
                                            event.categories.map(
                                              (eventCate, category) => {
                                                return (
                                                  <>
                                                    <p
                                                      className="location"
                                                      key={category}
                                                      style={{
                                                        // marginBottom: "10px",
                                                        fontWeight: "bold",
                                                        color: "#fd4700",
                                                      }}
                                                    >
                                                      {eventCate.vCategory}
                                                    </p>
                                                    <p
                                                      className="point-location"
                                                      onClick={() => {
                                                        handleAddressClick(
                                                          event.iEventId
                                                        );
                                                      }}
                                                    >
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        className="bi bi-geo-alt-fill"
                                                        viewBox="0 0 16 16"
                                                      >
                                                        <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                                                      </svg>
                                                      &nbsp;
                                                      {addressVisibility[
                                                        event.iEventId
                                                      ]
                                                        ? event.vLocation
                                                        : event.vLocation.slice(
                                                            0,
                                                            20
                                                          ) +
                                                          (event.vLocation
                                                            .length > 20
                                                            ? "..."
                                                            : "")}
                                                    </p>
                                                  </>
                                                );
                                              }
                                            )}
                                          <p
                                            className="attendees"
                                            onClick={() =>
                                              handleDetailClick(event)
                                            }
                                          >
                                            {event.attendees_count} attendees
                                          </p>
                                          <p
                                            className="attendees"
                                            onClick={() =>
                                              handleDetailClick(event)
                                            }
                                          >
                                            Host by: {event.host}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          )
                        )
                        // : !filterEvents() ||
                        //   filterEvents().length === 0 ||
                        //   selectedActivity === "Any activity" ||
                        //   selectedCategory === "Any category" ||
                        //   selectedFilter === "Any type" ||
                        //   selectedOrder === "Sort by: Relevance" ? (
                        //   <div className="no-data-found">
                        //     <div class="spinner-border" role="status">
                        //       <span class="sr-only">Loading...</span>
                        //     </div>
                        //     <>++++++++++++++++++++</>

                        //     <div class="search-button">
                        //       <button
                        //         class="main-search-btn"
                        //         onClick={handleFilterClick}
                        //       >
                        //         Clear Filter
                        //       </button>
                        //     </div>
                        //   </div>
                        // ) : (
                        //   <></>
                        // )
                      }

                      {!filterLoading &&
                        filterEvents() &&
                        filterEvents().length > 6 &&
                        limit !== null && (
                          <div className="col-12">
                            <Link
                              to="#"
                              className="secondary-btn category-view-btn"
                              onClick={handleViewAllClick}
                            >
                              View all
                            </Link>
                          </div>
                        )}
                    </div>
                  </div>
                  {filterEvents() && filterEvents().length > 0 ? (
                    <div
                      className={`col-lg-3 ${
                        filterEvents() && filterEvents().length === 0
                          ? "d-none"
                          : ""
                      }`}
                    >
                      <div className="event-map">
                        <div className="mapouter">
                          <div className="gmap_canvas">
                            <div
                              id="map"
                              style={{
                                width: "100%",
                                height: "860px",
                                title: "map",
                                className: "gmap_iframe",
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default EventListing;
