import React from "react";
import Login from "../components/Common/Login";


const LoginPage = () => {
  return (

    <Login />

  );
}

export default LoginPage;
