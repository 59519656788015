import {
    CORE_VALUES_REQUEST,
    CORE_VALUES_SUCCESS,
    CORE_VALUES_FAILURE,

    HOW_IT_WORKS_REQUEST,
    HOW_IT_WORKS_SUCCESS,
    HOW_IT_WORKS_FAILURE,

    WHY_WELLNAS_REQUEST,
    WHY_WELLNAS_SUCCESS,
    WHY_WELLNAS_FAILURE,

    PAGE_LIST_JOIN_REQUEST,
    PAGE_LIST_JOIN_SUCCESS,
    PAGE_LIST_JOIN_FAILURE,

    PAGE_LIST_ABOUT_REQUEST,
    PAGE_LIST_ABOUT_SUCCESS,
    PAGE_LIST_ABOUT_FAILURE,

} from "../constants/aboutConstant";

import axios from "axios";


export const getCoreValues = (data) => async (dispatch) => {
    try {
        dispatch({ type: CORE_VALUES_REQUEST });
        const resData = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/core-values/get_all_data`, data
        );
        dispatch({ type: CORE_VALUES_SUCCESS, payload: resData.data.data });
    } catch (err) {
        dispatch({ type: CORE_VALUES_FAILURE });
    }
};

export const getHowItWorks = (data) => async (dispatch) => {
    try {
        dispatch({ type: HOW_IT_WORKS_REQUEST });
        const resData = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/how-it-works/get_all_data`, data
        );
        dispatch({ type: HOW_IT_WORKS_SUCCESS, payload: resData.data.data });
    } catch (err) {
        dispatch({ type: HOW_IT_WORKS_FAILURE });
    }
};

export const getWhyWellNas = (data) => async (dispatch) => {
    try {
        dispatch({ type: WHY_WELLNAS_REQUEST });
        const resData = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/why-wellnas/get_all_data`, data
        );
        dispatch({ type: WHY_WELLNAS_SUCCESS, payload: resData.data.data });
    } catch (err) {
        dispatch({ type: WHY_WELLNAS_FAILURE });
    }
};

export const getPageListJoin = (data) => async (dispatch) => {
    try {
        dispatch({ type: PAGE_LIST_JOIN_REQUEST });
        const resData = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/page/join_us`, data
        );
        dispatch({ type: PAGE_LIST_JOIN_SUCCESS, payload: resData.data.data });
    } catch (err) {
        dispatch({ type: PAGE_LIST_JOIN_FAILURE });
    }
};

export const getPageListAbout = (data) => async (dispatch) => {
    try {
        dispatch({ type: PAGE_LIST_ABOUT_REQUEST });
        const resData = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/page/about_us`, data
        );
        dispatch({ type: PAGE_LIST_ABOUT_SUCCESS, payload: resData.data.data });
    } catch (err) {
        dispatch({ type: PAGE_LIST_ABOUT_FAILURE });
    }
};