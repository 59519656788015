import React from "react";
import Container from "../components/layouts/Container";
import Index from "../components/MyEvent/Index";
import { useNavigate } from "react-router";

const MyEventPage = () => {
  const navigate = useNavigate();
  // const isAuthenticated = sessionStorage.getItem("vUniqueCode");
  const isAuthenticated = localStorage.getItem("vUniqueCode");

  React.useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated,navigate]);
  return (
    <Container>
      <Index />
    </Container>
  );
};

export default MyEventPage;
