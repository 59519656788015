import React from "react";
import { useLocation } from "react-router";

const TermCondition = () => {
  const location = useLocation();
  React.useEffect(() => {
    document.title = "Term-condition | WellNas";
  }, []);

  return (
    <React.Fragment>
      <section
        className="inner-page-breadcamp"
        style={{ backgroundImage: "url(assets/images/breadcamp-image.png)" }}
      >
        <div className="container">
          <div className="brad-camp-detail">
            <p className="sub-title">Terms & Conditions</p>
          </div>
        </div>
      </section>
      <section className="terms-text-page p-100">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-8 mx-auto"
              // style={{ margin: "0 auto", maxWidth: "900px", width: "100%" }}
            >
              <h1
                className="sub-title-term-condition mb-sm-5 mb-4"
                // style={{
                //   textAlign: "center",
                //   marginBottom: "20px",
                //   justifyContent: "center",
                //   alignItems: "center",
                //   marginTop: "40px",
                // }}
              >
                Terms & Conditions
              </h1>
              <div>
                <ul>
                  <li>
                    <h2>Acceptance of Terms</h2>
                    <p>
                      By using WellNas, you agree to comply with and be bound by
                      these Terms and Conditions. If you do not agree with any
                      part of these terms, please do not use our platform.
                    </p>
                  </li>
                  <li>
                    <h2>User Registration</h2>
                    <p>
                      To access certain features of WellNas, you may be required
                      to register an account. You agree to provide accurate and
                      current information during the registration process and to
                      update such information to keep it accurate and current.
                    </p>
                  </li>
                  <li>
                    <h2>Code of Conduct</h2>
                    <p>
                      You agree to use WellNas in a manner consistent with all
                      applicable laws and regulations. You will not:
                    </p>
                    <ul>
                      <li>Violate any rights of third ularties.</li>
                      <li>
                        Post or share content that is harmful, offensive, or
                        violates community standards.
                      </li>
                      <li>
                        Attempt to gain unauthorized access to our platform or
                        user accounts.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <h2>Privacy</h2>
                    <p>
                      Your use of WellNas is also governed by our Privacy
                      Policy. Please review our Privacy Policy to understand our
                      practices regarding the collection, use, and disclosure of
                      your personal information.
                    </p>
                  </li>
                  <li>
                    <h2>Events on WellNas</h2>
                    <p>
                      Participation in events on WellNas is subject to
                      additional terms and conditions, which will be provided
                      before your participation in each event.
                    </p>
                    <p>
                      WellNas strongly advises event hosts to ensure proper
                      safety measures, permissions, and adherence to local laws
                      for their events. Any disputes or liabilities arising from
                      an event are the sole responsibility of the host. WellNas
                      serves as a platform for event organization and
                      connection, but the actual events are entirely managed by
                      the respective hosts. Participants are encouraged to
                      exercise caution and refer to hosts for
                      event-related matters.
                    </p>
                  </li>
                  <li>
                    <h2>Intellectual Property</h2>
                    <p>
                      The content on WellNas, including text, graphics, logos,
                      and images, is the intellectual property of WellNas. You
                      agree not to use, reproduce, or distribute any content
                      from WellNas without our prior written consent.
                    </p>
                  </li>
                  <li>
                    <h2>Termination</h2>
                    <p>
                      WellNas reserves the right to terminate or suspend your
                      account and access to our platform if you violate these
                      Terms and Conditions or for any other reason at our
                      discretion.
                    </p>
                  </li>
                  <li>
                    <h2>Changes to Terms and Conditions</h2>
                    <p>
                      We may update these Terms and Conditions to reflect
                      changes in our services or legal requirements. The
                      effective date will indicate the most recent revision.
                    </p>
                  </li>
                  <li>
                    <h2>Contact Us</h2>
                    <p>
                      If you have any questions or concerns about our Terms and
                      Conditions, please contact us at   <a href="mailto:info@wellnas.app">[info@wellnas.app]</a>.
                    </p>
                    <p>
                      By using WellNas, you agree to the terms outlined in these
                      Terms and Conditions.
                    </p>
                    <p>
                      For the complete Terms and Conditions, please visit:{" "}
                      <a href="mailto:info@wellnas.app">[info@wellnas.app]</a>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default TermCondition;
