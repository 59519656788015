import React from "react";
import Notification from "../components/Notification/Notification";
import Container from "../components/layouts/Container";
import { useNavigate } from "react-router";

const NotificationPage = () => {
  const navigate = useNavigate();
  const isAuthenticated =
    //  sessionStorage.getItem("vUniqueCode") ||
    localStorage.getItem("vUniqueCode");

  React.useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated,navigate]);
  return (
    <Container>
      <div className="profile-layer">
        <Notification />
      </div>
    </Container>
  );
};

export default NotificationPage;
