import React from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerProfile } from "../../store/action/customerAction";

import ReactStars from "react-rating-star-with-type";

const AddReview = () => {
  // const errorImg = "/assets/images/no_image.png";
  // const errorImg = "/assets/images/no-image-icon.png";
  const errorImg = "/assets/images/no-image.png";

  const { state } = useLocation();
  const { iEventId } = state ?? {};

  const dispatch = useDispatch();

  const { customerProfile } = useSelector((state) => state.customerProfile);

  // variables for add review
  const [addReview, setAddReview] = React.useState("");
  const [rating, setRating] = React.useState("");

  // variables for success & error review
  const [addReviewError, setAddReviewError] = React.useState("");
  const [ratingError, setRatingError] = React.useState("");
  const [addReviewSuccess, setAddReviewSuccess] = React.useState(false);

  const vUniqueCode =
    // sessionStorage.getItem("vUniqueCode") ||
    localStorage.getItem("vUniqueCode");
  const isUserLoggedIn = !!vUniqueCode;

  const handleReview = (review) => {
    setAddReview(review.target.value);
    setAddReviewError("");
  };
  const handleRating = (nextValue) => {
    setRating(nextValue);
    setRatingError("");
  };

  // validation conditions for contact-us
  const validateReview = React.useCallback(() => {
    let isValid = true;

    // Reset error messages
    setAddReviewError("");
    setRatingError("");

    if (rating.length === 0) {
      setRatingError("Rating is required.");
      isValid = false;
    }
    if (addReview.length === 0) {
      setAddReviewError("Message is required ");
      isValid = false;
    } else if (addReview.length < 100) {
      setAddReviewError("Message should be at least 100 characters.");
      isValid = false;
    }
    if (addReview.length > 1000) {
      setAddReviewError("Maximum limit is 1000 characters.");
      isValid = false;
    }
    return isValid;
  }, [addReview, rating]);

  // for customer profile View
  React.useEffect(() => {
    const vUniqueCode =
      // sessionStorage.getItem("vUniqueCode") ||
      localStorage.getItem("vUniqueCode");
    if (!customerProfile && vUniqueCode) {
      dispatch(
        getCustomerProfile({
          vUniqueCode: vUniqueCode,
        })
      );
    }
  }, [customerProfile, dispatch]);

  // for add new review
  const addNewReview = React.useCallback(
    async (e) => {
      e.preventDefault();

      const isValid = validateReview();

      if (isValid) {
        const formData = new FormData();
        formData.append("vUniqueCode", vUniqueCode);
        formData.append("iEventId", iEventId);
        formData.append("tDescription", addReview);
        formData.append("vRating", rating);

        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/review/add`,
            formData,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          if (response.status === 200) {
            setAddReviewSuccess(true);

            setAddReview("");
            setRating(0);

            setTimeout(() => {
              setAddReviewSuccess(false);
              window.location.reload();
            }, 2000);
          }
        } catch (error) {}
      }
    },
    [addReview, rating, iEventId, vUniqueCode, validateReview]
  );

  // for add review show/hide
  if (!isUserLoggedIn) {
    return null;
  }

  return (
    <React.Fragment>
      <div className="container">
        <div className="write-review-sec p-100">
          <h2>Write Review</h2>
          <form onSubmit={addNewReview}>
            <div className="write-review-box">
              {/* customer info */}
              {customerProfile && (
                <div className="review-user">
                  <img
                    className="img-cover"
                    src={
                      customerProfile ? customerProfile.vWebpImage : errorImg
                    }
                    alt={customerProfile.vImageAlt}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = errorImg;
                    }}
                    style={{
                      objectFit:
                        customerProfile.vWebpImage.split("/").pop() === "null"
                          ? "contain"
                          : "cover",
                    }}
                  />
                  <div className="u-title">
                    <p className="name">
                      {customerProfile.vFirstName} {customerProfile.vLastName}
                    </p>
                    <p className="sub-n">
                      {customerProfile.vFirstName} {customerProfile.vLastName}
                    </p>
                  </div>
                </div>
              )}

              {/* rating part */}
              <div>
                <ReactStars
                  isEdit={true}
                  value={rating}
                  onChange={handleRating}
                  count={5}
                  size={20}
                  inactiveColor="#808080"
                  activeColor="#FED900"
                />
                {ratingError && (
                  <div
                    className="error-msg"
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginLeft: "6px",
                    }}
                  >
                    {ratingError}
                  </div>
                )}
              </div>
              {/* <div className="rating">
                        <img src="/assets/images/star.png" alt="rating" />
                     </div> */}

              {/* write review box */}
              <div className="form-group">
                <textarea
                  className="form-control"
                  placeholder="Additional Comments.."
                  value={addReview}
                  onChange={handleReview}
                />
                {addReviewError && (
                  <div
                    className="error-msg"
                    style={{
                      color: "red",
                      fontSize: "14px",
                      marginLeft: "12px",
                    }}
                  >
                    {addReviewError}
                  </div>
                )}
              </div>
              <button type="submit" className="secondary-btn border-0">
                Submit Review
              </button>
            </div>
            {addReviewSuccess && (
              <p style={{ color: "green", fontSize: "18px" }}>
                Your review added successfully
              </p>
            )}
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddReview;
