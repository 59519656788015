import {
    TAG_LIST_REQUEST,
    TAG_LIST_SUCCESS,
    TAG_LIST_FAILURE,

} from "../constants/tagConstant";

export function tagReducer(state = {}, action) {
    switch (action.type) {
        case TAG_LIST_REQUEST:
            return {
                loading: true,
            }
        case TAG_LIST_SUCCESS:
            return {
                loading: false,
                tagList: action.payload,
            }
        case TAG_LIST_FAILURE:
            return {
                loading: false,
            }
        default:
            return state
    }
};