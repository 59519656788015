import {
    ACTIVITY_LIST_REQUEST,
    ACTIVITY_LIST_SUCCESS,
    ACTIVITY_LIST_FAILURE,

    PREFERRED_ACTIVITY_LIST_REQUEST,
    PREFERRED_ACTIVITY_LIST_SUCCESS,
    PREFERRED_ACTIVITY_LIST_FAILURE,

    ADD_PREFERRED_ACTIVITY_REQUEST,
    ADD_PREFERRED_ACTIVITY_SUCCESS,
    ADD_PREFERRED_ACTIVITY_FAILURE,

} from "../constants/activityConstant";

import axios from "axios";


export const getActivityList = (data) => async (dispatch) => {
    try {
        dispatch({ type: ACTIVITY_LIST_REQUEST });
        const resData = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/activity/get_all_data`, data
        );
        dispatch({ type: ACTIVITY_LIST_SUCCESS, payload: resData.data.data });
    } catch (err) {
        dispatch({ type: ACTIVITY_LIST_FAILURE });
    }
};

export const getPreferredActivityList = (data) => async (dispatch) => {
    try {
        dispatch({ type: PREFERRED_ACTIVITY_LIST_REQUEST });
        const resData = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/customer-activity/get_by_id`, data
        );
        dispatch({ type: PREFERRED_ACTIVITY_LIST_SUCCESS, payload: resData.data.data });
    } catch (err) {
        dispatch({ type: PREFERRED_ACTIVITY_LIST_FAILURE });
    }
};

export const getAddPreferredActivity = (data) => async (dispatch) => {
    try {
        dispatch({ type: ADD_PREFERRED_ACTIVITY_REQUEST });
        const resData = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/customer-activity/add`, data
        );
        dispatch({ type: ADD_PREFERRED_ACTIVITY_SUCCESS, payload: resData.data.data });
    } catch (err) {
        dispatch({ type: ADD_PREFERRED_ACTIVITY_FAILURE });
    }
};