import React from "react";
import OwlCarousel from "react-owl-carousel";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

const Register = () => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = React.useState("register");

  // state variables for form inputs
  const [isTcChecked, setIsTcChecked] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [emailId, setEmailId] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [selectType, setSelectType] = React.useState("User");

  // console.log(firstName, lastName, emailId, password, selectType);

  // state variables for form inputs
  //   const [emailIdLogin, setEmailIdLogin] = React.useState("");
  //   const [passwordLogin, setPasswordLogin] = React.useState("");

  // state variables for Register error messages
  const [firstNameError, setFirstNameError] = React.useState("");
  const [lastNameError, setLastNameError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");
  const [selectTypeError, setSelectTypeError] = React.useState("");
  const [tcError, setTcError] = React.useState("");

  //   // state variables for Login error messages
  //   const [emailLoginError, setEmailLoginError] = React.useState("");
  //   const [passwordLoginError, setPasswordLoginError] = React.useState("");

  // state variables for response msg
  const [errorMessage, setErrorMessage] = React.useState("");

  // here through hide the offcanvas modal
  window.$("#offcanvasRight").offcanvas("hide");

  // for email validation
  const isValidEmail = React.useCallback((email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }, []);

  const handleType = (event) => {
    setSelectType(event.target.value);
    setSelectTypeError("");
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
    setFirstNameError("");
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
    setLastNameError("");
  };

  const handleEmailChange = (event) => {
    setEmailId(event.target.value);
    setEmailError("");
  };

  //   const handleEmailLoginChange = (event) => {
  //     setEmailIdLogin(event.target.value);
  //     setEmailLoginError("");
  //   };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setPasswordError("");
  };

  //   const handlePasswordLoginChange = (event) => {
  //     setPasswordLogin(event.target.value);
  //     setPasswordLoginError("");
  //   };

  const handleCheckboxChange = () => {
    setIsTcChecked(!isTcChecked);
    setTcError("");
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // validation conditions for Register
  const validateForm = React.useCallback(() => {
    let isValid = true;

    // Reset error messages
    setSelectTypeError("");
    setFirstNameError("");
    setLastNameError("");
    setEmailError("");
    setPasswordError("");
    setTcError("");

    if (!selectType) {
      setSelectTypeError("Type is required. Please select an option.");
      isValid = false;
    }
    if (firstName.length === 0) {
      setFirstNameError("First Name is required.");
      isValid = false;
    }
    if (lastName.length === 0) {
      setLastNameError("Last Name is required.");
      isValid = false;
    }
    if (emailId.length === 0) {
      setEmailError("Email is required.");
      isValid = false;
    } else if (!isValidEmail(emailId)) {
      setEmailError("Invalid email format.");
      isValid = false;
    }
    if (password.length < 6) {
      setPasswordError("Password must be at least 6 characters.");
      isValid = false;
    }
    if (!isTcChecked) {
      setTcError("Please accept the terms and conditions.");
      isValid = false;
    }

    return isValid;
  }, [
    firstName,
    lastName,
    emailId,
    password,
    isTcChecked,
    selectType,
    isValidEmail,
  ]);

  // validation conditions for Login
  //   const validateLoginForm = React.useCallback(() => {
  //     let isValid = true;

  //     // Reset error messages
  //     setEmailLoginError("");
  //     setPasswordLoginError("");

  //     if (emailIdLogin.length === 0) {
  //       setEmailLoginError("Email is required.");
  //       isValid = false;
  //     } else if (!isValidEmail(emailIdLogin)) {
  //       setEmailLoginError("Invalid email format.");
  //       isValid = false;
  //     }
  //     if (passwordLogin.length < 6) {
  //       setPasswordLoginError("Password must be at least 6 characters.");
  //       isValid = false;
  //     }

  //     return isValid;
  //   }, [emailIdLogin, passwordLogin, isValidEmail]);

  // for register
  const signUpCustomer = React.useCallback(
    async (e) => {
      e.preventDefault();

      const isValid = validateForm();
      if (isValid) {
        const values = {
          vFirstName: firstName,
          vLastName: lastName,
          vEmail: emailId,
          vPassword: password,
          eType: selectType,
        };
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/customer/register`,
            values
          );

          // console.log("response", response);
          if (response.status === 200) {
            setFirstName("");
            setLastName("");
            setEmailId("");
            setPassword("");
            setIsTcChecked(false);
            navigate("/success-registration");
          }
        } catch (error) {
          if (error.response && error.response.status === 422) {
            setErrorMessage("Customer Email Already Exists.");

            setTimeout(() => {
              setErrorMessage("");
            }, 4000);
          }
        }
      } else {
      }
    },
    [firstName, lastName, emailId, password, selectType, validateForm, navigate]
  );

  // for google resgister token
  const googleSignUp = () => {
    const form = document.createElement("form");
    form.setAttribute("method", "GET");
    form.setAttribute("action", "https://accounts.google.com/o/oauth2/v2/auth");

    const vParameters = {
      client_id:
        "1066340452659-tb47pdjh00kitjlibm3dphqadmame7c9.apps.googleusercontent.com",
      // "896895017271-4j7ag1d600efdm934gcuj77g7oopi5in.apps.googleusercontent.com",
      // redirect_uri: "http://127.0.0.1:5500/profile.html",
      // redirect_uri: "http://localhost:8000/google_response",
      // redirect_uri: "https://wellnas.demo-available.com/google_response",
      redirect_uri: "https://wellnas.app/google_response",
      response_type: "token",
      scope:
        "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile",
      // 'scope': 'https://www.googleapis.com/auth/userinfo.profile',
      // 'scope': 'https://www.googleapis.com/auth/userinfo.email',
      include_granted_scopes: "true",
      state: "pass-through-value",
    };

    for (const vKey in vParameters) {
      const input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", vKey);
      input.setAttribute("value", vParameters[vKey]);
      form.appendChild(input);
    }

    document.body.appendChild(form);
    form.submit();
  };

  // for login
  //   const signInCustomer = React.useCallback(
  //     async (e) => {
  //       e.preventDefault();

  //       const isValidLogin = validateLoginForm();
  //       if (isValidLogin) {
  //         const values = {
  //           vEmail: emailIdLogin,
  //           vPassword: passwordLogin,
  //         };
  //         try {
  //           const response = await axios.post(
  //             `${process.env.REACT_APP_API_URL}/api/customer/login`,
  //             values
  //           );
  //           if (response.status === 200) {
  //             setEmailIdLogin("");
  //             setPasswordLogin("");
  //             sessionStorage.setItem("vUniqueCode", response.data.data);

  //             navigate("/profile");
  //             // setTimeout(() => {
  //             //    console.log('%%%%%%%%%',);
  //             //    navigate('/profile');
  //             // }, 7000);
  //           }
  //         } catch (error) {
  //           if (error.response) {
  //             if (error.response.status === 401) {
  //               if (error.response.data.message === "InvalidEmailOrPassword") {
  //                 setErrorMessage(
  //                   "You Have Entered An Invalid Email or Password."
  //                 );
  //               } else if (error.response.data.message === "EmailNotVerified") {
  //                 setErrorMessage("Please Verify Your Email Before Sign In.");
  //               }
  //             } else {
  //               setErrorMessage("Customer not found");
  //             }

  //             setTimeout(() => {
  //               setErrorMessage("");
  //             }, 2000);
  //           }
  //         }
  //       }
  //     },
  //     [emailIdLogin, passwordLogin, validateLoginForm, navigate]
  //   );

  // for google login token
  //   const googleSignIn = () => {
  //     const form = document.createElement("form");
  //     form.setAttribute("method", "GET");
  //     form.setAttribute("action", "https://accounts.google.com/o/oauth2/v2/auth");

  //     const vParameters = {
  //       client_id:
  //         "1066340452659-tb47pdjh00kitjlibm3dphqadmame7c9.apps.googleusercontent.com",
  //       // "896895017271-4j7ag1d600efdm934gcuj77g7oopi5in.apps.googleusercontent.com",
  //       // redirect_uri: "http://127.0.0.1:5500/profile.html",
  //       // redirect_uri: "http://localhost:8000/google_response",
  //       // redirect_uri: "https://wellnas.demo-available.com/google_response",
  //       redirect_uri: "https://wellnas.app/google_response",
  //       response_type: "token",
  //       scope:
  //         "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile",
  //       // 'scope': 'https://www.googleapis.com/auth/userinfo.profile',
  //       // 'scope': 'https://www.googleapis.com/auth/userinfo.email',
  //       include_granted_scopes: "true",
  //       state: "pass-through-value",
  //     };

  //     for (const vKey in vParameters) {
  //       const input = document.createElement("input");
  //       input.setAttribute("type", "hidden");
  //       input.setAttribute("name", vKey);
  //       input.setAttribute("value", vParameters[vKey]);
  //       form.appendChild(input);
  //     }

  //     document.body.appendChild(form);
  //     form.submit();
  //   };

  const navText = [
    "<i className='fal fa-long-arrow-left'></i>",
    "<i className='fal fa-long-arrow-right'></i>",
  ];

  const handleNavigation = (linkName) => {
    setActiveTab(linkName);
    navigate(`/${linkName}`);
    //  console.log("linkName", linkName);
  };

  return (
    <React.Fragment>
      <div className="signup-page">
        <div className="container">
          <div className="logo text-center">
            <Link to={"/"}>
              <img
                src="assets/images/logo.png"
                alt="wellnes"
                height="65"
                width="95"
              />
            </Link>
          </div>

          <div className="row signup-box g-0">
            <div className="col-lg-6 col-md-12">
              <div className="login-detail">
                <h1 className="sub-title">Welcome to WellNas</h1>
                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <a
                      // className="nav-link active main-btn"
                      className={`nav-link ${
                        activeTab === "login" ? "active" : ""
                      } main-btn`}
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      // aria-selected="true"
                      aria-selected={activeTab === "login"}
                      onClick={() => handleNavigation("login")}
                    >
                      Sign In
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      // className="nav-link"
                      className={`nav-link ${
                        activeTab === "register" ? "active" : ""
                      }`}
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      // aria-selected="false"
                      aria-selected={activeTab === "register"}
                      onClick={() => handleNavigation("register")}
                    >
                      Sign Up
                    </a>
                  </li>
                </ul>

                <div className="tab-content" id="pills-tabContent">
                  {/* --- login form --- */}
                  {/* <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  > */}
                  {/* <form onSubmit={signInCustomer}>
                      <div className="input-box">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                          Email
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="exampleInputEmail1"
                          value={emailIdLogin}
                          onChange={handleEmailLoginChange}
                          aria-describedby="emailHelp"
                          placeholder="Enter your Email"
                        />
                        {emailLoginError && (
                          <div
                            className="error-msg"
                            style={{
                              color: "red",
                              fontSize: "14px",
                              marginLeft: "20px",
                            }}
                          >
                            {emailLoginError}
                          </div>
                        )}
                      </div>
                      <div className="input-box">
                        <label
                          htmlFor="exampleInputpassword"
                          className="form-label"
                        >
                          Password
                        </label>
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control"
                          id="exampleInputpassword"
                          value={passwordLogin}
                          onChange={handlePasswordLoginChange}
                          aria-describedby="emailHelp"
                          placeholder="••••••••"
                        />
                        {passwordLoginError && (
                          <div
                            className="error-msg"
                            style={{
                              color: "red",
                              fontSize: "14px",
                              marginLeft: "20px",
                            }}
                          >
                            {passwordLoginError}
                          </div>
                        )}
                        <i
                          className={`far ${
                            showPassword ? "fa-eye" : "fa-eye-slash"
                          } eye-icon`}
                          onClick={togglePasswordVisibility}
                        ></i>
                      </div>
                      <div className="ft-links">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            checked={isTcChecked}
                            onChange={() => setIsTcChecked(!isTcChecked)}
                            id="flexCheckDefault"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault"
                          >
                            Keep me login
                          </label>
                        </div>
                        <Link
                          to={"/forget-password"}
                          className="forgot-password-link"
                        >
                          Forget Password ?
                        </Link>
                      </div>
                      <button type="submit" className="main-btn w-100">
                        Sign In
                      </button>
                      {errorMessage && (
                        <div
                          className="error-msg"
                          style={{
                            color: "red",
                            fontSize: "14px",
                            marginLeft: "22px",
                          }}
                        >
                          {errorMessage}
                        </div>
                      )}
                    </form> */}

                  {/* --- other ways to login --- */}
                  {/* <div className="other-login">
                      <div className="sign-text">
                        <p>Or Sign In with</p>
                      </div>
                      <ul>
                        <li>
                          <button
                            type="button"
                            onClick={googleSignIn}
                            className="other-login-account"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="26"
                              height="25"
                              viewBox="0 0 26 25"
                              fill="none"
                            >
                              <g id="Frame" clipPath="url(#clip0_207_146)">
                                <path
                                  id="Vector"
                                  d="M25.2042 12.7779C25.2042 11.75 25.1182 11.0002 24.9327 10.2223H12.8604V14.8613H19.9466C19.8036 16.0141 19.0323 17.75 17.3178 18.9169L17.2938 19.072L21.1122 21.9466L21.3767 21.9723C23.8043 19.7916 25.2042 16.5835 25.2042 12.7779Z"
                                  fill="#4285F4"
                                />
                                <path
                                  id="Vector_2"
                                  d="M12.8601 25C16.3321 25 19.2461 23.8889 21.3748 21.9723L17.3171 18.9184C16.2312 19.6542 14.7742 20.1681 12.8596 20.1681C9.45955 20.1681 6.57322 17.9878 5.5436 14.9738L5.39274 14.9862L1.42471 17.9725L1.37305 18.1128C3.48767 22.1937 7.831 25 12.8601 25Z"
                                  fill="#34A853"
                                />
                                <path
                                  id="Vector_3"
                                  d="M5.54521 14.9722C5.26392 14.1766 5.11884 13.3415 5.11575 12.5002C5.12067 11.6602 5.26012 10.8261 5.52909 10.0278L5.52206 9.86226L1.50485 6.82812L1.37341 6.8888C0.471659 8.62938 0.00195313 10.5511 0.00195312 12.5C0.00195313 14.4489 0.471659 16.3706 1.37341 18.1112L5.54521 14.9722Z"
                                  fill="#FBBC05"
                                />
                                <path
                                  id="Vector_4"
                                  d="M12.8596 4.83507C15.2739 4.83507 16.9029 5.84886 17.8312 6.6963L21.4603 3.2515C19.2316 1.23799 16.3312 0.00158691 12.8596 0.00158691C7.83059 0.00158691 3.48726 2.80709 1.37305 6.89038L5.52872 10.0278C6.57322 7.01414 9.45954 4.83507 12.8596 4.83507Z"
                                  fill="#EB4335"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_207_146">
                                  <rect
                                    width="25.2023"
                                    height="25"
                                    fill="white"
                                    transform="translate(0.00195312)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                            Google
                          </button>
                        </li>
                      </ul>
                    </div> */}
                </div>

                {/* --- register form --- */}
                <div
                  // className="tab-pane fade"
                  className={`tab-pane fade ${
                    activeTab === "register" ? "show active" : ""
                  }`}
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  <form onSubmit={signUpCustomer}>
                    <div className="input-box">
                      <label htmlFor="exampleType" className="form-label">
                        Type
                      </label>
                      <select
                        className="form-select form-control"
                        aria-label="Default select example"
                        id="exampleType"
                        value={selectType}
                        onChange={handleType}
                      >
                        <option defaultValue="User">User</option>
                        <option value="Business">Business</option>
                      </select>
                      {selectTypeError && (
                        <div
                          className="error-msg"
                          style={{
                            color: "red",
                            fontSize: "14px",
                            marginLeft: "20px",
                          }}
                        >
                          {selectTypeError}
                        </div>
                      )}
                    </div>
                    <div className="input-box">
                      <label
                        htmlFor="exampleInputFirstName"
                        className="form-label"
                      >
                        First Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputFirstName"
                        value={firstName}
                        onChange={handleFirstNameChange}
                        placeholder="Enter your First Name"
                      />
                      {firstNameError && (
                        <div
                          className="error-msg"
                          style={{
                            color: "red",
                            fontSize: "14px",
                            marginLeft: "20px",
                          }}
                        >
                          {firstNameError}
                        </div>
                      )}
                    </div>
                    <div className="input-box">
                      <label
                        htmlFor="exampleInputLastName"
                        className="form-label"
                      >
                        Last Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputLastName"
                        value={lastName}
                        onChange={handleLastNameChange}
                        placeholder="Enter your Last Name"
                      />
                      {lastNameError && (
                        <div
                          className="error-msg"
                          style={{
                            color: "red",
                            fontSize: "14px",
                            marginLeft: "20px",
                          }}
                        >
                          {lastNameError}
                        </div>
                      )}
                    </div>
                    <div className="input-box">
                      <label htmlFor="exampleInputEmail" className="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail"
                        value={emailId}
                        onChange={handleEmailChange}
                        aria-describedby="emailHelp"
                        placeholder="Enter your Email"
                      />
                      {emailError && (
                        <div
                          className="error-msg"
                          style={{
                            color: "red",
                            fontSize: "14px",
                            marginLeft: "20px",
                          }}
                        >
                          {emailError}
                        </div>
                      )}
                    </div>
                    <div className="input-box">
                      <label
                        htmlFor="exampleInputPassword"
                        className="form-label"
                      >
                        Password
                      </label>
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        id="exampleInputPassword"
                        value={password}
                        onChange={handlePasswordChange}
                        aria-describedby="emailHelp"
                        placeholder="••••••••"
                      />
                      {passwordError && (
                        <div
                          className="error-msg"
                          style={{
                            color: "red",
                            fontSize: "14px",
                            marginLeft: "20px",
                          }}
                        >
                          {passwordError}
                        </div>
                      )}
                      <i
                        className={`far ${
                          showPassword ? "fa-eye" : "fa-eye-slash"
                        } eye-icon`}
                        onClick={togglePasswordVisibility}
                      ></i>
                    </div>
                    <div className="ft-links">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          checked={isTcChecked}
                          onChange={handleCheckboxChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          I Accept terms and conditions & privacy policy
                        </label>
                        {tcError && (
                          <div
                            className="error-msg"
                            style={{
                              color: "red",
                              fontSize: "14px",
                              marginLeft: "12px",
                            }}
                          >
                            {tcError}
                          </div>
                        )}
                      </div>
                    </div>
                    <button type="submit" className="main-btn w-100">
                      Sign Up
                    </button>
                    {errorMessage && (
                      <div
                        className="error-msg"
                        style={{
                          color: "red",
                          fontSize: "14px",
                          marginLeft: "22px",
                        }}
                      >
                        {errorMessage}
                      </div>
                    )}
                  </form>

                  {/* --- other way to register --- */}
                  {/* <div className="other-login">
                    <div className="sign-text">
                      <p>Or Sign Up with</p>
                    </div>
                    <ul>
                      <li>
                        <button
                          type="button"
                          onClick={googleSignUp}
                          className="other-login-account"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="26"
                            height="25"
                            viewBox="0 0 26 25"
                            fill="none"
                          >
                            <g id="Frame" clipPath="url(#clip0_207_146)">
                              <path
                                id="Vector"
                                d="M25.2042 12.7779C25.2042 11.75 25.1182 11.0002 24.9327 10.2223H12.8604V14.8613H19.9466C19.8036 16.0141 19.0323 17.75 17.3178 18.9169L17.2938 19.072L21.1122 21.9466L21.3767 21.9723C23.8043 19.7916 25.2042 16.5835 25.2042 12.7779Z"
                                fill="#4285F4"
                              />
                              <path
                                id="Vector_2"
                                d="M12.8601 25C16.3321 25 19.2461 23.8889 21.3748 21.9723L17.3171 18.9184C16.2312 19.6542 14.7742 20.1681 12.8596 20.1681C9.45955 20.1681 6.57322 17.9878 5.5436 14.9738L5.39274 14.9862L1.42471 17.9725L1.37305 18.1128C3.48767 22.1937 7.831 25 12.8601 25Z"
                                fill="#34A853"
                              />
                              <path
                                id="Vector_3"
                                d="M5.54521 14.9722C5.26392 14.1766 5.11884 13.3415 5.11575 12.5002C5.12067 11.6602 5.26012 10.8261 5.52909 10.0278L5.52206 9.86226L1.50485 6.82812L1.37341 6.8888C0.471659 8.62938 0.00195313 10.5511 0.00195312 12.5C0.00195313 14.4489 0.471659 16.3706 1.37341 18.1112L5.54521 14.9722Z"
                                fill="#FBBC05"
                              />
                              <path
                                id="Vector_4"
                                d="M12.8596 4.83507C15.2739 4.83507 16.9029 5.84886 17.8312 6.6963L21.4603 3.2515C19.2316 1.23799 16.3312 0.00158691 12.8596 0.00158691C7.83059 0.00158691 3.48726 2.80709 1.37305 6.89038L5.52872 10.0278C6.57322 7.01414 9.45954 4.83507 12.8596 4.83507Z"
                                fill="#EB4335"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_207_146">
                                <rect
                                  width="25.2023"
                                  height="25"
                                  fill="white"
                                  transform="translate(0.00195312)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          Google
                        </button>
                      </li>
                    </ul>
                  </div> */}
                </div>
        
              </div>
            </div>

            <div className="col-lg-6 col-md-12 p-0">
              <div className="login-slider">
                <OwlCarousel
                  className="login owl-theme"
                  autoplay={true}
                  navSpeed={800}
                  smartSpeed={800}
                  loop={false}
                  nav
                  dots={true}
                  navText={navText}
                  responsive={{
                    0: {
                      items: 1,
                    },
                  }}
                >
                  <div className="item">
                    <div className="img-box">
                      <img
                        src="assets/images/login-slider-one.png"
                        alt="event-img"
                        height="300"
                        width="300"
                      />
                    </div>
                  </div>
                  <div className="item">
                    <div className="img-box">
                      <img
                        src="assets/images/login-slider-two.png"
                        alt="event-img"
                        height="300"
                        width="300"
                      />
                    </div>
                  </div>
                  <div className="item">
                    <div className="img-box">
                      <img
                        src="assets/images/login-slider-three.png"
                        alt="event-img"
                        height="300"
                        width="300"
                      />
                    </div>
                  </div>
                  
                </OwlCarousel>
                <div className="slider-contain">
                  <p>
                  Discover holistic wellness and vibrant connections at WellNas. Explore diverse activities, connect with like-minded individuals, and begin a journey of WellNas.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Register;
