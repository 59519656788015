import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router";
import axios from "axios";

import { getEventList } from "../../store/action/eventAction";
import { getCategoryList } from "../../store/action/categoryAction";
import { getActivityList } from "../../store/action/activityAction";
import { getSearchList } from "../../store/action/searchAction";
import { EVENT_LIST_SUCCESS } from "../../store/constants/eventConstant";
import { SEARCH_LIST_SUCCESS } from "../../store/constants/searchConstant";
import { getCustomerProfile } from "../../store/action/customerAction";
// import GroupListing from "../Group/GroupListing";

const SearchEvents = () => {
  React.useEffect(() => {
    document.title = "Events | WellNas";
  });

  // const errorImg = "/assets/images/no_image.png";
  // const errorImg = "/assets/images/no-image-icon.png";
  const errorImg = "/assets/images/no-image.png";

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { searchData } = useParams();

  const { eventList } = useSelector((state) => state.event);
  const { customerProfile } = useSelector((state) => state.customerProfile);
  const { categoryList } = useSelector((state) => state.category);
  const { activityList } = useSelector((state) => state.activityList);
  const { searchListData } = useSelector((state) => state.searchList);

  const [limit, setLimit] = React.useState(6);
  const [activeType, setActiveType] = React.useState("events");
  const [selectedFilter, setSelectedFilter] = React.useState("Any type");
  const [selectedOrder, setSelectedOrder] = React.useState("Sort by:Relevance");

  const [latLngData, setLatLngData] = React.useState([]);

  const [latLngInfo, setLatLngInfo] = React.useState([]);

  const [eventValidLocationData, setEventvalidLocationData] = React.useState(
    []
  );

  const [searchValidLocationData, setSearchValidLocationData] = React.useState(
    []
  );

  const [filteredEventData, setFilteredEventData] = React.useState([]);

  const [filteredAllEventData, setFilteredAllEventData] = React.useState([]);

  const [showFullAddress, setShowFullAddress] = React.useState(false);
  const [addressVisibility, setAddressVisibility] = React.useState({});

  const initialState = location.state
    ? location.state.vCategory
    : "Any category";
  const [selectedCategory, setSelectedCategory] = React.useState(initialState);

  const startState = location.state ? location.state.vActivity : "Any activity";
  const [selectedActivity, setSelectedActivity] = React.useState(startState);

  // dateTime format
  const formatDateTime = (dateTimeString) => {
    const options = {
      weekday: "short",
      month: "short",
      day: "numeric",
    };

    const formattedDate = new Date(dateTimeString).toLocaleString(
      "en-US",
      options
    );
    const commaIndex = formattedDate.indexOf(
      ",",
      formattedDate.indexOf(",") + 1
    );
    if (commaIndex !== -1) {
      return (
        formattedDate.substring(0, commaIndex) +
        " -" +
        formattedDate.substring(commaIndex + 1)
      );
    }
    return formattedDate;
  };

  const handleAddressClick = (eventId) => {
    setAddressVisibility((prevVisibility) => ({
      ...prevVisibility,
      [eventId]: !prevVisibility[eventId],
    }));
    setShowFullAddress((prevShowFullAddress) =>
      prevShowFullAddress === eventId ? null : eventId
    );
  };

  // for clear all filters
  const resetFilters = () => {
    setSelectedFilter("Any type");
    setSelectedCategory("Any category");
    setSelectedActivity("Any activity");
    setSelectedOrder("Sort by:Relevance");
  };

  // for handle event Detail
  const handleDetailClick = (event) => {
    navigate(`/event-details/${event.vEvent.replace(/\s+/g, "-")}`, {
      state: { iEventId: event.iEventId },
    });
  };

  // for customer profile View
  React.useEffect(() => {
    const vUniqueCode =
      // sessionStorage.getItem("vUniqueCode") ||
      localStorage.getItem("vUniqueCode");
    if (!customerProfile) {
      dispatch(
        getCustomerProfile({
          vUniqueCode: vUniqueCode,
        })
      );
    }
  }, [customerProfile, dispatch]);

  //   // for searchList
  React.useEffect(() => {
    const vUniqueCode =
      // sessionStorage.getItem("vUniqueCode") ||
      localStorage.getItem("vUniqueCode");
    if (vUniqueCode) {
      if (searchData !== "all") {
        // Check if vCategory and vActivity are set, and dispatch the action accordingly
        if (
          selectedCategory !== "Any category" &&
          selectedActivity !== "Any activity"
        ) {
          dispatch(
            getSearchList({
              vUniqueCode: vUniqueCode,
              searchData: searchData,
              // eFeature: "",
              // vLocation: "",
              // eType: "",
              vCategory: selectedCategory,
              // iCategoryId: "",
              vActivity: selectedActivity,
              // iActivityId: ""
            })
          );
        } else {
          dispatch(
            getSearchList({
              vUniqueCode: vUniqueCode,
              searchData: searchData,
              // eFeature: "",
              // vLocation: "",
              // eType: "",
              vCategory: "",
              // iCategoryId: "",
              vActivity: "",
              // iActivityId: ""
            })
          );
        }
      }
    } else {
      if (searchData !== "all") {
        // Check if vCategory and vActivity are set, and dispatch the action accordingly
        if (
          selectedCategory !== "Any category" &&
          selectedActivity !== "Any activity"
        ) {
          dispatch(
            getSearchList({
              vUniqueCode: "",
              searchData: searchData,
              // eFeature: "",
              // vLocation: "",
              // eType: "",
              vCategory: selectedCategory,
              // iCategoryId: "",
              vActivity: selectedActivity,
              // iActivityId: ""
            })
          );
        } else {
          dispatch(
            getSearchList({
              vUniqueCode: "",
              searchData: searchData,
              // eFeature: "",
              // vLocation: "",
              // eType: "",
              vCategory: "",
              // iCategoryId: "",
              vActivity: "",
              // iActivityId: ""
            })
          );
        }
      }
    }
  }, [searchData, selectedCategory, selectedActivity, dispatch]);

  // for CategoryList
  React.useEffect(() => {
    if (!categoryList) {
      dispatch(
        getCategoryList({
          eFeature: "",
          vCategory: "",
        })
      );
    }
  }, [categoryList, dispatch]);

  // for Activity List
  React.useEffect(() => {
    if (!activityList) {
      dispatch(
        getActivityList({
          iCategoryId: "",
          eFeature: "",
        })
      );
    }
  }, [activityList, dispatch]);

  React.useEffect(() => {
    if (
      location.state &&
      location.state.vCategory &&
      !location.state.vActivity
    ) {
      setSelectedCategory(location.state.vCategory);
      setSelectedActivity("Any activity");
    }
    if (
      location.state &&
      location.state.vActivity &&
      !location.state.vCategory
    ) {
      setSelectedActivity(location.state.vActivity);
      setSelectedCategory("Any category");
    }
  }, [location.state]);

  React.useEffect(() => {
    if (location.state) {
      if (location.state.vCategory && !location.state.vActivity) {
        setSelectedCategory(location.state.vCategory);
        setSelectedActivity("Any activity");
      } else if (location.state.vActivity && !location.state.vCategory) {
        setSelectedActivity(location.state.vActivity);
        setSelectedCategory("Any category");
      } else if (!location.state.vCategory && !location.state.vActivity) {
        // If both are not present, reset both to their default values
        setSelectedCategory("Any category");
        setSelectedActivity("Any activity");
      }
    }
  }, [location.state]);

  React.useEffect(() => {
    const filterEvents = () => {
      if (
        searchListData &&
        searchListData.code === "200" &&
        searchListData.data &&
        searchListData.data.length > 0
      ) {
        const filteredEvents = [];

        searchListData.data.forEach((event) => {
          event.events.forEach((element) => {
            const filterByType =
              selectedFilter === "Any type" || element.eType === selectedFilter;

            const filterByCategory =
              selectedCategory === "Any category" ||
              element.categories.some(
                (category) => category.vCategory === selectedCategory
              );

            const filterByActivity =
              selectedActivity === "Any activity" ||
              element.activities.some(
                (activity) => activity.vActivity === selectedActivity
              );

            // const FilterByOrder = selectedOrder === "Sort by:Relevance";

            if (filterByType && filterByCategory && filterByActivity) {
              filteredEvents.push(element);
            }
          });
        });

        return filteredEvents;
      }
      return []; // Return an empty array if no data or invalid data
    };

    const result = filterEvents();

    setFilteredEventData(result);
  }, [
    searchListData,
    selectedFilter,
    selectedCategory,
    selectedActivity,
    searchData,
  ]);

  // Example usage
  // const filteredEvents = filterEvents();
  // console.log(filteredEvents);

  // handle View all button
  const handleViewAllClick = () => {
    setLimit(null);
  };

  React.useEffect(() => {
    if (filteredEventData) {
      const latLongArray = filteredEventData.map((event) => ({
        eventId: event.iEventId,
        location: event.vLocation,
        latitude: event.vLatitude,
        longitude: event.vLongitude,
        events: event.vEvent,
      }));

      setLatLngData(latLongArray);
    } else {
      // Uncomment the following line for error logging
      // console.error("filteredEventData is not defined or is not an array.");
    }
  }, [
    filteredEventData,
    searchListData,
    selectedFilter,
    selectedCategory,
    selectedActivity,
    setLatLngData,
  ]);

  // for EventList
  React.useEffect(() => {
    const vUniqueCode =
      // sessionStorage.getItem("vUniqueCode") ||
      localStorage.getItem("vUniqueCode");
    if (vUniqueCode) {
      if (!eventList && searchData === "all") {
        // Check if vCategory and vActivity are set, and dispatch the action accordingly
        if (
          selectedCategory !== "Any category" &&
          selectedActivity !== "Any activity"
        ) {
          dispatch(
            getEventList({
              vUniqueCode: vUniqueCode,
              eFeature: "",
              vLocation: "",
              eType: "",
              vCategory: selectedCategory,
              iCategoryId: "",
              vActivity: selectedActivity,
              iActivityId: "",
            })
          );
        } else {
          dispatch(
            getEventList({
              vUniqueCode: vUniqueCode,
              eFeature: "",
              vLocation: "",
              eType: "",
              vCategory: "",
              iCategoryId: "",
              vActivity: "",
              iActivityId: "",
            })
          );
        }
      }
    } else {
      if (!eventList && searchData === "all") {
        // Check if vCategory and vActivity are set, and dispatch the action accordingly
        if (
          selectedCategory !== "Any category" &&
          selectedActivity !== "Any activity"
        ) {
          dispatch(
            getEventList({
              vUniqueCode: "",
              eFeature: "",
              vLocation: "",
              eType: "",
              vCategory: selectedCategory,
              iCategoryId: "",
              vActivity: selectedActivity,
              iActivityId: "",
            })
          );
        } else {
          dispatch(
            getEventList({
              vUniqueCode: "",
              eFeature: "",
              vLocation: "",
              eType: "",
              vCategory: "",
              iCategoryId: "",
              vActivity: "",
              iActivityId: "",
            })
          );
        }
      }
    }
  }, [eventList, selectedCategory, selectedActivity, dispatch]);

  const HandleOrderChange = (e) => {
    setSelectedOrder(e.target.value);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      if (searchData === "all") {
        const vUniqueCode =
          // sessionStorage.getItem("vUniqueCode") ||
          localStorage.getItem("vUniqueCode");
        // Check if vCategory and vActivity are set, and dispatch the action accordingly
        const actionParams = {
          vUniqueCode: vUniqueCode ? vUniqueCode : "",
          eFeature: "",
          vLocation: "",
          eType: "",
          vCategory:
            selectedCategory !== "Any category" ? selectedCategory : "",
          iCategoryId: "",
          vActivity:
            selectedActivity !== "Any activity" ? selectedActivity : "",
          iActivityId: "",
          vColumn: "vEvent",
          vOrder: selectedOrder !== "Sort by:Relevance" ? selectedOrder : "",
        };

        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/event/get_all_data`,
            actionParams
          );
          // setEvents(response.data.data);
          dispatch({ type: EVENT_LIST_SUCCESS, payload: response.data.data });
        } catch (error) {
          // console.error("Error fetching data:", error);
          // Handle error as needed
        }
      } else {
        if (searchData !== "all") {
          const vUniqueCode =
            // sessionStorage.getItem("vUniqueCode") ||
            localStorage.getItem("vUniqueCode");
          // Check if vCategory and vActivity are set, and dispatch the action accordingly
          const actionParams = {
            searchData: searchData,
            vUniqueCode: vUniqueCode ? vUniqueCode : "",
            // eFeature: "",
            // vLocation: "",
            // eType: "",
            vCategory:
              selectedCategory !== "Any category" ? selectedCategory : "",
            // iCategoryId: "",
            vActivity:
              selectedActivity !== "Any activity" ? selectedActivity : "",
            // iActivityId: "",
            vColumn: "vEvent",
            vOrder: selectedOrder !== "Sort by:Relevance" ? selectedOrder : "",
          };

          try {
            const response = await axios.post(
              `${process.env.REACT_APP_API_URL}/api/search`,
              actionParams
            );
            // setEvents(response.data.data);
            const responseData = response.data;
            dispatch({ type: SEARCH_LIST_SUCCESS, payload: responseData });
          } catch (error) {
            // console.error("Error fetching data:", error);
            // Handle error as needed
          }
        }
      }
    };

    fetchData();
  }, [selectedOrder, selectedCategory, selectedActivity, searchData]);

  // filter function for events
  // filter function for events
  React.useEffect(() => {
    const filterAllEvents = () => {
      const filtered_data =
        eventList &&
        Array.isArray(eventList) &&
        eventList.filter((event) => {
          const filterByType =
            selectedFilter === "Any type" || event.eType === selectedFilter;

          const filterByCategory =
            selectedCategory === "Any category" ||
            event.categories.some(
              (category) => category.vCategory === selectedCategory
            );

          const filterByActivity =
            selectedActivity === "Any activity" ||
            event.activities.some(
              (activity) => activity.vActivity === selectedActivity
            );

          return filterByType && filterByCategory && filterByActivity;
        });

      return filtered_data || []; // handle the case when filtered_data is undefined
    };

    const result = filterAllEvents();

    setFilteredAllEventData(result);
  }, [eventList, selectedFilter, selectedCategory, selectedActivity]);

  // console.log("FilteredAllEventData", filteredAllEventData);

  React.useEffect(() => {
    const latLongArrayInfo = [];
    if (eventList) {
      // Use filterEvents function to filter and extract data
      const filteredEvents = filteredAllEventData;

      // Iterate through filtered events and push latitude and longitude into the array
      if (Array.isArray(filteredEvents)) {
        filteredEvents.forEach((event) => {
          const latLongObject = {
            eventId: event.iEventId,
            location: event.vLocation,
            latitude: event.vLatitude,
            longitude: event.vLongitude,
            events: event.vEvent,
          };
          latLongArrayInfo.push(latLongObject);
        });
      }

      setLatLngInfo(latLongArrayInfo);
    }
  }, [
    eventList,
    selectedFilter,
    selectedCategory,
    selectedActivity,
    setLatLngInfo,
  ]);

  const filteredEvents = filteredAllEventData;

  React.useEffect(() => {
    const vUniqueCode =
      // sessionStorage.getItem("vUniqueCode") ||
      localStorage.getItem("vUniqueCode");
    try {
      if (latLngInfo && searchData === "all" && window.google) {
        // console.log("Rendering map for 'all' event  search data");
        const map = new window.google.maps.Map(document.getElementById("map"), {
          center: { lat: 0, lng: 0 },
          zoom: 2,
        });

        const infoBox = new window.google.maps.InfoWindow();

        const validLocations = latLngInfo.filter(
          (location) =>
            location.latitude !== null &&
            location.latitude !== "" &&
            location.longitude !== null &&
            location.longitude !== ""
        );
        setEventvalidLocationData(validLocations);

        if (customerProfile && vUniqueCode) {
          // console.log("customerProfile ", customerProfile);
          if (
            selectedActivity === "Any activity" &&
            selectedOrder === "Sort by:Relevance" &&
            selectedCategory === "Any category" &&
            selectedFilter === "Any type"
          ) {
            const lat = parseFloat(customerProfile.vLatitude);
            const lng = parseFloat(customerProfile.vLongitude);

            if (!isNaN(lat) && !isNaN(lng)) {
              map.setCenter({ lat, lng });
              map.setZoom(4);
            }
          } else if (
            filteredAllEventData &&
            filteredAllEventData.length === 1
          ) {
            const lat = parseFloat(filteredAllEventData[0].vLatitude);
            const lng = parseFloat(filteredAllEventData[0].vLongitude);

            if (!isNaN(lat) && !isNaN(lng)) {
              map.setCenter({ lat, lng });
              map.setZoom(4);
            }
          } else if (
            filteredEvents &&
            filteredEvents.length > 1 &&
            filteredEvents &&
            filteredEvents.length < 3
          ) {
            // console.log(" filteredEvents---------->", filteredEvents);

            const event = [...filteredEvents].sort((a, b) =>
              a.vLocation.localeCompare(b.vLocation)
            );

            // console.log(" event", event);

            const lat = parseFloat(event[0].vLatitude);
            const lng = parseFloat(event[0].vLongitude);
            if (!isNaN(lat) && !isNaN(lng)) {
              map.setCenter({ lat, lng });
              map.setZoom(4);
            }
          } else if (filteredEvents && filteredEvents.length >= 3) {
            const sameCountry =
              filteredEvents &&
              filteredEvents.reduce((acc, obj) => {
                const countryKey = obj.vCountry;

                const countryEntry = acc.find(
                  (entry) => entry[0].vCountry === countryKey
                );

                if (countryEntry) {
                  countryEntry.push(obj);
                } else {
                  acc.push([obj]);
                }

                return acc;
              }, []);

            const maxDataArray = sameCountry.reduce(
              (max, current) => (current.length > max.length ? current : max),
              []
            );

            const latLongArray = [];

            if (maxDataArray) {
              maxDataArray.forEach((event) => {
                const latLongObject = {
                  eventId: event.iEventId,
                  location: event.vLocation,
                  latitude: event.vLatitude,
                  longitude: event.vLongitude,
                  events: event.vEvent,
                };

                latLongArray.push(latLongObject);
              });
            }

            latLongArray.sort(
              (a, b) => parseFloat(a.latitude) - parseFloat(b.latitude)
            );

            if (latLongArray && latLongArray.length > 0) {
              latLongArray.forEach((location) => {
                if (
                  location &&
                  location.vLatitude !== "" &&
                  location.vLongitude !== ""
                ) {
                  const lat = parseFloat(location.latitude);
                  const lng = parseFloat(location.longitude);

                  if (!isNaN(lat) && !isNaN(lng)) {
                    map.setCenter({ lat, lng });
                    map.setZoom(4);
                  } else {
                    // console.error("Invalid coordinates for location:", location);
                  }
                }
              });
            }
          }
        } else {
          if (
            selectedActivity === "Any activity" &&
            selectedOrder === "Sort by:Relevance" &&
            selectedCategory === "Any category" &&
            selectedFilter === "Any type"
          ) {
            if (filteredEvents && filteredEvents.length > 0) {
              const sameCountry =
                filteredEvents &&
                filteredEvents.reduce((acc, obj) => {
                  const countryKey = obj.vCountry;

                  const countryEntry = acc.find(
                    (entry) => entry[0].vCountry === countryKey
                  );

                  if (countryEntry) {
                    countryEntry.push(obj);
                  } else {
                    acc.push([obj]);
                  }

                  return acc;
                }, []);

              const maxDataArray = sameCountry.reduce(
                (max, current) => (current.length > max.length ? current : max),
                []
              );

              const latLongArray = [];

              if (maxDataArray) {
                maxDataArray.forEach((event) => {
                  const latLongObject = {
                    eventId: event.iEventId,
                    location: event.vLocation,
                    latitude: event.vLatitude,
                    longitude: event.vLongitude,
                    events: event.vEvent,
                  };

                  latLongArray.push(latLongObject);
                });
              }

              latLongArray.sort(
                (a, b) => parseFloat(a.latitude) - parseFloat(b.latitude)
              );

              if (latLongArray && latLongArray.length > 0) {
                latLongArray.forEach((location) => {
                  // console.log("location", location);
                  if (
                    location &&
                    location.vLatitude !== "" &&
                    location.vLongitude !== ""
                  ) {
                    const lat = parseFloat(location.latitude);
                    const lng = parseFloat(location.longitude);

                    if (!isNaN(lat) && !isNaN(lng)) {
                      map.setCenter({ lat, lng });
                      map.setZoom(4);
                      // console.log("Centering map at:", lat, lng);
                    } else {
                      // console.error("Invalid coordinates for location:", location);
                    }
                  }
                });
              }
            }
            // const lat = 33.8939238;
            // const lng = 36.1151942;
            // if (!isNaN(lat) && !isNaN(lng)) {
            //   map.setCenter({ lat, lng });
            //   map.setZoom(4);
            // }
          } else if (
            filteredAllEventData &&
            filteredAllEventData.length === 1
          ) {
            const lat = parseFloat(filteredAllEventData[0].vLatitude);
            const lng = parseFloat(filteredAllEventData[0].vLongitude);

            if (!isNaN(lat) && !isNaN(lng)) {
              map.setCenter({ lat, lng });
              map.setZoom(4);
            }
          } else if (
            filteredEvents &&
            filteredEvents.length > 1 &&
            filteredEvents &&
            filteredEvents.length < 3
          ) {
            const event = [...filteredEvents].sort((a, b) =>
              a.vLocation.localeCompare(b.vLocation)
            );

            // console.log(" event", event);

            const lat = parseFloat(event[0].vLatitude);
            const lng = parseFloat(event[0].vLongitude);
            if (!isNaN(lat) && !isNaN(lng)) {
              map.setCenter({ lat, lng });
              map.setZoom(4);
            }
          } else if (filteredEvents && filteredEvents.length >= 3) {
            const sameCountry =
              filteredEvents &&
              filteredEvents.reduce((acc, obj) => {
                const countryKey = obj.vCountry;

                const countryEntry = acc.find(
                  (entry) => entry[0].vCountry === countryKey
                );

                if (countryEntry) {
                  countryEntry.push(obj);
                } else {
                  acc.push([obj]);
                }

                return acc;
              }, []);

            const maxDataArray = sameCountry.reduce(
              (max, current) => (current.length > max.length ? current : max),
              []
            );

            const latLongArray = [];

            if (maxDataArray) {
              maxDataArray.forEach((event) => {
                const latLongObject = {
                  eventId: event.iEventId,
                  location: event.vLocation,
                  latitude: event.vLatitude,
                  longitude: event.vLongitude,
                  events: event.vEvent,
                };

                latLongArray.push(latLongObject);
              });
            }

            latLongArray.sort(
              (a, b) => parseFloat(a.latitude) - parseFloat(b.latitude)
            );

            if (latLongArray && latLongArray.length > 0) {
              latLongArray.forEach((location) => {
                // console.log("location", location);
                if (
                  location &&
                  location.vLatitude !== "" &&
                  location.vLongitude !== ""
                ) {
                  const lat = parseFloat(location.latitude);
                  const lng = parseFloat(location.longitude);

                  if (!isNaN(lat) && !isNaN(lng)) {
                    map.setCenter({ lat, lng });
                    map.setZoom(4);
                    // console.log("Centering map at:", lat, lng);
                  } else {
                    // console.error("Invalid coordinates for location:", location);
                  }
                }
              });
            }
          }
        }

        if (validLocations.length > 0) {
          validLocations.forEach((location) => {
            const trimmedLatitude = parseFloat(location.latitude);
            const trimmedLongitude = parseFloat(location.longitude);
            const eventName = location.events;

            let marker = new window.google.maps.Marker({
              position: {
                lat: trimmedLatitude,
                lng: trimmedLongitude,
              },
              map: map,
              title: location.location,
            });

            marker.addListener("click", () => {
              infoBox.setContent(`
                <div>
                  <h6>${eventName}</h6>
                  <h7>${location.location}</h7>
                </div>
              `);
              infoBox.open(map, marker);
            });
          });
        }
      } else if (searchListData && filteredEventData && window.google) {
        const map = new window.google.maps.Map(document.getElementById("map"), {
          center: { lat: 0, lng: 0 },
          zoom: 2,
        });

        const infoBox = new window.google.maps.InfoWindow();

        const validLocationsData = latLngData.filter(
          (location) =>
            location.latitude !== null &&
            location.latitude !== "" &&
            location.longitude !== null &&
            location.longitude !== ""
        );
        setSearchValidLocationData(validLocationsData);

        if (customerProfile && vUniqueCode) {
          // console.log("customerProfile ", customerProfile);
          if (
            selectedActivity === "Any activity" &&
            selectedOrder === "Sort by:Relevance" &&
            selectedCategory === "Any category" &&
            selectedFilter === "Any type"
          ) {
            // const lat = parseFloat(customerProfile.vLatitude);
            // const lng = parseFloat(customerProfile.vLongitude);

            // if (!isNaN(lat) && !isNaN(lng)) {
            //   map.setCenter({ lat, lng });
            //   map.setZoom(4);
            // }

            if (filteredEventData && filteredEventData.length > 0) {
              const sameCountry =
                filteredEventData &&
                filteredEventData.reduce((acc, obj) => {
                  const countryKey = obj.vCountry;

                  const countryEntry = acc.find(
                    (entry) => entry[0].vCountry === countryKey
                  );

                  if (countryEntry) {
                    countryEntry.push(obj);
                  } else {
                    acc.push([obj]);
                  }

                  return acc;
                }, []);

              const maxDataArray = sameCountry.reduce(
                (max, current) => (current.length > max.length ? current : max),
                []
              );

              const latLongArray = [];

              if (maxDataArray) {
                maxDataArray.forEach((event) => {
                  const latLongObject = {
                    eventId: event.iEventId,
                    location: event.vLocation,
                    latitude: event.vLatitude,
                    longitude: event.vLongitude,
                    events: event.vEvent,
                  };

                  latLongArray.push(latLongObject);
                });
              }
              latLongArray.sort(
                (a, b) => parseFloat(a.latitude) - parseFloat(b.latitude)
              );

              if (latLongArray && latLongArray.length > 0) {
                latLongArray.forEach((location) => {
                  // console.log("location", location);
                  if (
                    location &&
                    location.vLatitude !== "" &&
                    location.vLongitude !== ""
                  ) {
                    const lat = parseFloat(location.latitude);
                    const lng = parseFloat(location.longitude);

                    if (!isNaN(lat) && !isNaN(lng)) {
                      map.setCenter({ lat, lng });
                      map.setZoom(4);
                      // console.log("Centering map at:", lat, lng);
                    } else {
                      // console.error("Invalid coordinates for location:", location);
                    }
                  }
                });
              }
            }
          } else if (filteredEventData && filteredEventData.length === 1) {
            const lat = parseFloat(filteredEventData[0].vLatitude);
            const lng = parseFloat(filteredEventData[0].vLongitude);

            if (!isNaN(lat) && !isNaN(lng)) {
              map.setCenter({ lat, lng });
              map.setZoom(4);
            }
          } else if (
            filteredEventData &&
            filteredEventData.length > 1 &&
            filteredEventData &&
            filteredEventData.length < 3
          ) {
            const event = [...filteredEventData].sort((a, b) =>
              a.vLocation.localeCompare(b.vLocation)
            );

            // console.log(" event------------>", event);

            const lat = parseFloat(event[0].vLatitude);
            const lng = parseFloat(event[0].vLongitude);
            if (!isNaN(lat) && !isNaN(lng)) {
              map.setCenter({ lat, lng });
              map.setZoom(4);
            }
          } else if (filteredEventData && filteredEventData.length >= 3) {
            const sameCountry =
              filteredEventData &&
              filteredEventData.reduce((acc, obj) => {
                const countryKey = obj.vCountry;

                const countryEntry = acc.find(
                  (entry) => entry[0].vCountry === countryKey
                );

                if (countryEntry) {
                  countryEntry.push(obj);
                } else {
                  acc.push([obj]);
                }

                return acc;
              }, []);

            const maxDataArray = sameCountry.reduce(
              (max, current) => (current.length > max.length ? current : max),
              []
            );

            const latLongArray = [];

            if (maxDataArray) {
              maxDataArray.forEach((event) => {
                const latLongObject = {
                  eventId: event.iEventId,
                  location: event.vLocation,
                  latitude: event.vLatitude,
                  longitude: event.vLongitude,
                  events: event.vEvent,
                };

                latLongArray.push(latLongObject);
              });
            }

            latLongArray.sort(
              (a, b) => parseFloat(a.latitude) - parseFloat(b.latitude)
            );

            if (latLongArray && latLongArray.length > 0) {
              latLongArray.forEach((location) => {
                if (
                  location &&
                  location.vLatitude !== "" &&
                  location.vLongitude !== ""
                ) {
                  const lat = parseFloat(location.latitude);
                  const lng = parseFloat(location.longitude);

                  if (!isNaN(lat) && !isNaN(lng)) {
                    map.setCenter({ lat, lng });
                    map.setZoom(4);
                  } else {
                    // console.error("Invalid coordinates for location:", location);
                  }
                }
              });
            }
          }
        } else {
          if (
            filteredEventData &&
            selectedActivity === "Any activity" &&
            selectedOrder === "Sort by:Relevance" &&
            selectedCategory === "Any category" &&
            selectedFilter === "Any type"
          ) {
            // const lat = 33.8939238;
            // const lng = 36.1151942;
            // if (!isNaN(lat) && !isNaN(lng)) {
            //   map.setCenter({ lat, lng });
            //   map.setZoom(4);
            // }

            if (filteredEventData && filteredEventData.length > 0) {
              const sameCountry =
                filteredEventData &&
                filteredEventData.reduce((acc, obj) => {
                  const countryKey = obj.vCountry;

                  const countryEntry = acc.find(
                    (entry) => entry[0].vCountry === countryKey
                  );

                  if (countryEntry) {
                    countryEntry.push(obj);
                  } else {
                    acc.push([obj]);
                  }

                  return acc;
                }, []);

              const maxDataArray = sameCountry.reduce(
                (max, current) => (current.length > max.length ? current : max),
                []
              );

              const latLongArray = [];

              if (maxDataArray) {
                maxDataArray.forEach((event) => {
                  const latLongObject = {
                    eventId: event.iEventId,
                    location: event.vLocation,
                    latitude: event.vLatitude,
                    longitude: event.vLongitude,
                    events: event.vEvent,
                  };

                  latLongArray.push(latLongObject);
                });
              }
              latLongArray.sort(
                (a, b) => parseFloat(a.latitude) - parseFloat(b.latitude)
              );

              if (latLongArray && latLongArray.length > 0) {
                latLongArray.forEach((location) => {
                  // console.log("location", location);
                  if (
                    location &&
                    location.vLatitude !== "" &&
                    location.vLongitude !== ""
                  ) {
                    const lat = parseFloat(location.latitude);
                    const lng = parseFloat(location.longitude);

                    if (!isNaN(lat) && !isNaN(lng)) {
                      map.setCenter({ lat, lng });
                      map.setZoom(4);
                      // console.log("Centering map at:", lat, lng);
                    } else {
                      // console.error("Invalid coordinates for location:", location);
                    }
                  }
                });
              }
            }
          } else if (filteredEventData && filteredEventData.length === 1) {
            const lat = parseFloat(filteredEventData[0].vLatitude);
            const lng = parseFloat(filteredEventData[0].vLongitude);

            if (!isNaN(lat) && !isNaN(lng)) {
              map.setCenter({ lat, lng });
              map.setZoom(4);
            }
          } else if (
            filteredEventData &&
            filteredEventData.length > 1 &&
            filteredEventData &&
            filteredEventData.length < 3
          ) {
            // console.log("filterEventData", filteredEventData);
            const event = [...filteredEventData].sort((a, b) =>
              a.vLocation.localeCompare(b.vLocation)
            );

            // console.log(" event", event);

            const lat = parseFloat(event[0].vLatitude);
            const lng = parseFloat(event[0].vLongitude);
            if (!isNaN(lat) && !isNaN(lng)) {
              map.setCenter({ lat, lng });
              map.setZoom(4);
            }
          } else if (filteredEventData && filteredEventData.length >= 3) {
            const sameCountry =
              filteredEventData &&
              filteredEventData.reduce((acc, obj) => {
                const countryKey = obj.vCountry;

                const countryEntry = acc.find(
                  (entry) => entry[0].vCountry === countryKey
                );

                if (countryEntry) {
                  countryEntry.push(obj);
                } else {
                  acc.push([obj]);
                }

                return acc;
              }, []);

            const maxDataArray = sameCountry.reduce(
              (max, current) => (current.length > max.length ? current : max),
              []
            );

            const latLongArray = [];

            if (maxDataArray) {
              maxDataArray.forEach((event) => {
                const latLongObject = {
                  eventId: event.iEventId,
                  location: event.vLocation,
                  latitude: event.vLatitude,
                  longitude: event.vLongitude,
                  events: event.vEvent,
                };

                latLongArray.push(latLongObject);
              });
            }
            latLongArray.sort(
              (a, b) => parseFloat(a.latitude) - parseFloat(b.latitude)
            );

            if (latLongArray && latLongArray.length > 0) {
              latLongArray.forEach((location) => {
                // console.log("location", location);
                if (
                  location &&
                  location.vLatitude !== "" &&
                  location.vLongitude !== ""
                ) {
                  const lat = parseFloat(location.latitude);
                  const lng = parseFloat(location.longitude);

                  if (!isNaN(lat) && !isNaN(lng)) {
                    map.setCenter({ lat, lng });
                    map.setZoom(4);
                    // console.log("Centering map at:", lat, lng);
                  } else {
                    // console.error("Invalid coordinates for location:", location);
                  }
                }
              });
            }
          }
        }

        if (validLocationsData.length > 0) {
          validLocationsData.forEach((location) => {
            const trimmedLatitude = parseFloat(location.latitude);
            const trimmedLongitude = parseFloat(location.longitude);
            const eventName = location.events;

            let marker = new window.google.maps.Marker({
              position: {
                lat: trimmedLatitude,
                lng: trimmedLongitude,
              },
              map: map,
              title: location.location,
            });

            marker.addListener("click", () => {
              infoBox.setContent(`
                <div>
                  <h6>${eventName}</h6>
                  <h7>${location.location}</h7>
                </div>
              `);
              infoBox.open(map, marker);
            });
          });
        }
      }
    } catch (error) {
      // console.error("Error in useEffect:", error);
    }
  }, [latLngInfo, searchData, latLngData, searchListData, filteredEventData]);

  const handleSearchClick = () => {
    navigate("/");
  };

  const handleFilterClick = () => {
    navigate(`/search/all`);
    window.location.reload();
  };

  // console.log("filterAllEvents()", filteredAllEventData);
  // console.log(" filteredEventData", filteredEventData);

  return (
    <React.Fragment>
      <section
        className="inner-page-breadcamp event-banner"
        style={{ backgroundImage: "url(../assets/images/event-banner.png)" }}
      >
        <div className="container">
          <div className="brad-camp-detail">
            <h1 className="sub-title">Search | {searchData}</h1>
          </div>
        </div>
      </section>

      <section className="event-tab">
        <div className="up-area">
          <div className="container">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    activeType === "events" ? "active" : ""
                  }`}
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#home"
                  type="button"
                  role="tab"
                  aria-controls="home"
                  aria-selected={activeType === "events"}
                  onClick={() => setActiveType("events")}
                >
                  Events
                </button>
              </li>
              {/* <li className="nav-item" role="presentation">
                        <button
                           className={`nav-link ${activeType === "group" ? "active" : ""}`}
                           id="profile-tab"
                           data-bs-toggle="tab"
                           data-bs-target="#profile"
                           type="button"
                           role="tab"
                           aria-controls="profile"
                           aria-selected={activeType === "group"}
                           onClick={() => setActiveType("group")}
                        >
                           Group
                        </button>
                     </li> */}
            </ul>
          </div>
        </div>
        <div className="filtter-area">
          <div className="container">
            {(filteredEventData && filteredEventData.length > 0) ||
            (filteredAllEventData && filteredAllEventData.length > 0) ? (
              <div className="filtter-categ">
                <div className="left-side">
                  {/* filter activities */}
                  <div className="fillter-box activity-filter">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      value={selectedActivity}
                      onChange={(e) => setSelectedActivity(e.target.value)}
                    >
                      <option value="Any activity">Any activity</option>
                      {activityList &&
                        activityList.map((activityType) => (
                          <option
                            key={activityType.iActivityId}
                            value={activityType.vActivity}
                          >
                            {activityType.vActivity}
                          </option>
                        ))}
                    </select>
                  </div>

                  {/* filter eTypes */}
                  {activeType === "events" && (
                    <div className="fillter-box">
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        value={selectedFilter}
                        onChange={(e) => setSelectedFilter(e.target.value)}
                      >
                        <option value="Any type">Any type</option>
                        <option value="Public">Public</option>
                        {/* <option value="Private">Private</option> */}
                        <option value="Premium">Premium</option>
                      </select>
                    </div>
                  )}

                  {/* <div className="fillter-box">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                  >
                    <option defaultValue>Any distance</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div> */}

                  {/* filter categories */}
                  <div className="fillter-box category-filter">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      value={selectedCategory}
                      onChange={(e) => setSelectedCategory(e.target.value)}
                    >
                      <option value="Any category">Any category</option>
                      {categoryList &&
                        categoryList.map((categoryType) => (
                          <option
                            key={categoryType.iCategoryId}
                            value={categoryType.vCategory}
                          >
                            {categoryType.vCategory}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="fillter-box">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      value={selectedOrder}
                      onChange={HandleOrderChange}
                    >
                      <option defaultValue>Sort by: Relevance</option>
                      <option value="ASC">ASC</option>
                      <option value="DESC">DESC</option>
                      {/* <option value="3">Popular</option> */}
                    </select>
                  </div>
                </div>

                <div className="right-side">
                  <div className="fillter-box">
                    <button
                      type="submit"
                      className="fillter-btn"
                      onClick={resetFilters}
                    >
                      Clear all
                    </button>
                  </div>
                  {/* <div className="fillter-box">
                           <a href="/" className="fillter-btn">
                              Clear all
                           </a>
                        </div> */}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </section>

      <section className="event-tab-contain-area pb-60">
        <div className="container">
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <div className="row gy-5">
                <div
                  className={
                    (filteredAllEventData && filteredAllEventData.length > 0) ||
                    (filteredEventData && filteredEventData.length > 0)
                      ? "col-lg-9"
                      : "col-lg-12"
                  }
                >
                  <div className="row gy-4">
                    {searchData === "all" ? (
                      <>
                        {filteredAllEventData &&
                        filteredAllEventData.length > 0 ? (
                          <div className="col-12">
                            <div className="row gy-4">
                              {filteredAllEventData
                                .slice(
                                  0,
                                  limit !== null
                                    ? limit
                                    : eventList && eventList.length
                                )
                                .map((event, index) => (
                                  <div
                                    className="col-xl-4 col-lg-6 col-sm-6"
                                    key={index}
                                    // onClick={() => handleDetailClick(event)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <div className="event-box">
                                      <div className="img-box">
                                        <img
                                          src={
                                            event ? event.vWebpImage : errorImg
                                          }
                                          alt={event.vImageAlt}
                                          height="210"
                                          width="320"
                                          onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src = errorImg;
                                          }}
                                          onClick={() =>
                                            handleDetailClick(event)
                                          }
                                          style={{
                                            objectFit:
                                              event.vWebpImage
                                                .split("/")
                                                .pop() === "null"
                                                ? "contain"
                                                : "cover",
                                          }}
                                        />
                                        <Link
                                          to={`/event-details/${event.vEvent.replace(
                                            /\s+/g,
                                            "-"
                                          )}`}
                                          state={{ iEventId: event.iEventId }}
                                          className="redirect-btn"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="37"
                                            height="37"
                                            viewBox="0 0 37 37"
                                            fill="none"
                                          >
                                            <circle
                                              cx="18.5"
                                              cy="18.5"
                                              r="18.5"
                                              fill="white"
                                            />
                                            <path
                                              d="M20.3889 15.7333V12L27 18.5333L20.3889 25.0667V21.3333C15.6667 21.3333 12.3611 22.7333 10 26L10.7556 23.2L10.9444 22.8267C11.7019 20.8975 12.9855 19.2135 14.6526 17.9614C16.3197 16.7093 18.3052 15.9381 20.3889 15.7333Z"
                                              fill="#616161"
                                            />
                                          </svg>
                                        </Link>
                                      </div>
                                      <div className="contain">
                                        <p
                                          className="date-time"
                                          onClick={() =>
                                            handleDetailClick(event)
                                          }
                                        >
                                          {formatDateTime(event.dtEvent)}
                                        </p>
                                        <h2
                                          onClick={() =>
                                            handleDetailClick(event)
                                          }
                                        >
                                          {event.vEvent}
                                        </h2>
                                        <p
                                          className="description"
                                          onClick={() =>
                                            handleDetailClick(event)
                                          }
                                        >
                                          {event.tDescription}
                                        </p>
                                        {event.categories &&
                                          event.categories.map(
                                            (eventCate, category) => {
                                              return (
                                                <>
                                                  <p
                                                    className="location"
                                                    key={category}
                                                    onClick={() =>
                                                      handleDetailClick(event)
                                                    }
                                                    style={{
                                                      marginBottom: "10px",
                                                      fontWeight: "bold",
                                                      color: "#fd4700",
                                                    }}
                                                  >
                                                    {eventCate.vCategory}
                                                  </p>
                                                  <p
                                                    className="point-location"
                                                    onClick={() => {
                                                      handleAddressClick(
                                                        event.iEventId
                                                      );
                                                    }}
                                                  >
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="16"
                                                      height="16"
                                                      fill="currentColor"
                                                      className="bi bi-geo-alt-fill"
                                                      viewBox="0 0 16 16"
                                                    >
                                                      <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                                                    </svg>
                                                    &nbsp;
                                                    {/* {event.vLocation} */}
                                                    {addressVisibility[
                                                      event.iEventId
                                                    ]
                                                      ? event.vLocation
                                                      : event.vLocation.slice(
                                                          0,
                                                          20
                                                        ) +
                                                        (event.vLocation
                                                          .length > 20
                                                          ? "..."
                                                          : "")}
                                                  </p>
                                                </>
                                              );
                                            }
                                          )}
                                        <p
                                          className="attendees"
                                          onClick={() =>
                                            handleDetailClick(event)
                                          }
                                        >
                                          {event.attendees_count} attendees
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        ) : filteredAllEventData &&
                          filteredAllEventData.length === 0 ? (
                          <div className="no-data-found">
                            <p>No Data Found</p>
                            <div class="search-button">
                              <button
                                class="main-search-btn"
                                onClick={handleFilterClick}
                              >
                                Clear Filter
                              </button>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <>
                        {filteredEventData && filteredEventData.length > 0 ? (
                          <div className="col-12">
                            <div className="row gy-4">
                              {filteredEventData
                                .slice(
                                  0,
                                  limit !== null
                                    ? limit
                                    : filteredEventData.length
                                )
                                .map((event, index) => (
                                  <div
                                    className="col-xl-4 col-lg-6 col-sm-6"
                                    key={index}
                                    // onClick={() => handleDetailClick(event)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <div className="event-box">
                                      <div className="img-box">
                                        <img
                                          src={
                                            event ? event.vWebpImage : errorImg
                                          }
                                          alt={event.vImageAlt}
                                          height="210"
                                          width="320"
                                          onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src = errorImg;
                                          }}
                                          onClick={() =>
                                            handleDetailClick(event)
                                          }
                                          style={{
                                            objectFit:
                                              event.vWebpImage
                                                .split("/")
                                                .pop() === "null"
                                                ? "contain"
                                                : "cover",
                                          }}
                                        />
                                        <Link
                                          to={`/event-details/${event.vEvent.replace(
                                            /\s+/g,
                                            "-"
                                          )}`}
                                          state={{ iEventId: event.iEventId }}
                                          className="redirect-btn"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="37"
                                            height="37"
                                            viewBox="0 0 37 37"
                                            fill="none"
                                          >
                                            <circle
                                              cx="18.5"
                                              cy="18.5"
                                              r="18.5"
                                              fill="white"
                                            />
                                            <path
                                              d="M20.3889 15.7333V12L27 18.5333L20.3889 25.0667V21.3333C15.6667 21.3333 12.3611 22.7333 10 26L10.7556 23.2L10.9444 22.8267C11.7019 20.8975 12.9855 19.2135 14.6526 17.9614C16.3197 16.7093 18.3052 15.9381 20.3889 15.7333Z"
                                              fill="#616161"
                                            />
                                          </svg>
                                        </Link>
                                      </div>
                                      <div className="contain">
                                        <p
                                          className="date-time"
                                          onClick={() =>
                                            handleDetailClick(event)
                                          }
                                        >
                                          {formatDateTime(event.dtEvent)}
                                        </p>
                                        <h2>{event.vEvent}</h2>

                                        <p
                                          className="description"
                                          onClick={() =>
                                            handleDetailClick(event)
                                          }
                                        >
                                          {event.tDescription}
                                        </p>
                                        {event.categories &&
                                          event.categories.map(
                                            (eventCate, category) => (
                                              <React.Fragment key={category}>
                                                <p
                                                  className="location"
                                                  onClick={() =>
                                                    handleDetailClick(event)
                                                  }
                                                  style={{
                                                    marginBottom: "10px",
                                                    fontWeight: "bold",
                                                    color: "#fd4700",
                                                  }}
                                                >
                                                  {eventCate.vCategory}
                                                </p>
                                                <p
                                                  className="point-location"
                                                  onClick={() => {
                                                    handleAddressClick(
                                                      event.iEventId
                                                    );
                                                  }}
                                                >
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    fill="currentColor"
                                                    className="bi bi-geo-alt-fill"
                                                    viewBox="0 0 16 16"
                                                  >
                                                    <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                                                  </svg>
                                                  &nbsp;
                                                  {/* {event.vLocation} */}
                                                  {addressVisibility[
                                                    event.iEventId
                                                  ]
                                                    ? event.vLocation
                                                    : event.vLocation.slice(
                                                        0,
                                                        20
                                                      ) +
                                                      (event.vLocation.length >
                                                      20
                                                        ? "..."
                                                        : "")}
                                                </p>
                                              </React.Fragment>
                                            )
                                          )}
                                        <p
                                          className="attendees"
                                          onClick={() =>
                                            handleDetailClick(event)
                                          }
                                        >
                                          {event.attendees_count} attendees
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        ) : (
                          <div className="no-data-found">
                            <p>No Data Found</p>

                            <div class="search-button">
                              <button
                                class="main-search-btn"
                                onClick={handleSearchClick}
                              >
                                Search Again
                              </button>
                            </div>
                          </div>
                        )}
                      </>
                    )}

                    {/* {filteredEventData &&
                      filteredEventData.length > 6 ||
                      filterAllEvents() &&
                      filteredEventData.length > 6 &&
                      limit !== null && (
                        <div className="col-12">
                          <Link
                            to="#"
                            className="secondary-btn category-view-btn"
                            onClick={handleViewAllClick}
                          >
                            View all
                          </Link>
                        </div>
                      )} */}

                    {/* {((filteredEventData &&
                      filteredEventData.length > 6 &&
                      limit !== null) ||
                      (filterAllEvents() &&
                        filterAllEvents().length > 6 &&
                        limit !== null)) && (
                      <div className="col-12">
                        <Link
                          to="#"
                          className="secondary-btn category-view-btn"
                          onClick={handleViewAllClick}
                        >
                          View all
                        </Link>
                      </div>
                    )} */}

                    {/* {((filteredEventData &&
                      filteredEventData.length > 6 &&
                      limit !== null) ||
                      (filterAllEvents() &&
                        filterAllEvents().length > 6 &&
                        limit !== null)) && (
                      <div className="col-12">
                        <Link
                          to="#"
                          className="secondary-btn category-view-btn"
                          onClick={handleViewAllClick}
                        >
                          View all
                        </Link>
                      </div>
                    )} */}

                    {searchData === "all" &&
                    filteredAllEventData &&
                    filteredAllEventData.length > 6 &&
                    limit !== null ? (
                      <div className="col-12">
                        <Link
                          to="#"
                          className="secondary-btn category-view-btn"
                          onClick={handleViewAllClick}
                        >
                          View all
                        </Link>
                      </div>
                    ) : searchData !== "all" &&
                      filteredEventData &&
                      filteredEventData.length > 6 &&
                      limit !== null ? (
                      <div className="col-12">
                        <Link
                          to="#"
                          className="secondary-btn category-view-btn"
                          onClick={handleViewAllClick}
                        >
                          View all
                        </Link>
                      </div>
                    ) : null}
                  </div>
                </div>

                {searchData === "all" &&
                filteredAllEventData !== undefined &&
                filteredAllEventData.length > 0 ? (
                  <div className="col-lg-3">
                    <div className="event-map">
                      <div className="mapouter">
                        <div className="gmap_canvas">
                          <div
                            id="map"
                            style={{
                              width: "100%",
                              height: "860px",
                              title: "map",
                              className: "gmap_iframe",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : filteredEventData && filteredEventData.length > 0 ? (
                  <div className="col-lg-3">
                    <div className="event-map">
                      <div className="mapouter">
                        <div className="gmap_canvas">
                          <div
                            id="map"
                            style={{
                              width: "100%",
                              height: "860px",
                              title: "map",
                              className: "gmap_iframe",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            {/* Add other tab panes as needed */}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default SearchEvents;
