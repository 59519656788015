import React from "react";
import Container from "../components/layouts/Container";
import ReviewListing from "../components/Review/ReviewListing";


const ReviewPage = () => {
   return (
      <React.Fragment>
         <div className="dark-header">
            <Container>
               <ReviewListing />
            </Container>
         </div>
      </React.Fragment>
   );
}

export default ReviewPage;