import {
    CONTACT_US_REQUEST,
    CONTACT_US_SUCCESS,
    CONTACT_US_FAILURE,

} from "../constants/contactConstant";

export function contactUsReducer(state = {}, action) {
    switch (action.type) {
        case CONTACT_US_REQUEST:
            return {
                loading: true,
            }
        case CONTACT_US_SUCCESS:
            return {
                loading: false,
                contactUs: action.payload,
            }
        case CONTACT_US_FAILURE:
            return {
                loading: false,
            }
        default:
            return state
    }
};