import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getWhyWellNas } from "../../store/action/aboutAction";


const WhyUs = () => {

   React.useEffect(() => {
      document.title = "About-us | WellNas";
   },);

   const dispatch = useDispatch();
   const { whyWellNas } = useSelector((state) => state.whyWellNas);

   // for why wellNas list
   React.useEffect(() => {
      if (!whyWellNas) {
         dispatch(getWhyWellNas({}));
      }
   }, [whyWellNas, dispatch]);

   // handle show full/less description
   const [showMoreStates, setShowMoreStates] = React.useState({});
   const toggleShowMore = (index) => {
      setShowMoreStates((prevState) => ({
         ...prevState,
         [index]: !prevState[index],
      }));
   };

   return (
      <React.Fragment>
         <section className="why-welnes p-100">
            <div className="container">
               <div className="row gy-5">
                  <div className="col-lg-3 col-md-12">
                     <h2 className="sub-title">Why WellNas</h2>
                  </div>

                  {
                     whyWellNas && whyWellNas.map((wellNasList, wellNas) => {
                        return (
                           <div className="col-lg-3 col-md-4" key={wellNas}>
                              <div className="why-us-box">
                                 <div className="why-us-contain">
                                    <h3>{wellNasList.vTitle}</h3>
                                    {/* <p>{wellNasList.tDescription}</p> */}
                                    <p>
                                       {showMoreStates[wellNas]
                                          ? wellNasList.tDescription
                                          : wellNasList.tDescription.length > 110
                                             ? `${wellNasList.tDescription.slice(0, 110)} ...`
                                             : wellNasList.tDescription}
                                       <span>
                                          {
                                             wellNasList.tDescription.length > 110 && (
                                                <button
                                                   className="morelink"
                                                   onClick={() => toggleShowMore(wellNas)}
                                                   style={{ border: "none", color: "#F87217", background: "none" }}
                                                >
                                                   {showMoreStates[wellNas] ? "Read Less" : "Read More"}
                                                </button>
                                             )
                                          }
                                       </span>
                                    </p>
                                 </div>
                              </div>
                           </div>
                        )
                     })
                  }

               </div>
            </div>
         </section>
      </React.Fragment>
   );
};

export default WhyUs;














// Extra code
/* <>
   <div className="col-lg-3 col-md-4">
      <div className="why-us-box">
         <img src="assets/images/login-slider-three.png" alt="why-us-image" width="310" height="370" />
         <div className="why-us-contain">
            <h3>User-Friendly Interface</h3>
            <p>Lorem ipsum dolor sit amet consectetur. Sit at et urna nibh sem.</p>
         </div>
      </div>
   </div>
   <div className="col-lg-3 col-md-4">
      <div className="why-us-box">
         <img src="assets/images/why-us-six.png" alt="why-us-image" width="310" height="370" />
         <div className="why-us-contain">
            <h3>Inclusivity</h3>
            <p>Lorem ipsum dolor sit amet consectetur. Sit at et urna nibh sem.</p>
         </div>
      </div>
   </div>
   <div className="col-lg-3 col-md-4">
      <div className="why-us-box">
         <img src="assets/images/why-us-four.png" alt="why-us-image" width="310" height="370" />
         <div className="why-us-contain">
            <h3>Safety & Privacy</h3>
            <p>Lorem ipsum dolor sit amet consectetur. Sit at et urna nibh sem.</p>
         </div>
      </div>
   </div>
   <div className="col-lg-3 col-md-4">
      <div className="why-us-box">
         <img src="assets/images/why-us-five.png" alt="why-us-image" width="310" height="370" />
         <div className="why-us-contain">
            <h3>Resources & Rewards</h3>
            <p>Lorem ipsum dolor sit amet consectetur. Sit at et urna nibh sem.</p>
         </div>
      </div>
   </div>
   <div className="col-lg-3 col-md-4">
      <div className="why-us-box">
         <img src="assets/images/why-us-three.png" alt="why-us-image" width="310" height="370" />
         <div className="why-us-contain">
            <h3>Active Support</h3>
            <p>Lorem ipsum dolor sit amet consectetur. Sit at et urna nibh sem.</p>
         </div>
      </div>
   </div>
   <div className="col-lg-3 col-md-4">
      <div className="why-us-box">
         <img src="assets/images/why-us-two.png" alt="why-us-image" width="310" height="370" />
         <div className="why-us-contain">
            <h3>Constant Improvement</h3>
            <p>Lorem ipsum dolor sit amet consectetur. Sit at et urna nibh sem.</p>
         </div>
      </div>
   </div>
</> */