import React from "react";
import Container from "../components/layouts/Container";
import EditEvent from "../components/MyEvent/EditEvent";
import { useNavigate } from "react-router";

const EditEventPage = () => {
  const navigate = useNavigate();
  const isAuthenticated =
    // sessionStorage.getItem("vUniqueCode") ||
    localStorage.getItem("vUniqueCode");

  React.useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);
  return (
    <Container>
      <EditEvent />
    </Container>
  );
};

export default EditEventPage;
