import {
   CATEGORY_LIST_REQUEST,
   CATEGORY_LIST_SUCCESS,
   CATEGORY_LIST_FAILURE,

} from "../constants/categoryConstant";

export function categoryReducer(state = {}, action) {
   switch (action.type) {
      case CATEGORY_LIST_REQUEST:
         return {
            loading: true,
         }
      case CATEGORY_LIST_SUCCESS:
         return {
            loading: true,
            categoryList: action.payload,
         }
      case CATEGORY_LIST_FAILURE:
         return {
            loading: false,
         }
      default:
         return state
   }
};