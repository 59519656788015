import {
   BANNER_LIST_REQUEST,
   BANNER_LIST_SUCCESS,
   BANNER_LIST_FAILURE,

} from "../constants/bannerConstant";

export function bannerReducer(state = {}, action) {
   switch (action.type) {
      case BANNER_LIST_REQUEST:
         return {
            loading: true,
         }
      case BANNER_LIST_SUCCESS:
         return {
            loading: false,
            bannerList: action.payload,
         }
      case BANNER_LIST_FAILURE:
         return {
            loading: false,
         }
      default:
         return state
   }
};