import React from "react";
import OwlCarousel from "react-owl-carousel";
import { useDispatch, useSelector } from "react-redux";
import { getCoreValues } from "../../store/action/aboutAction";

const CoreValue = () => {
  React.useEffect(() => {
    document.title = "About-us | WellNas";
  });

  // const errorImg = "/assets/images/no_image.png";
  // const errorImg = "/assets/images/no-image-icon.png";
  const errorImg = "/assets/images/no-image.png";

  const dispatch = useDispatch();
  const { coreValues } = useSelector((state) => state.coreValues);
  const [loading, setLoading] = React.useState(true);
  const [coreData, setCoreData] = React.useState([]);

  // for core values list
  React.useEffect(() => {
    dispatch(getCoreValues({})).then(() => {
      setLoading(false);
    });
  }, [dispatch]);

  React.useEffect(() => {
    if (coreValues && coreValues.length > 0) {
      setCoreData(coreValues);
    }
  }, [coreValues]);

  const [showMoreStates, setShowMoreStates] = React.useState({});

  const toggleShowMore = (index) => {
    // console.log("index", index);
    setShowMoreStates((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const navText = [
    "<i class='fal fa-chevron-left'></i>",
    "<i class='fal fa-chevron-right'></i>",
  ];

  return (
    <React.Fragment>
      <section className="core-value p-100">
        <div className="flex-warper-class">
          <div className="row g-4">
            <div className="col-xxl-3 col-xl-2 col-md-3">
              <div className="left-area">
                <h2 className="sub-title">Core Values</h2>
              </div>
            </div>
            <div className="col-xxl-9 col-xl-10 col-md-9">
              <div className="right-area">
                {loading ? (
                  <div>Loading...</div>
                ) : (
                  <OwlCarousel
                    key={JSON.stringify(showMoreStates)}
                    className="sport_section owl-theme"
                    autoplay={false}
                    smartSpeed={800}
                    loop={true}
                    nav
                    dots={false}
                    navText={navText}
                    responsive={{
                      0: {
                        items: 1,
                        margin: 15,
                      },
                      420: {
                        items: 1,
                        margin: 15,
                      },
                      575: {
                        items: 1.5,
                        margin: 15,
                      },
                      767: {
                        items: 1.5,
                        margin: 15,
                      },
                      1199: {
                        items: 2.5,
                        margin: 25,
                      },
                    }}
                  >
                    {coreValues &&
                      coreValues.map((coreValueList, core) => (
                        <div className="item" key={core}>
                          <div className="img-box">
                            <img
                              src={
                                coreValueList ? coreValueList.vImage : errorImg
                              }
                              alt={coreValueList.vImageAlt}
                              height="57"
                              width="57"
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = errorImg;
                              }}
                              style={{
                                objectFit:
                                  coreValueList.vImage.split("/").pop() ===
                                  "null"
                                    ? "contain"
                                    : "cover",
                              }}
                            />
                          </div>
                          <div className="iteam-contain">
                            <h3 className="slide-title">
                              {coreValueList.vTitle}
                            </h3>
                            {/* <p>{coreValueList.tDescription}</p> */}
                            <p>
                              {showMoreStates[core]
                                ? coreValueList.tDescription
                                : coreValueList.tDescription.length > 90
                                ? `${coreValueList.tDescription.slice(
                                    0,
                                    90
                                  )} ...`
                                : coreValueList.tDescription}
                              <span>
                                {coreValueList.tDescription.length > 90 && (
                                  <button
                                    className="morelink"
                                    onClick={() => toggleShowMore(core)}
                                    style={{
                                      border: "none",
                                      color: "#F87217",
                                      background: "none",
                                    }}
                                  >
                                    {showMoreStates[core]
                                      ? "Read Less"
                                      : "Read More"}
                                  </button>
                                )}
                              </span>
                            </p>
                          </div>
                        </div>
                      ))}
                  </OwlCarousel>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default CoreValue;

// Extra code
/* <>
   <div className="item">
      <div className="img-box">
         <img
            src="assets/images/core-two.png"
            alt="core-value-img"
            height="57"
            width="57"
         />
      </div>
      <div className="iteam-contain">
         <div className="iteam-contain">
            <h3 className="slide-title">Health</h3>
            <p>Lorem ipsum dolor sit amet consectetur. Ultricies convallis consequat felis
               elementum bibendum lectus sodales ut. Sit sed quam scelerisque ullamcorper. </p>
         </div>
      </div>
   </div>
   <div className="item">
      <div className="img-box">
         <img src="assets/images/core-three.png" alt="core-value-img" height="57" width="57" />
      </div>
      <div className="iteam-contain">
         <h3 className="slide-title">Innovation</h3>
         <p>Lorem ipsum dolor sit amet consectetur. Ultricies convallis consequat felis
            elementum bibendum lectus sodales ut. Sit sed quam scelerisque ullamcorper. </p>
      </div>
   </div>
   <div className="item">
      <div className="img-box">
         <img src="assets/images/core-three.png" alt="core-value-img" height="57" width="57" />
      </div>
      <div className="iteam-contain">
         <h3 className="slide-title">Innovation</h3>
         <p>Lorem ipsum dolor sit amet consectetur. Ultricies convallis consequat felis
            elementum bibendum lectus sodales ut. Sit sed quam scelerisque ullamcorper. </p>
      </div>
   </div>
   <div className="item">
      <div className="img-box">
         <img src="assets/images/core-three.png" alt="core-value-img" height="57" width="57" />
      </div>
      <div className="iteam-contain">
         <h3 className="slide-title">Innovation</h3>
         <p>Lorem ipsum dolor sit amet consectetur. Ultricies convallis consequat felis
            elementum bibendum lectus sodales ut. Sit sed quam scelerisque ullamcorper. </p>
      </div>
   </div>
   <div className="item">
      <div className="img-box">
         <img src="assets/images/core-three.png" alt="core-value-img" height="57" width="57" />
      </div>
      <div className="iteam-contain">
         <h3 className="slide-title">Innovation</h3>
         <p>Lorem ipsum dolor sit amet consectetur. Ultricies convallis consequat felis
            elementum bibendum lectus sodales ut. Sit sed quam scelerisque ullamcorper. </p>
      </div>
   </div>
</> */
