import {
  EVENT_LIST_REQUEST,
  EVENT_LIST_SUCCESS,
  EVENT_LIST_FAILURE,
  EVENT_DETAIL_REQUEST,
  EVENT_DETAIL_SUCCESS,
  EVENT_DETAIL_FAILURE,
  RECOMMENDED_EVENT_REQUEST,
  RECOMMENDED_EVENT_SUCCESS,
  RECOMMENDED_EVENT_FAILURE,
  MY_EVENT_REQUEST,
  MY_EVENT_SUCCESS,
  MY_EVENT_FAILURE,
  ATTENDEES_EVENT_REQUEST,
  ATTENDEES_EVENT_SUCCESS,
  ATTENDEES_EVENT_FAILURE,
  ADD_NEW_EVENT_REQUEST,
  ADD_NEW_EVENT_SUCCESS,
  ADD_NEW_EVENT_FAILURE,
  ONE_TO_ONE_EVENT_REQUEST,
  ONE_TO_ONE_EVENT_SUCCESS,
  ONE_TO_ONE_EVENT_FAILURE,
  ALL_EVENT_REVIEW_REQUEST,
  ALL_EVENT_REVIEW_SUCCESS,
  ALL_EVENT_REVIEW_FAILURE,
  EVENT_TOP_REVIEW_REQUEST,
  EVENT_TOP_REVIEW_SUCCESS,
  EVENT_TOP_REVIEW_FAILURE,
  EDIT_EVENT_REQUEST,
  EDIT_EVENT_SUCCESS,
  EDIT_EVENT_FAILURE,
  STORE_NAME_RESET,
} from "../constants/eventConstant";

export function eventReducer(state = {}, action) {
  switch (action.type) {
    case EVENT_LIST_REQUEST:
      return {
        loading: true,
      };
    case EVENT_LIST_SUCCESS:
      return {
        loading: false,
        eventList: action.payload,
      };
    case EVENT_LIST_FAILURE:
      return {
        loading: false,
      };
    case STORE_NAME_RESET:
      return {
        loading: false,
        eventList: {},
      };
    default:
      return state;
  }
}

export function eventDetailReducer(state = {}, action) {
  switch (action.type) {
    case EVENT_DETAIL_REQUEST:
      return {
        loading: true,
      };
    case EVENT_DETAIL_SUCCESS:
      return {
        loading: false,
        eventDetail: action.payload,
      };
    case EVENT_DETAIL_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}

export function recommendedEventReducer(state = {}, action) {
  switch (action.type) {
    case RECOMMENDED_EVENT_REQUEST:
      return {
        loading: true,
      };
    case RECOMMENDED_EVENT_SUCCESS:
      return {
        loading: false,
        recommendedEvent: action.payload,
      };
    case RECOMMENDED_EVENT_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}

export function myEventReducer(state = {}, action) {
  switch (action.type) {
    case MY_EVENT_REQUEST:
      return {
        loading: true,
      };
    case MY_EVENT_SUCCESS:
      return {
        loading: false,
        myEvent: action.payload,
      };
    case MY_EVENT_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}

export function myAttendeesEventReducer(state = {}, action) {
  switch (action.type) {
    case ATTENDEES_EVENT_REQUEST:
      return {
        loading: true,
      };
    case ATTENDEES_EVENT_SUCCESS:
      return {
        loading: false,
        attendeesEvent: action.payload,
      };
    case ATTENDEES_EVENT_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}

export function addNewEventReducer(state = {}, action) {
  switch (action.type) {
    case ADD_NEW_EVENT_REQUEST:
      return {
        loading: true,
      };
    case ADD_NEW_EVENT_SUCCESS:
      return {
        loading: false,
        addNewEvent: action.payload,
      };
    case ADD_NEW_EVENT_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}

export function oneToOneEventReducer(state = {}, action) {
  switch (action.type) {
    case ONE_TO_ONE_EVENT_REQUEST:
      return {
        loading: true,
      };
    case ONE_TO_ONE_EVENT_SUCCESS:
      return {
        loading: false,
        oneToOneEvent: action.payload,
      };
    case ONE_TO_ONE_EVENT_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}

export function allEventReviewReducer(state = {}, action) {
  switch (action.type) {
    case ALL_EVENT_REVIEW_REQUEST:
      return {
        loading: true,
      };
    case ALL_EVENT_REVIEW_SUCCESS:
      return {
        loading: false,
        allEventReview: action.payload,
      };
    case ALL_EVENT_REVIEW_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}

export function eventTopReviewReducer(state = {}, action) {
  switch (action.type) {
    case EVENT_TOP_REVIEW_REQUEST:
      return {
        loading: true,
      };
    case EVENT_TOP_REVIEW_SUCCESS:
      return {
        loading: false,
        eventTopReview: action.payload,
      };
    case EVENT_TOP_REVIEW_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}

export function editEventReducer(state = {}, action) {
  switch (action.type) {
    case EDIT_EVENT_REQUEST:
      return {
        loading: true,
      };
    case EDIT_EVENT_SUCCESS:
      return {
        loading: false,
        editEvent: action.payload,
      };
    case EDIT_EVENT_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}
