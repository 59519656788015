import React from "react";
import Container from "../components/layouts/Container";
import CategoriesListing from "../components/Category/CategoriesListing";
// import RecommandCategories from "../components/Category/RecommandCategories";


const CategoriePage = () => {

  React.useEffect(() => {
    document.title = "Categories | WellNas";
  },);

  return (
    <React.Fragment>
      <div className="dark-header">
        <Container>

          <CategoriesListing />
          {/* <RecommandCategories /> */}

        </Container>
      </div>
    </React.Fragment>
  );
}

export default CategoriePage;
