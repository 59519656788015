import React, { Suspense } from "react";

const Header = React.lazy(() => import('./Header'));
const Footer = React.lazy(() => import('./Footer'));

function Container(props) {
   return (
      <React.Fragment>
         <Suspense fallback={<div className="loeader-box"><div className="jumping-dots-loader"> <span></span> <span></span> <span></span> </div>
            <div className="moving-gradient"></div></div>}>
            <Header />
            {props.children}
            <Footer />
         </Suspense>
      </React.Fragment>
   );
}

export default Container;
