import React from "react";
import { useDispatch, useSelector } from "react-redux";
import OwlCarousel from "react-owl-carousel";
import { Link, useNavigate } from "react-router-dom";

import { getEventList } from "../../store/action/eventAction";

const FeatureEvent = () => {
  // const errorImg = "/assets/images/no_image.png";
  // const errorImg = "/assets/images/no-image-icon.png";
  const errorImg = "/assets/images/no-image.png";

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { eventList } = useSelector((state) => state.event);

  // dateTime format
  const formatDateTime = (dateTimeString) => {
    const options = {
      weekday: "short",
      month: "short",
      day: "numeric",
      // hour: "numeric",
      // minute: "numeric",
      // hour12: true,
    };

    const formattedDate = new Date(dateTimeString).toLocaleString(
      "en-US",
      options
    );
    const commaIndex = formattedDate.indexOf(
      ",",
      formattedDate.indexOf(",") + 1
    );
    if (commaIndex !== -1) {
      return (
        formattedDate.substring(0, commaIndex) +
        " -" +
        formattedDate.substring(commaIndex + 1)
      );
    }
    return formattedDate;
  };

  const handleItemClick = (eventList) => {
    navigate(`/event-details/${eventList.vEvent.replace(/\s+/g, "-")}`, {
      state: { iEventId: eventList.iEventId },
    });
  };

  const [showMoreStates, setShowMoreStates] = React.useState({});

  const handleAddressClick = (index) => {
    // console.log("index", index);
    setShowMoreStates((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  // for EventList
  React.useEffect(() => {
    if (!eventList) {
      dispatch(
        getEventList({
          eFeature: "",
          vLocation: "",
          eType: "",
          vCategory: "",
          iCategoryId: "",
          vActivity: "",
          iActivityId: "",
        })
      );
    }
  }, [eventList, dispatch]);

  const navText = [
    "<i class='fal fa-chevron-left'></i>",
    "<i class='fal fa-chevron-right'></i>",
  ];

  return (
    <React.Fragment>
      <section className="event-main p-100">
        <div className="flex-warper-class">
          <div className="row g-4">
            <div className="col-xl-2 col-md-3">
              <div className="left-area">
                <h2 className="sub-title">Feature Events</h2>
                {/* <p className="sub-content">
                  Lorem ipsum dolor sit amet consectetur. Ultricies pulvinar
                  lectus fusc
                </p> */}
                <p className="count"></p>
                {eventList && eventList.length > 0 && (
                  <Link to={"/events"} className="secondary-btn mt-5">
                    View All
                  </Link>
                )}
              </div>
            </div>
            <div className="col-xl-10 col-md-9">
              <div className="right-area">
                {eventList && eventList.length > 0 ? (
                  <OwlCarousel
                    key={JSON.stringify(showMoreStates)}
                    className="sport_section owl-theme"
                    items={3.8}
                    autoplay={false}
                    smartSpeed={800}
                    loop={false}
                    margin={25}
                    nav
                    dots={false}
                    navText={navText}
                    responsive={{
                      0: {
                        items: 1,
                        margin: 10,
                      },
                      420: {
                        items: 2,
                        margin: 10,
                      },
                      575: {
                        items: 2,
                        margin: 15,
                      },
                      767: {
                        items: 3,
                        margin: 15,
                      },
                      1024: {
                        items: 3,
                        margin: 15,
                      },
                      1199: {
                        items: 4,
                      },
                    }}
                  >
                    {eventList &&
                      eventList.slice(0, 8).map((event, eventDel) => (
                        <div
                          className="item"
                          style={{ cursor: "pointer" }}
                          key={eventDel}
                          //   onClick={() => handleItemClick(event)}
                        >
                          <div className="img-box">
                            <img
                              src={event ? event.vWebpImage : errorImg}
                              alt={event.vImageAlt}
                              height={310}
                              width={370}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = errorImg;
                              }}
                              style={{ objectFit:  event.vWebpImage.split("/").pop() === "null" ? 'contain' : 'cover' }}
                            />
                          </div>
                          <div className="iteam-contain">
                            <p
                              className="date-time"
                              onClick={() => handleItemClick(event)}
                            >
                              {formatDateTime(event.dtEvent)}
                            </p>
                            <h3
                              className="slide-title"
                              onClick={() => handleItemClick(event)}
                            >
                              {event.vEvent}
                            </h3>
                            <p
                              className="description"
                              onClick={() => handleItemClick(event)}
                            >
                              {event.tDescription}
                            </p>
                            {event.categories &&
                              event.categories.map((eventCate, category) => {
                                return (
                                  <React.Fragment key={category}>
                                    <p
                                      className="location"
                                      style={{
                                        marginBottom: "10px",
                                        fontWeight: "bold",
                                        color: "#fd4700",
                                      }}
                                      onClick={() => handleItemClick(event)}
                                    >
                                      {eventCate.vCategory}
                                    </p>
                                    <p
                                      className="point-location"
                                      onClick={() => {
                                        handleAddressClick(event.iEventId);
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-geo-alt-fill"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                                      </svg>
                                      &nbsp;
                                      {/* {event.vLocation} */}
                                      {showMoreStates[event.iEventId]
                                        ? event.vLocation
                                        : event.vLocation.slice(0, 20) +
                                          (event.vLocation.length > 20
                                            ? "..."
                                            : "")}
                                    </p>
                                  </React.Fragment>
                                );
                              })}
                            {/* <p className="location">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                                             <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                                          </svg>
                                          {event.vLocation}
                                       </p> */}
                          </div>
                        </div>
                      ))}
                  </OwlCarousel>
                ) : (
                  <p>No Data Found</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default FeatureEvent;
