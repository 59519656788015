// Import necessary dependencies
import React, { Suspense } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";

// Import your page components
import Loader from "../components/Loader/Loader";
import HomePage from "../pages/HomePage";
import RegisterPage from "../pages/RegisterPage";
import LoginPage from "../pages/LoginPage";
import AboutPage from "../pages/AboutPage";
import ContactPage from "../pages/ContactPage";
import EventPage from "../pages/EventPage";
import EventDetailPage from "../pages/EventDetailPage";
import CategoriesPage from "../pages/CategoriesPage";
import ActivityPage from "../pages/ActivityPage";
// import GroupDetailPage from "../pages/GroupDetailPage";
// import GroupPage from "../pages/GroupPage";
import ForgetpasswordPage from "../pages/ForgetpasswordPage";
import ResetpasswordPage from "../pages/ResetpasswordPage";
// import ProfilePage from "../pages/ProfilePage";
import ErrorPage from "../pages/ErrorPage";
import AccountVerifiedPage from "../pages/AccountVerifiedPage";
import UpdatePasswordPage from "../pages/UpdatePasswordPage";
import SuccessfulRegistrationPage from "../pages/SuccessfulRegistrationPage";
import SuccessResetPasswordPage from "../pages/SuccessResetPasswordPage";
import PageNotFound from "../pages/PageNotFound";
import NotificationPage from "../pages/NotificationPage";
import AttendedEventPage from "../pages/AttendedEventPage";
import VerifyPage from "../pages/VerifyPage";
import GoogleVerifyPage from "../pages/GoogleVerifyPage";
import SettingPage from "../pages/SettingPage";
import MyEventPage from "../pages/MyEventPage";
import AddEventPage from "../pages/AddEventPage";
// import MyGroupPage from "../pages/MyGroupPage";
// import AddGroupPage from "../pages/AddGroupPage";

// Import necessary CSS stylesheets
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OneToOneEventPage from "../pages/OneToOneEvent";
import ReviewPage from "../pages/ReviewPage";
import UserInformationPage from "../pages/UserInformationPage";
import EditEventPage from "../pages/EditEventPage";
import SearchPage from "../pages/SearchPage";

import TermAndConditionPage from "../pages/TermAndConditionPage";
import PrivacyPolicyPage from "../pages/PrivacyPolicyPage";
import JoinNowPage from "../pages/JoinNowPage";

const ProfilePage = React.lazy(() => import("../pages/ProfilePage"));

// function to scroll to the top on route change
const ScrollToTopOnRouteChange = () => {
  const location = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
};

// const vUniqueCode = sessionStorage.getItem("vUniqueCode");
// console.log("vUniqueCode", vUniqueCode);

function Index() {
  const location = useLocation();
  const navigate = useNavigate();

  // for dynamic browser title
  // React.useEffect(() => {
  //   const routeTitle = location.state && location.state.title;
  //   console.log("title", routeTitle);

  //   // Set the browser tab title
  //   const mainTitle = "WellNas";
  //   const dynamicTitle = routeTitle
  //     ? ` ${routeTitle} | ${mainTitle}`
  //     : mainTitle;

  //   document.title = dynamicTitle;
  // }, [location]);

  React.useEffect(() => {
    const routeTitle = location && location.pathname;

    const pathWithoutSlash = location.pathname.slice(1);
    const formattedTitle =
      pathWithoutSlash.charAt(0).toUpperCase() + pathWithoutSlash.slice(1);

    // Set the browser tab title
    const mainTitle = "WellNas";
    const dynamicTitle = formattedTitle
      ? ` ${formattedTitle} | ${mainTitle}`
      : mainTitle;

    // console.log("Dynamic Title:", dynamicTitle);

    document.title = dynamicTitle;
  }, [location]);

  return (
    <React.Fragment>
      {/* Scroll to top on route change */}
      <ScrollToTopOnRouteChange />
      {/* Define your routes */}
      <Routes>
        {/* Public routes */}
        <Route exact path="/" element={<HomePage />} title="Home" />
        <Route
          exact
          path="/register"
          element={<RegisterPage />}
          title="Register"
        />
        <Route exact path="/login" element={<LoginPage />} title="Login" />
        <Route
          exact
          path="/join-now"
          element={<JoinNowPage />}
          title="Join-now"
        />
        <Route
          exact
          path="/about-us"
          element={<AboutPage />}
          title="About-us"
        />
        <Route
          exact
          path="/term-condition"
          element={<TermAndConditionPage />}
          title="Term-condition"
        />
        <Route
          exact
          path="/privacy-policy"
          element={<PrivacyPolicyPage />}
          title="Privacy-policy"
        />

        <Route
          exact
          path="/contact-us"
          element={<ContactPage />}
          title="Contact-us"
        />
        <Route exact path="/events" element={<EventPage />} title="Events" />
        <Route
          exact
          path="/search/:searchData"
          element={<SearchPage />}
          title="search"
        />

        <Route
          exact
          path="/event-details/:vEvent"
          element={<EventDetailPage />}
          title="Event-details"
        />
        <Route
          exact
          path="/categories"
          element={<CategoriesPage />}
          title="Categories"
        />
        <Route
          exact
          path="/categories/:vCategory/events"
          element={<EventPage />}
          title="Category-events"
        />
        <Route
          exact
          path="/activities"
          element={<ActivityPage />}
          title="Activities"
        />
        <Route
          exact
          path="/activities/:vActivity/events"
          element={<EventPage />}
          title="Activity-events"
        />
        {/* <Route exact path="/reviews" element={<ReviewPage />} title="Reviews" /> */}
        <Route
          exact
          path="/reviews/:vEvent"
          element={<ReviewPage />}
          title="Event-reviews"
        />
        {/* <Route exact path="/user-information" element={<UserInformationPage />} title="User-information" /> */}
        <Route
          exact
          path="/user-information/:vUniqueCode"
          element={<UserInformationPage />}
          title="User-information"
        />
        {/* Routes accessible only for logged-in users */}
        {/* {isAuthenticated && ( */}
        {/* Promotion */}
        {/* <React.Fragment>
          <Suspense fallback={<Loader />}>
            <Route
              exact
              path="/profile"
              element={<ProfilePage />}
              title="profile-page"
            />
          </Suspense>
        </React.Fragment> */}

        <Route
          path={"/profile/:vUniqueCode"}
          element={
            <Suspense fallback={<Loader />}>
              <ProfilePage />
            </Suspense>
          }
          title="profile-page"
        />
        <Route
          path={"/profile"}
          element={
            <Suspense fallback={<Loader />}>
              <ProfilePage />
            </Suspense>
          }
          title="profile-page"
        />

        <React.Fragment>
          <Route
            exact
            path="/setting"
            element={<SettingPage />}
            title="Notification-page"
          />
          <Route
            exact
            path="/notification"
            element={<NotificationPage />}
            title="message-notification-page"
          />
          <Route
            exact
            path="/attended-event"
            element={<AttendedEventPage />}
            title="attendedevent-page"
          />
          <Route
            exact
            path="/onetoone-event"
            element={<OneToOneEventPage />}
            title="onetoone-page"
          />
          <Route
            exact
            path="/my-event"
            element={<MyEventPage />}
            title="my-eventListing-page"
          />
          <Route
            exact
            path="/add-event"
            element={<AddEventPage />}
            title="add-event-page"
          />
          <Route
            exact
            path="/edit-event/:iEventId"
            element={<EditEventPage />}
            title="add-event-page"
          />
          {/* <Route exact path="/my-group" element={<MyGroupPage />} title="my-groupListing-page" /> */}
          {/* <Route exact path="/add-group" element={<AddGroupPage />} title="add-group-page" /> */}
        </React.Fragment>
        {/* )} */}
        {/* <Route exact path="/group-details" element={<GroupDetailPage />} title="Group-details" /> */}
        <Route
          exact
          path="/forget-password"
          element={<ForgetpasswordPage />}
          title="Forget-password"
        />
        <Route
          exact
          path="/reset-password/:vUniqueCode"
          element={<ResetpasswordPage />}
          title="Reset-password"
        />
        <Route exact path="/error" element={<ErrorPage />} title="error-page" />
        <Route
          exact
          path="/account-verified"
          element={<AccountVerifiedPage />}
          title="accountverified-page"
        />
        <Route
          exact
          path="/updatepassword"
          element={<UpdatePasswordPage />}
          title="updatepassword-page"
        />
        <Route
          exact
          path="/success-registration"
          element={<SuccessfulRegistrationPage />}
          title="successfulregistration-page"
        />
        <Route
          exact
          path="/success-reset-password"
          element={<SuccessResetPasswordPage />}
          title="successresetpassword-page"
        />
        <Route
          exact
          path="/verify/:vUniqueCode"
          element={<VerifyPage />}
          title="accountverified-page"
        />
        <Route
          exact
          path="/google_response"
          element={<GoogleVerifyPage />}
          title="googleverify-page"
        />
        {/* Route for 404 Page Not Found */}
        <Route path="/*" element={<PageNotFound />} />
      </Routes>
    </React.Fragment>
  );
}

export default Index;
