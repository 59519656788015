export const CORE_VALUES_REQUEST = 'CORE_VALUES_REQUEST'
export const CORE_VALUES_SUCCESS = 'CORE_VALUES_SUCCESS'
export const CORE_VALUES_FAILURE = 'CORE_VALUES_FAILURE'

export const HOW_IT_WORKS_REQUEST = 'HOW_IT_WORKS_REQUEST'
export const HOW_IT_WORKS_SUCCESS = 'HOW_IT_WORKS_SUCCESS'
export const HOW_IT_WORKS_FAILURE = 'HOW_IT_WORKS_FAILURE'

export const WHY_WELLNAS_REQUEST = 'WHY_WELLNAS_REQUEST'
export const WHY_WELLNAS_SUCCESS = 'WHY_WELLNAS_SUCCESS'
export const WHY_WELLNAS_FAILURE = 'WHY_WELLNAS_FAILURE'

export const PAGE_LIST_JOIN_REQUEST = 'PAGE_LIST_JOIN_REQUEST'
export const PAGE_LIST_JOIN_SUCCESS = 'PAGE_LIST_JOIN_SUCCESS'
export const PAGE_LIST_JOIN_FAILURE = 'PAGE_LIST_JOIN_FAILURE'

export const PAGE_LIST_ABOUT_REQUEST = 'PAGE_LIST_ABOUT_REQUEST'
export const PAGE_LIST_ABOUT_SUCCESS = 'PAGE_LIST_ABOUT_SUCCESS'
export const PAGE_LIST_ABOUT_FAILURE = 'PAGE_LIST_ABOUT_FAILURE'