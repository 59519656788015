import React from "react";
import ProfileMenu from "../Profile/ProfileMenu";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getMyEvent } from "../../store/action/eventAction";
import SkeletonMyEventList from "../../Skeleton/SkeletonMyEventList";

const MyEventListing = () => {
  // const errorImg = "/assets/images/no_image.png";
  // const errorImg = "/assets/images/no-image-icon.png";
  const errorImg = "/assets/images/no-image.png";
  const location = useLocation();

  const { vUniqueCode: paramVUniqueCode } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { myEvent } = useSelector((state) => state.myEvent);

  // Loading state to determine whether to show content or skeleton
  const [loading, setLoading] = React.useState(true);

  const [showFullAddress, setShowFullAddress] = React.useState(false);
  const [addressVisibility, setAddressVisibility] = React.useState({});

  const [eventId, setEventId] = React.useState(false);

  // dateTime format
  const formatDateTime = (dateTimeString) => {
    const options = {
      weekday: "short",
      month: "short",
      day: "numeric",
      // hour: "numeric",
      // minute: "numeric",
      // hour12: true,
    };

    const formattedDate = new Date(dateTimeString).toLocaleString(
      "en-US",
      options
    );
    const commaIndex = formattedDate.indexOf(
      ",",
      formattedDate.indexOf(",") + 1
    );
    if (commaIndex !== -1) {
      return (
        formattedDate.substring(0, commaIndex) +
        " -" +
        formattedDate.substring(commaIndex + 1)
      );
    }
    // setDate(formattedDate);
    return formattedDate;
  };

  const handleDetailClick = (myEventDel) => {
    navigate(`/event-details/${myEventDel.vEvent.replace(/\s+/g, "-")}`, {
      state: { iEventId: myEventDel.iEventId },
    });
  };

  const handleAddressClick = (eventId) => {
    // console.log("eventId", eventId);

    setAddressVisibility((prevVisibility) => ({
      ...prevVisibility,
      [eventId]: !prevVisibility[eventId], // Toggle visibility for the clicked eventId
    }));

    setShowFullAddress((prevShowFullAddress) =>
      prevShowFullAddress === eventId ? null : eventId
    );
  };

  // Simulate an API call delay with useEffect
  React.useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);
  // const code = localStorage.getItem("code");
  // console.log("code --------------->", code);

  // for myEvent list
  React.useEffect(() => {
    const vUniqueCode =
      // sessionStorage.getItem("vUniqueCode") ||
      localStorage.getItem("vUniqueCode");

    if (!myEvent) {
      dispatch(
        getMyEvent({
          vUniqueCode: vUniqueCode,
        })
      );
    }
  }, [myEvent, dispatch]);

  return (
    <React.Fragment>
      <div className="profile-layer">
        <div className="profile-page">
          <div className="container-fluid">
            <div className="row g-4">
              <div className="col-lg-2 col-md-3">
                <ProfileMenu />
              </div>
              <div className="col-lg-10 col-md-9 col-12">
                <div className="attend-event-page white-card">
                  <div className="title-top">
                    <h1>My Events</h1>
                    <Link to="/add-event" className="main-btn">
                      + Add New Event
                    </Link>
                  </div>
                  {loading && myEvent && myEvent.events.length > 0 ? (
                    // Show Skeleton while loading
                    <SkeletonMyEventList count={12} />
                  ) : myEvent && myEvent.events.length > 0 ? (
                    <div className="row g-xl-4 g-3">
                      {myEvent &&
                        myEvent.events.map((profileMyEvent, profileEvent) => {
                          // console.log("profileMyEvent", profileMyEvent);
                          return (
                            <div
                              className="col-xl-3 col-lg-4 col-sm-6 col-12"
                              key={profileEvent}
                            >
                              <div
                                className="box"
                                //   onClick={() =>
                                //     handleDetailClick(profileMyEvent)
                                //   }
                                style={{ cursor: "pointer" }}
                              >
                                <img
                                  src={
                                    profileMyEvent
                                      ? profileMyEvent.vWebpImage
                                      : errorImg
                                  }
                                  alt={profileMyEvent.vImageAlt}
                                  onClick={() =>
                                    handleDetailClick(profileMyEvent)
                                  }
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = errorImg;
                                  }}
                                  style={{
                                    objectFit:
                                      profileMyEvent.vWebpImage
                                        .split("/")
                                        .pop() === "null"
                                        ? "contain"
                                        : "cover",
                                  }}
                                />

                                <Link
                                  type="button"
                                  className="redirect-btn"
                                  to={`/edit-event/${profileMyEvent.vEvent.replace(
                                    /\s+/g,
                                    "-"
                                  )}`}
                                  state={{ iEventId: profileMyEvent.iEventId }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="40"
                                    height="40"
                                    viewBox="0 0 40 40"
                                    fill="none"
                                  >
                                    <path
                                      d="M39.5 20C39.5 9.23045 30.7696 0.5 20 0.5C9.23045 0.5 0.5 9.23045 0.5 20C0.5 30.7696 9.23045 39.5 20 39.5C30.7696 39.5 39.5 30.7696 39.5 20Z"
                                      fill="#F8F8F8"
                                      stroke="#C4C4C4"
                                    ></path>
                                    <path
                                      d="M13 26.9708H14.414L23.728 17.6568L22.314 16.2428L13 25.5568V26.9708ZM29 28.9708H11V24.7278L24.435 11.2928C24.6225 11.1053 24.8768 11 25.142 11C25.4072 11 25.6615 11.1053 25.849 11.2928L28.678 14.1218C28.8655 14.3093 28.9708 14.5636 28.9708 14.8288C28.9708 15.094 28.8655 15.3483 28.678 15.5358L17.243 26.9708H29V28.9708ZM23.728 14.8288L25.142 16.2428L26.556 14.8288L25.142 13.4148L23.728 14.8288Z"
                                      fill="#8A8A8A"
                                    ></path>
                                  </svg>
                                </Link>
                                {/* <a href="" className="share">
                                                <i className="fas fa-share"></i>
                                             </a> */}
                                <div className="text">
                                  <div
                                    className="time"
                                    onClick={() =>
                                      handleDetailClick(profileMyEvent)
                                    }
                                  >
                                    {formatDateTime(profileMyEvent.dtEvent)}
                                  </div>

                                  <h2
                                    onClick={() =>
                                      handleDetailClick(profileMyEvent)
                                    }
                                  >
                                    {profileMyEvent.vEvent}
                                  </h2>
                                  <p
                                    className="description"
                                    onClick={() =>
                                      handleDetailClick(profileMyEvent)
                                    }
                                  >
                                    {profileMyEvent.tDescription}
                                  </p>
                                  {profileMyEvent.categories &&
                                    profileMyEvent.categories.map(
                                      (eventCate, category) => {
                                        return (
                                          <>
                                            <p
                                              className="location"
                                              key={category}
                                              onClick={() =>
                                                handleDetailClick(
                                                  profileMyEvent
                                                )
                                              }
                                              style={{
                                                marginBottom: "10px",
                                                fontWeight: "bold",
                                                color: "#fd4700",
                                              }}
                                            >
                                              {eventCate.vCategory}
                                            </p>
                                            {/* <br/> */}
                                            <p
                                              className="point-location"
                                              onClick={() => {
                                                handleAddressClick(
                                                  profileMyEvent.iEventId
                                                );
                                              }}
                                              // onClick={() =>
                                              //   handleDetailClick(
                                              //     profileMyEvent
                                              //   )
                                              // }
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-geo-alt-fill"
                                                viewBox="0 0 16 16"
                                              >
                                                <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                                              </svg>
                                              &nbsp;
                                              {/* {profileMyEvent.vLocation} */}
                                              {addressVisibility[
                                                profileMyEvent.iEventId
                                              ]
                                                ? profileMyEvent.vLocation
                                                : profileMyEvent.vLocation.slice(
                                                    0,
                                                    20
                                                  ) +
                                                  (profileMyEvent.vLocation
                                                    .length > 20
                                                    ? "..."
                                                    : "")}
                                            </p>
                                          </>
                                        );
                                      }
                                    )}
                                  {/* <p className="location">
                                                   {profileMyEvent.vEvent} - {profileMyEvent.vLocation}
                                                </p> */}
                                  <p
                                    className="attendeds"
                                    onClick={() =>
                                      handleDetailClick(profileMyEvent)
                                    }
                                  >
                                    {profileMyEvent.attendees_count} attendees
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  ) : myEvent && myEvent.events.length === 0 ? (
                    <div className="no-data-found">
                      <p>No Data Found</p>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MyEventListing;
