import React from "react";
import Container from "../components/layouts/Container";
import EventDetail from "../components/Event/EventDetail";
import SimilarEvent from "../components/Event/SimilarEvent";
import EventReviews from "../components/Review/EventReviews";
import AddReview from "../components/Review/AddReview";

const EventDetailPage = () => {
  return (
    <React.Fragment>
      <div className="dark-header">
        <Container>
          <EventDetail />
          {/* --- Top 3 Reviews list --- */}
          <div className="section-with-background">
            <EventReviews />
          </div>

          {/* --- Add Review --- */}
          <AddReview />
          <div className="section-with-background">
            <SimilarEvent />
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EventDetailPage;
