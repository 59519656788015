import React from "react";
import { Link } from "react-router-dom";

const SuccessfulRegistrationPage = () => {
   return (
      <div className="highlight-msg">
         <Link to={"/"} className="logo-box">
            <img src="/assets/images/logo.png" alt="" />
         </Link>
         <div className="text">
            <h1>Thank You</h1>
            <p>Your account is successfully registered. Please check your email and verify your account.</p>
            <Link to={"/"} className="main-btn">Home</Link>
         </div>
      </div>
   );
};

export default SuccessfulRegistrationPage;
