export const EVENT_LIST_REQUEST = "EVENT_LIST_REQUEST";
export const EVENT_LIST_SUCCESS = "EVENT_LIST_SUCCESS";
export const EVENT_LIST_FAILURE = "EVENT_LIST_FAILURE";

export const EVENT_DETAIL_REQUEST = "EVENT_DETAIL_REQUEST";
export const EVENT_DETAIL_SUCCESS = "EVENT_DETAIL_SUCCESS";
export const EVENT_DETAIL_FAILURE = "EVENT_DETAIL_FAILURE";

export const RECOMMENDED_EVENT_REQUEST = "RECOMMENDED_EVENT_REQUEST";
export const RECOMMENDED_EVENT_SUCCESS = "RECOMMENDED_EVENT_SUCCESS";
export const RECOMMENDED_EVENT_FAILURE = "RECOMMENDED_EVENT_FAILURE";

export const MY_EVENT_REQUEST = "MY_EVENT_REQUEST";
export const MY_EVENT_SUCCESS = "MY_EVENT_SUCCESS";
export const MY_EVENT_FAILURE = "MY_EVENT_FAILURE";

export const ATTENDEES_EVENT_REQUEST = "ATTENDEES_EVENT_REQUEST";
export const ATTENDEES_EVENT_SUCCESS = "ATTENDEES_EVENT_SUCCESS";
export const ATTENDEES_EVENT_FAILURE = "ATTENDEES_EVENT_FAILURE";

export const ADD_NEW_EVENT_REQUEST = "ADD_NEW_EVENT_REQUEST";
export const ADD_NEW_EVENT_SUCCESS = "ADD_NEW_EVENT_SUCCESS";
export const ADD_NEW_EVENT_FAILURE = "ADD_NEW_EVENT_FAILURE";

export const ONE_TO_ONE_EVENT_REQUEST = "ONE_TO_ONE_EVENT_REQUEST";
export const ONE_TO_ONE_EVENT_SUCCESS = "ONE_TO_ONE_EVENT_SUCCESS";
export const ONE_TO_ONE_EVENT_FAILURE = "ONE_TO_ONE_EVENT_FAILURE";

export const ALL_EVENT_REVIEW_REQUEST = "ALL_EVENT_REVIEW_REQUEST";
export const ALL_EVENT_REVIEW_SUCCESS = "ALL_EVENT_REVIEW_SUCCESS";
export const ALL_EVENT_REVIEW_FAILURE = "ALL_EVENT_REVIEW_FAILURE";

export const EVENT_TOP_REVIEW_REQUEST = "EVENT_TOP_REVIEW_REQUEST";
export const EVENT_TOP_REVIEW_SUCCESS = "EVENT_TOP_REVIEW_SUCCESS";
export const EVENT_TOP_REVIEW_FAILURE = "EVENT_TOP_REVIEW_FAILURE";

export const EDIT_EVENT_REQUEST = "EDIT_EVENT_REQUEST";
export const EDIT_EVENT_SUCCESS = "EDIT_EVENT_SUCCESS";
export const EDIT_EVENT_FAILURE = "EDIT_EVENT_FAILURE";

export const STORE_NAME_RESET = "STORE_NAME_RESET";
