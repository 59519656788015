import React from "react";
import { Link } from "react-router-dom";

const AccountVerifiedPage = () => {

   return (
      <>
         <div className="highlight-msg">
            <Link to={"/"} className="logo-box">
               <img src="/assets/images/logo.png" alt="" />
            </Link>
            <div className="text">
               <h1 className="success-color">Success !!</h1>
               <p>
                  Your account is verified successfully. Now you can login and start
                  your journey & connect with the community.
               </p>
               <Link to={"/login"} className="main-btn">
                  Login
               </Link>
            </div>
         </div>
      </>
   );
};

export default AccountVerifiedPage;
