import React from "react";
import Container from "../components/layouts/Container";
import Contact from "../components/Contact/contactUs";

const ContactPage = () => {

  React.useEffect(() => {
    // console.log("useEffect is running");
    document.title = "Contact-us | WellNas";
  },);

  return (
    <React.Fragment>
      <div className="dark-header">
        <Container>

          <Contact />

        </Container>
      </div>
    </React.Fragment>
  );
}

export default ContactPage;
