import React from "react";
import { getJoinNow } from "../../store/action/attendeesAction";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";
import { Toast } from "primereact/toast";

function JoinNowForm() {
  const dispatch = useDispatch();
  const location = useLocation();
  const toast = React.useRef(null);
  const navigate = useNavigate();
  const [fullname, setFullname] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [mobile, setMobile] = React.useState("");

  const [fullnameError, setFullnameError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [mobileError, setMobileError] = React.useState("");

  const { state } = useLocation();

  //   console.log("state------------------>", state);

  const vEvent = state && state.EventName;

  const { joinNow } = useSelector((state) => state.joinNow);

  const vUniqueCode = localStorage.getItem("vUniqueCode");

  const validatePhone = (phoneNumber) => {
    if (!phoneNumber.match(/^\d{6,12}$/)) {
      setMobileError("Mobile number should be between 6 and 12 digits.");
      return false;
    }
    setMobileError("");
    return true;
  };

  const handlefullNameChange = (event) => {
    const enteredName = event.target.value.trim();
    setFullname(enteredName);
    event.target.value.trim() !== "" && setFullnameError("");
    if (enteredName.trim() === "") {
      setFullnameError("Name is Required.");
    }
  };

  const handleEmailChange = (event) => {
    const enteredEmail = event.target.value.trim();
    setEmail(enteredEmail);

    event.target.value.trim() !== "" && setEmailError("");

    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (enteredEmail.trim() === "") {
      setEmailError("Email is required");
    } else if (!emailRegex.test(enteredEmail)) {
      setEmailError("Invalid email format");
    } else {
      setEmailError("");
    }
  };

  const handleMobileChange = (event) => {
    const contactNumber = event.target.value.trim();

    setMobile(contactNumber);

    event.target.value.trim() !== "" && setMobileError("");

    // Check if the contact number has a valid length

    if (contactNumber.length > 0 && !contactNumber.match(/^\d{6,12}$/)) {
      setMobileError("Mobile number should be between 6 and 12 digits.");
    } else {
      setMobileError("");
    }
    // setMobileError("");
  };

  const isValidEmail = React.useCallback((email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }, []);

  const ValidateJoinForm = React.useCallback(() => {
    let isValid = true;

    // Reset error messages
    setEmailError("");
    setMobileError("");
    setFullnameError("");

    if (email.length === 0) {
      setEmailError("Email is Required.");
      isValid = false;
    } else if (!isValidEmail(email)) {
      setEmailError("Invalid email format.");
      isValid = false;
    }
    if (mobile.length > 0 && !mobile.match(/^\d{6,12}$/)) {
      setMobileError("Mobile number should be between 6 and 12 digits.");
      isValid = false;
    }
    if (fullname.length === 0) {
      setFullnameError("Name is Required.");
      isValid = false;
    }
    if (document.getElementById("exampleInputAccept").checked === false) {
      alert("Terms and Conditions Need to Accept.");
      isValid = false;
    }

    return isValid;
  }, [email, mobile, fullname, isValidEmail]);

  //   const JoinNewEvent = async (event) => {
  //     event.preventDefault();
  //     const isValid = validateContactForm();
  //     alert("hello");
  //   };

  //   console.log("Join =------------->", joinNow);

  const JoinNewEvent = React.useCallback(
    async (e) => {
      e.preventDefault();

      const isValid = ValidateJoinForm();
      if (isValid) {
        const values = {
          vUniqueCode: vUniqueCode,
          iEventId: state && state.iEventId,
        };
        try {
          document.getElementById("join_now_button").style.visibility = 'hidden';
          document.getElementById("join_now_button_loader").style.display = 'block';

          localStorage.setItem("iEventId", state.iEventId);
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/event_details/join_now`,
            values
          );
          if (response.status === 200) {
            // setSuccessMessage("Your message is submitted");
            setFullname("");
            setEmail("");
            setMobile("");
            toast.current.show({
              severity: "success",
              summary: "You Have Successfully joined The Event.",
              life: 2000,
            });

            setTimeout(() => {
              //   setSuccessMessage("");
              navigate(`/event-details/${vEvent.replace(/\s+/g, "-")}`, {
                state: { iEventIdNew: state && state.iEventId },
              });
            }, 3000);
          }
        } catch (error) {}
      } else {
        if (fullnameError) {
          setFullname("");
        }
        if (emailError) {
          setEmail("");
        }
        if (mobileError) {
          setMobile("");
        }
      }
    },
    [
      fullname,
      mobile,
      email,
      fullnameError,
      emailError,
      mobileError,
      ValidateJoinForm,
    ]
  );

  return (
    <React.Fragment>
      <Toast ref={toast} />
      <div className="profile-layer">
        <div className="profile-page">
          <div className="container-fluid">
            <div className="col-lg-5 col-md-7 col-10 mx-auto">
              <div className="add-event-form white-card attend-event-page">
                <div className="title-top">
                  <h1>Join Event</h1>
                </div>
                <form onSubmit={JoinNewEvent}>
                  <div className="row g-xl-4 g-3">
                    <div className="col-12">
                      <div className="form-group">
                        <label>Fullname</label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputFullname"
                          placeholder="Enter Fullname "
                          name="fullname"
                          value={fullname}
                          onChange={handlefullNameChange}
                        />
                      </div>
                      {fullnameError && (
                        <div
                          className="error-msg"
                          style={{
                            color: "red",
                            fontSize: "14px",
                            // marginLeft: "22px",
                          }}
                        >
                          {fullnameError}
                        </div>
                      )}
                    </div>

                    <div className="col-12">
                      <div className="form-group">
                        <label>Email</label>
                        <input
                          type="text"
                          name="email"
                          className="form-control"
                          id="exampleInputEmail"
                          onChange={handleEmailChange}
                          placeholder={"Enter Email"}
                          value={email}
                        />
                      </div>
                      {emailError && (
                        <div
                          className="error-msg"
                          style={{
                            color: "red",
                            fontSize: "14px",
                            // marginLeft: "22px",
                          }}
                        >
                          {emailError}
                        </div>
                      )}
                    </div>

                    <div className="col-12">
                      <div className="form-group">
                        <label>Mobile</label>
                        <input
                          type="text"
                          name="mobile"
                          id="exampleInputMobile"
                          className="form-control"
                          placeholder="Enter Mobile"
                          value={mobile}
                          onChange={handleMobileChange}
                        />
                      </div>
                      {mobileError && (
                        <div
                          className="error-msg"
                          style={{
                            color: "red",
                            fontSize: "14px",
                            // marginLeft: "22px",
                          }}
                        >
                          {mobileError}
                        </div>
                      )}
                    </div>

                    <div className="col-12">
                      <div className="form-group">
                        <input type="checkbox" name="accept" id="exampleInputAccept" />  Please click the checkbox to confirm that you have read and agree to the event rules.
                      </div>
                    </div>

                    <div className="col-12">
                      {/* {isLoading ? (
                  <div class="text-center logo-container animate__animated animate__fadeIn">
                    <div className="ft-logo">
                      <img
                        src="/assets/images/logo.png"
                        alt="wellnes"
                        height={105}
                        width={130}
                      />
                    </div>
                    <h5
                      style={{
                        fontSize: "15px",
                        color: "#fd4700",
                        marginTop: "10px",
                        textAlign: "center",
                      }}
                    >
                      Submitting .....
                    </h5>
                  </div>
                ) : ( */}
                      <button class="main-btn" type="button" disabled id="join_now_button_loader" style={{display: 'none'}}>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span class="sr-only">Loading...</span>&nbsp; &nbsp;Joining
                      </button>
                      <button type="submit" className="main-btn" id="join_now_button">
                        Submit
                      </button>
                      
                      {/* )} */}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default JoinNowForm;
