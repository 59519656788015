import React from "react";
import axios from "axios";

const Contact = () => {
  React.useEffect(() => {
    document.title = "Contact-us | WellNas";
  });

  // state variables for form inputs
  const [name, setName] = React.useState("");
  const [emailId, setEmailId] = React.useState("");
  const [message, setMessage] = React.useState("");

  // state variables for error & success messages
  const [nameError, setNameError] = React.useState("");
  const [emailIdError, setEmailIdError] = React.useState("");
  const [messageError, setMessageError] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");

  const isValidEmail = React.useCallback((email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }, []);

  const handleName = (event) => {
    setName(event.target.value);
    setNameError("");
  };
  const handleEmail = (event) => {
    setEmailId(event.target.value);
    setEmailIdError("");
  };
  const handleMessage = (event) => {
    setMessage(event.target.value);
    setMessageError("");
  };

  // validation conditions for contact-us
  const validateContactForm = React.useCallback(() => {
    let isValid = true;

    // Reset error messages
    setNameError("");
    setEmailIdError("");
    setMessageError("");

    if (name.length === 0) {
      setNameError("Name is required.");
      isValid = false;
    }
    if (emailId.length === 0) {
      setEmailIdError("Email is required.");
      isValid = false;
    } else if (!isValidEmail(emailId)) {
      setEmailIdError("Invalid email format.");
      isValid = false;
    }
    if (message.length === 0) {
      setMessageError("Message is required.");
      isValid = false;
    }
    if (message.length > 1000) {
      setMessageError("Maximum limit is 1000 characters.");
      isValid = false;
    }
    return isValid;
  }, [name, emailId, message, isValidEmail]);

  // for contact-us
  const contactUsDetail = React.useCallback(
    async (e) => {
      e.preventDefault();

      const isValid = validateContactForm();
      if (isValid) {
        const values = {
          vName: name,
          vEmail: emailId,
          tMessage: message,
        };
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/contact/contact-us`,
            values
          );
          if (response.status === 200) {
            setSuccessMessage("Your message is submitted");
            setName("");
            setEmailId("");
            setMessage("");

            setTimeout(() => {
              setSuccessMessage("");
            }, 3000);
          }
        } catch (error) {}
      } else {
        if (nameError) {
          setName("");
        }
        if (emailIdError) {
          setEmailId("");
        }
        if (messageError) {
          setMessage("");
        }
      }
    },
    [
      name,
      message,
      emailId,
      nameError,
      emailIdError,
      messageError,
      validateContactForm,
    ]
  );

  return (
    <React.Fragment>
      <section
        className="inner-page-breadcamp contact-banner"
        style={{ backgroundImage: "url(assets/images/contact-banner.png)" }}
      >
        <div className="container">
          <div className="brad-camp-detail">
            <p className="sub-title">Contact us</p>
          </div>
        </div>
      </section>

      <section className="contact-us-page">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="contain-area">
                <h1 className="sub-title">Get in Touch</h1>
                <p className="peregrap-style">
                  For event collaborations, inquiries, or any support, feel free
                  to reach out to us at:
                  <a
                    href="mailto:info@wellnas.app"
                    style={{ fontSize: "16px", color: "#616161" }}
                  >
                    info@wellnas.app
                  </a>{" "}
                  <br />
                  We look forward to hearing from you!
                </p>
                <div className="img-box">
                  <img
                    src="assets/images/contact-us-bg.png"
                    alt="contact-us"
                    height="510"
                    width="820"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="contact-form">
                <form onSubmit={contactUsDetail}>
                  <div className="input-box">
                    <label htmlFor="exampleInputName" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputName"
                      aria-describedby="emailHelp"
                      placeholder="Enter your Name"
                      value={name}
                      onChange={handleName}
                    />
                    {nameError && (
                      <div
                        className="error-msg"
                        style={{
                          color: "red",
                          fontSize: "14px",
                          marginLeft: "22px",
                        }}
                      >
                        {nameError}
                      </div>
                    )}
                  </div>
                  <div className="input-box">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Email
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Enter your Email"
                      value={emailId}
                      onChange={handleEmail}
                    />
                    {emailIdError && (
                      <div
                        className="error-msg"
                        style={{
                          color: "red",
                          fontSize: "14px",
                          marginLeft: "22px",
                        }}
                      >
                        {emailIdError}
                      </div>
                    )}
                  </div>
                  <div className="input-box">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Message
                    </label>
                    <textarea
                      className="form-control"
                      placeholder="Message"
                      id="floatingTextarea"
                      value={message}
                      onChange={handleMessage}
                    ></textarea>
                    {messageError && (
                      <div
                        className="error-msg"
                        style={{
                          color: "red",
                          fontSize: "14px",
                          marginLeft: "22px",
                        }}
                      >
                        {messageError}
                      </div>
                    )}
                  </div>
                  <button type="submit" className="main-btn w-100">
                    Submit
                  </button>
                  {successMessage && (
                    <div
                      className="success-msg"
                      style={{
                        color: "green",
                        fontSize: "14px",
                        marginLeft: "22px",
                      }}
                    >
                      {successMessage}
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="address-bord p-100">
        <div className="container">
          <div className="up-part">
            <div className="row g-3">
              <div className="col-lg-4 col-md-6">
                <div className="address-box">
                  <div className="img-box">
                    <img
                      src="assets/images/contact-address-one.png"
                      alt="cotact-detail"
                      height="85"
                      width="85"
                    />
                  </div>
                  <div className="content">
                    <p className="small-heading">Email</p>
                    <div className="main-value">
                      <a href="mailto:info@wellnas.app">info@wellnas.app</a>
                      {/* <a href="#">contact@wellnas.com</a> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-4 col-md-6">
                <div className="address-box">
                  <div className="img-box">
                    <img
                      src="assets/images/contact-address-two.png"
                      alt="cotact-detail"
                      height="85"
                      width="85"
                    />
                  </div>
                  <div className="content">
                    <p className="small-heading">Phone</p>
                    <div className="main-value">
                      {/* <a href="#">(255)-352-6255</a> */}
              {/* <a href="#">+1 9876543210</a>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-lg-4 col-md-6">
                <div className="address-box">
                  <div className="img-box">
                    <img
                      src="assets/images/contact-address-three.png"
                      alt="cotact-detail"
                      height="85"
                      width="85"
                    />
                  </div>
                  <div className="content">
                    <p className="small-heading">Address</p>
                    <div className="main-value">
                      <a href="#" className="address-line">
                        124, Divin st#1000San Francisco, CA 9420
                      </a>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          {/* <div className="office-box">
                  <div className="row">
                     <div className="col-md-5 m-auto">
                        <h2 className="sub-title">Visit our office!</h2>
                        <p className="peregrap-style">
                           Lorem ipsum dolor sit amet consectetur. Facilisis ac faucibus nam morbi elit at.
                           Mauris duis faucibus et nisl. Eu ut odio pellentesque proin. Aliquet pulvinar consequat nullam nisi tellus.
                        </p>
                        <a href="/" className="secondary-btn">Get Directions</a>
                     </div>
                     <div className="col-md-7">
                        <div className="iframe-box">
                           <div className="mapouter">
                              <div className="gmap_canvas">
                                 <iframe className="gmap_iframe" width="100%" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?width=600&amp;height=375&amp;hl=en&amp;q=University of Oxford&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div> */}
        </div>
      </section>
    </React.Fragment>
  );
};

export default Contact;
