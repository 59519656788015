import {
    JOIN_NOW_REQUEST,
    JOIN_NOW_SUCCESS,
    JOIN_NOW_FAILURE,

    UNDO_EVENT_REQUEST,
    UNDO_EVENT_SUCCESS,
    UNDO_EVENT_FAILURE,


} from "../constants/attendeesConstant";


export function joinNowReducer(state = {}, action) {
    switch (action.type) {
        case JOIN_NOW_REQUEST:
            return {
                loading: true,
            }
        case JOIN_NOW_SUCCESS:
            return {
                loading: false,
                joinNow: action.payload,
            }
        case JOIN_NOW_FAILURE:
            return {
                loading: false,
            }
        default:
            return state
    }
};

export function undoEventReducer(state = {}, action) {
    switch (action.type) {
        case UNDO_EVENT_REQUEST:
            return {
                loading: true,
            }
        case UNDO_EVENT_SUCCESS:
            return {
                loading: false,
                undoEvent: action.payload,
            }
        case UNDO_EVENT_FAILURE:
            return {
                loading: false,
            }
        default:
            return state
    }
};