import React from "react";
import { Link } from "react-router-dom";

const PageNotFound = () => {
   return (
      <div className="highlight-msg">
         <Link to={"/"} className="logo-box">
            <img src="/assets/images/logo.png" alt="" className="img-contain" />
         </Link>
         <div className="text">
            <img src="/assets/images/errorPage.jpg" alt="errorpic" style={{ width: "500px", alignItems: "center", marginTop: "12px" }} />
            <h1 className="error-color" style={{ fontSize: "20px" }}>Page Not Found :(</h1>
            <p style={{ fontSize: "15px", marginTop: "10px", color: " #32b6b5" }}>Oops! The requested URL was not found on this server.</p>
         </div>
      </div>
   )
}

export default PageNotFound;