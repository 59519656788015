export const MY_REVIEW_REQUEST = "MY_REVIEW_REQUEST"
export const MY_REVIEW_SUCCESS = "MY_REVIEW_SUCCESS"
export const MY_REVIEW_FAILURE = "MY_REVIEW_FAILURE"

export const ADD_NEW_REVIEW_REQUEST = "ADD_NEW_REVIEW_REQUEST"
export const ADD_NEW_REVIEW_SUCCESS = "ADD_NEW_REVIEW_SUCCESS"
export const ADD_NEW_REVIEW_FAILURE = "ADD_NEW_REVIEW_FAILURE"

export const HOST_EVENT_REVIEW_REQUEST = "HOST_EVENT_REVIEW_REQUEST"
export const HOST_EVENT_REVIEW_SUCCESS = "HOST_EVENT_REVIEW_SUCCESS"
export const HOST_EVENT_REVIEW_FAILURE = "HOST_EVENT_REVIEW_FAILURE"