import {
    TAG_LIST_REQUEST,
    TAG_LIST_SUCCESS,
    TAG_LIST_FAILURE,

} from "../constants/tagConstant";

import axios from "axios";


export const getTagList = (data) => async (dispatch) => {
    // console.log("values inside action....", values)
    try {
        dispatch({ type: TAG_LIST_REQUEST });
        const resData = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/tag/get_all_data`, data
        );
        dispatch({ type: TAG_LIST_SUCCESS, payload: resData.data.data });
    } catch (err) {
        dispatch({ type: TAG_LIST_FAILURE });
    }
};