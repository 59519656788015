import React from "react";
import { useDispatch, useSelector } from "react-redux";
import OwlCarousel from "react-owl-carousel";
import { Link, useNavigate } from "react-router-dom";
import { getCategoryList } from "../../store/action/categoryAction";

const FeatureCategory = () => {

   // const errorImg = "/assets/images/no_image.png"
   // const errorImg = "/assets/images/no-image-icon.png";
   const errorImg = "/assets/images/no-image.png";

   const dispatch = useDispatch();
   const navigate = useNavigate();
   const { categoryList } = useSelector((state) => state.category);

   // for handle events as per category
   const handleCategoryEvent = (category) => {
      navigate(`/categories/${category.vCategory.replace(/\s+/g, '-')}/events`, { state: { vCategory: category.vCategory } });
   };

   // for categories list
   React.useEffect(() => {
      if (!categoryList) {
         dispatch(getCategoryList({
            eFeature: "Yes",
            vCategory: ""
         }));
      }
   },
      [categoryList, dispatch]);

   const navText = [
      "<i class='fal fa-chevron-left'></i>",
      "<i class='fal fa-chevron-right'></i>",
   ];

   return (
      <React.Fragment>
         <section className="cate-box p-100 bg-grey">
            <div className="container">
               <div className="cate-slider">
                  <h2 className="sub-title">Categories</h2>
                  {categoryList && categoryList.length > 0 ? (
                     <OwlCarousel
                        className="owl-theme"
                        autoplay={false}
                        smartSpeed={800}
                        loop={false}
                        margin={25}
                        nav
                        dots={false}
                        navText={navText}
                        responsive={{
                           0: {
                              items: 1,
                              margin: 15,
                           },
                           420: {
                              items: 2,
                              margin: 15,
                           },
                           550: {
                              items: 2,
                              margin: 15,
                           },
                           767: {
                              items: 3,
                              margin: 15,
                           },
                           1024: {
                              items: 3,
                              margin: 15,
                           },
                           1199: {
                              items: 4,
                           },
                        }}
                     >
                        {categoryList.slice(0, 8).map((category, cateDel) => (
                           <div className="item" key={cateDel} onClick={() => handleCategoryEvent(category)} style={{ cursor: "pointer" }}>
                              <div className="image-box">
                                 <img
                                    src={category ? category.vWebpImage : errorImg}
                                    alt={category.vImageAlt}
                                    onError={({ currentTarget }) => {
                                       currentTarget.onerror = null;
                                       currentTarget.src = errorImg;
                                    }}
                                    width={300}
                                    height={330}
                                    style={{
                                       objectFit:
                                       category.vWebpImage.split("/").pop() === "null"
                                           ? "contain"
                                           : "cover",
                                     }}
                                 />
                              </div>
                              <div className="text">
                                 <h3>{category.vCategory}</h3>
                                 <p>{category.tDescription}</p>
                                 <a href="javascript:;">
                                    <i className="fal fa-chevron-right"></i>
                                 </a>
                              </div>
                           </div>
                        ))}
                     </OwlCarousel>
                  ) : (
                     <p>No Data Found</p>
                  )}

                  {categoryList && categoryList.length > 0 && (
                     <Link to={"/categories"} className="secondary-btn">
                        View All
                     </Link>
                  )}
               </div>
            </div>
         </section>
      </React.Fragment>

   );
}

export default FeatureCategory;














// -----Extra Data (Static) ----
/* <>
  <div className="item">
    <div className="image-box">
      <img src="assets/images/categories-two.png" alt="" />
      <h3>Sports & Fitness</h3>
    </div>
    <div className="text">
      <p>Lorem ipsum dolor sit amet consectetur. </p>
      <a href="javascript:;">
        <i class="fal fa-chevron-right"></i>
      </a>
    </div>
  </div>
  <div className="item">
    <div className="image-box">
      <img src="assets/images/categories-three.png" alt="" />
      <h3>Technology</h3>
    </div>
    <div className="text">
      <p>Lorem ipsum dolor sit amet consectetur. </p>
      <a href="javascript:;">
        <i class="fal fa-chevron-right"></i>
      </a>
    </div>
  </div>
  <div className="item">
    <div className="image-box">
      <img src="assets/images/categories-four.png" alt="" />
      <h3>Art & Culture</h3>
    </div>
    <div className="text">
      <p>Lorem ipsum dolor sit amet consectetur. </p>
      <a href="javascript:;">
        <i class="fal fa-chevron-right"></i>
      </a>
    </div>
  </div>
  <div className="item">
    <div className="image-box">
      <img src="assets/images/categories-one.png" alt="" />
      <h3>Travel & Outdoor</h3>
    </div>
    <div className="text">
      <p>Lorem ipsum dolor sit amet consectetur. </p>
      <a href="javascript:;">
        <i class="fal fa-chevron-right"></i>
      </a>
    </div>
  </div>
  <div className="item">
    <div className="image-box">
      <img src="assets/images/categories-two.png" alt="" />
      <h3>Travel & Outdoor</h3>
    </div>
    <div className="text">
      <p>Lorem ipsum dolor sit amet consectetur. </p>
      <a href="javascript:;">
        <i class="fal fa-chevron-right"></i>
      </a>
    </div>
  </div>
  <div className="item">
    <div className="image-box">
      <img src="assets/images/categories-three.png" alt="" />
      <h3>Travel & Outdoor</h3>
    </div>
    <div className="text">
      <p>Lorem ipsum dolor sit amet consectetur. </p>
      <a href="javascript:;">
        <i class="fal fa-chevron-right"></i>
      </a>
    </div>
  </div>
  <div className="item">
    <div className="image-box">
      <img src="assets/images/categories-four.png" alt="" />
      <h3>Travel & Outdoor</h3>
    </div>
    <div className="text">
      <p>Lorem ipsum dolor sit amet consectetur. </p>
      <a href="javascript:;">
        <i class="fal fa-chevron-right"></i>
      </a>
    </div>
  </div>
</> */
