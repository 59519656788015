import {
  EVENT_LIST_REQUEST,
  EVENT_LIST_SUCCESS,
  EVENT_LIST_FAILURE,
  EVENT_DETAIL_REQUEST,
  EVENT_DETAIL_SUCCESS,
  EVENT_DETAIL_FAILURE,
  RECOMMENDED_EVENT_REQUEST,
  RECOMMENDED_EVENT_SUCCESS,
  RECOMMENDED_EVENT_FAILURE,
  MY_EVENT_REQUEST,
  MY_EVENT_SUCCESS,
  MY_EVENT_FAILURE,
  ATTENDEES_EVENT_REQUEST,
  ATTENDEES_EVENT_SUCCESS,
  ATTENDEES_EVENT_FAILURE,
  ADD_NEW_EVENT_REQUEST,
  ADD_NEW_EVENT_SUCCESS,
  ADD_NEW_EVENT_FAILURE,
  ONE_TO_ONE_EVENT_REQUEST,
  ONE_TO_ONE_EVENT_SUCCESS,
  ONE_TO_ONE_EVENT_FAILURE,
  ALL_EVENT_REVIEW_REQUEST,
  ALL_EVENT_REVIEW_SUCCESS,
  ALL_EVENT_REVIEW_FAILURE,
  EVENT_TOP_REVIEW_REQUEST,
  EVENT_TOP_REVIEW_SUCCESS,
  EVENT_TOP_REVIEW_FAILURE,
  EDIT_EVENT_REQUEST,
  EDIT_EVENT_SUCCESS,
  EDIT_EVENT_FAILURE,
  STORE_NAME_RESET,

} from "../constants/eventConstant";

import axios from "axios";

export const getEventList = (data) => async (dispatch) => {
  try {
    dispatch({ type: EVENT_LIST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/event/get_all_data`,
      data
    );
    dispatch({ type: EVENT_LIST_SUCCESS, payload: resData.data.data });
  } catch (err) {
    dispatch({ type: EVENT_LIST_FAILURE });
  }
};

export const resetState = () => async (dispatch) => {
  dispatch({ type:STORE_NAME_RESET});
};

export const getEventDetail = (data) => async (dispatch) => {
  try {
    dispatch({ type: EVENT_DETAIL_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/event_details/get_by_id`,
      data
    );
    dispatch({ type: EVENT_DETAIL_SUCCESS, payload: resData.data.data });
  } catch (err) {
    dispatch({ type: EVENT_DETAIL_FAILURE });
  }
};

export const getRecommendedEvent = (data) => async (dispatch) => {
  try {
    dispatch({ type: RECOMMENDED_EVENT_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/event/recommended_event_data`,
      data
    );
    dispatch({ type: RECOMMENDED_EVENT_SUCCESS, payload: resData.data.data });
  } catch (err) {
    dispatch({ type: RECOMMENDED_EVENT_FAILURE });
  }
};

export const getMyEvent = (data) => async (dispatch) => {
  try {
    dispatch({ type: MY_EVENT_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/event/get_all_myevent`,
      data
    );
    dispatch({ type: MY_EVENT_SUCCESS, payload: resData.data.data });
  } catch (err) {
    dispatch({ type: MY_EVENT_FAILURE });
  }
};

export const getAttendeesEvent = (data) => async (dispatch) => {
  try {
    dispatch({ type: ATTENDEES_EVENT_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/event/get_all_attendees`,
      data
    );
    dispatch({ type: ATTENDEES_EVENT_SUCCESS, payload: resData.data.data });
  } catch (err) {
    dispatch({ type: ATTENDEES_EVENT_FAILURE });
  }
};

export const getAddNewEvent = (data) => async (dispatch) => {
  try {
    dispatch({ type: ADD_NEW_EVENT_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/event/add`,
      data
    );
    dispatch({ type: ADD_NEW_EVENT_SUCCESS, payload: resData.data.data });
  } catch (err) {
    dispatch({ type: ADD_NEW_EVENT_FAILURE });
  }
};

export const getOneToOneEvent = (data) => async (dispatch) => {
  try {
    dispatch({ type: ONE_TO_ONE_EVENT_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/event/get_one_to_one_data`,
      data
    );
    dispatch({ type: ONE_TO_ONE_EVENT_SUCCESS, payload: resData.data.data });
  } catch (err) {
    dispatch({ type: ONE_TO_ONE_EVENT_FAILURE });
  }
};

export const getAllEventReview = (data) => async (dispatch) => {
  try {
    dispatch({ type: ALL_EVENT_REVIEW_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/event/get_all_event_review`,
      data
    );
    dispatch({ type: ALL_EVENT_REVIEW_SUCCESS, payload: resData.data.data });
  } catch (err) {
    dispatch({ type: ALL_EVENT_REVIEW_FAILURE });
  }
};

export const getEventTopReview = (data) => async (dispatch) => {
  try {
    dispatch({ type: EVENT_TOP_REVIEW_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/event_review/get_by_id`,
      data
    );
    dispatch({ type: EVENT_TOP_REVIEW_SUCCESS, payload: resData.data.data });
  } catch (err) {
    dispatch({ type: EVENT_TOP_REVIEW_FAILURE });
  }
};

export const getEditEvent = (data) => async (dispatch) => {
  try {
    dispatch({ type: EDIT_EVENT_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/event/update`,
      data
    );
    dispatch({ type: EDIT_EVENT_SUCCESS, payload: resData.data.data });
  } catch (err) {
    dispatch({ type: EDIT_EVENT_FAILURE });
  }
};


