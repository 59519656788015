import {
   CATEGORY_LIST_REQUEST,
   CATEGORY_LIST_SUCCESS,
   CATEGORY_LIST_FAILURE,

} from "../constants/categoryConstant";

import axios from "axios";


export const getCategoryList = (data) => async (dispatch) => {
   // console.log("55555555555", `${process.env.REACT_APP_API_URL}`)
   try {
      dispatch({ type: CATEGORY_LIST_REQUEST });
      const resData = await axios.post(
         `${process.env.REACT_APP_API_URL}/api/category/get_all_data`, data
      );
      dispatch({ type: CATEGORY_LIST_SUCCESS, payload: resData.data.data });
   } catch (err) {
      dispatch({ type: CATEGORY_LIST_FAILURE });
   }
};
