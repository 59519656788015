import {
  SEARCH_LIST_REQUEST,
  SEARCH_LIST_SUCCESS,
  SEARCH_LIST_FAILURE,
} from "../constants/searchConstant";

import axios from "axios";

export const getSearchList = (data) => async (dispatch) => {
  try {
    dispatch({ type: SEARCH_LIST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/search`,
      data
    );
    // console.log(resData.data);
    // Assuming the response has a 'data' property containing the actual data
    const responseData = resData.data;


    dispatch({ type: SEARCH_LIST_SUCCESS, payload: responseData });
  } catch (err) {
    dispatch({ type: SEARCH_LIST_FAILURE });
  }
};
