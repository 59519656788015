import React from "react";
import { useLocation } from "react-router-dom";

const PrivacyPolicy = () => {
  const location = useLocation();

  React.useEffect(() => {
    document.title = "Privacy-policy | WellNas";
  }, [location]);

  return (
    <React.Fragment>
      <section
        className="inner-page-breadcamp"
        style={{ backgroundImage: "url(assets/images/breadcamp-image.png)" }}
      >
        <div className="container">
          <div className="brad-camp-detail">
            <p className="sub-title">Privacy Policy</p>
          </div>
        </div>
      </section>

      <section className="terms-text-page p-100">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-8 mx-auto"
              // style={{ margin: "0 auto", maxWidth: "900px", width: "100%" }}
            >
              <h1
                className="sub-title-term-condition mb-sm-5 mb-4"
                // style={{
                //   textAlign: "center",
                //   marginBottom: "20px",
                //   justifyContent: "center",
                //   alignItems: "center",
                //   marginTop: "40px",
                // }}
              >
                Privacy Policy
              </h1>
              <div className="terms-text-page">
                <ul>
                  <li>
                    <h2>Overview</h2>
                    <p>
                      Welcome to WellNas, a platform dedicated to connecting
                      people worldwide through wellness and shared experiences.
                      This Privacy Policy outlines how we collect, use, and
                      protect the personal information of our users.
                    </p>
                  </li>
                  <li>
                    <h2>Information We Collect</h2>
                    <p>
                      <ul>
                        <li>
                          {" "}
                          User Registration: When you sign up for WellNas, we
                          collect your name and email address to create and
                          manage your account.
                        </li>
                        <li>
                          {" "}
                          Verification Email: To enhance security, we use
                          cookies to send a verification email during the
                          registration process.{" "}
                        </li>
                        <li>
                          Event Information: If you participate in events on our
                          platform, we may collect information related to those
                          events to enhance your experience.
                        </li>
                      </ul>
                    </p>
                  </li>
                  <li>
                    <h2> How We Use Your Information</h2>
                    <p>
                      We use the collected information for the following
                      purposes:
                    </p>
                    <ul>
                      <li>
                        Facilitating user registration and account management.
                      </li>
                      <li>
                        Enhancing security through verification emails and
                        cookies.
                      </li>
                      <li>
                        Providing information related to events on WellNas.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <h2>Third-Party Links</h2>
                    <p>
                      WellNas does not utilize third-party services or links.
                      This Privacy Policy applies solely to information
                      collected on our platform.
                    </p>
                  </li>
                  <li>
                    <h2> Data Security</h2>
                    <p>
                      We prioritize the security of your data and employ
                      measures such as encryption and secure protocols to
                      safeguard your information.
                    </p>
                  </li>
                  <li>
                    <h2>User Choices</h2>
                    <p>
                      Users have the option to manage their preferences,
                      including opting out of certain communications. If you
                      have any specific preferences, please adjust them in your
                      account settings.
                    </p>
                  </li>
                  <li>
                    <h2> Changes to This Privacy Policy</h2>
                    <p>
                      We may update this Privacy Policy to reflect changes in
                      our practices. The effective date will indicate the most
                      recent revision.
                    </p>
                  </li>
                  <li>
                    <h2>Contact Us</h2>
                    <p>
                      If you have any questions or concerns about our Privacy
                      Policy, please contact us at{" "}
                      <a href="mailto:info@wellnas.app">[info@wellnas.app].</a>
                    </p>
                  </li>
                  <li>
                    <h2>Login Information</h2>
                    <p>
                      Upon user selection, we may offer the option to store
                      login information for convenience. This feature allows you
                      to stay logged in without entering credentials each time.
                      By choosing to use this feature, you acknowledge and agree
                      that your login information will be securely stored on our
                      servers.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default PrivacyPolicy;
