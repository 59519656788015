import React from "react";
import Index from './routes/Index';
import { store } from "./store/reducer";
import { Provider } from 'react-redux';

const App = () => {

  return (
    <div>
      <Provider store={store}>

        <Index />

      </Provider>
    </div>
  );
}

export default App;
