import {
    GROUP_LIST_REQUEST,
    GROUP_LIST_SUCCESS,
    GROUP_LIST_FAILURE,

    MY_GROUP_REQUEST,
    MY_GROUP_SUCCESS,
    MY_GROUP_FAILURE,

    ADD_NEW_GROUP_REQUEST,
    ADD_NEW_GROUP_SUCCESS,
    ADD_NEW_GROUP_FAILURE,

} from '../constants/groupConstant';

export function groupReducer(state = {}, action) {
    switch (action.type) {
        case GROUP_LIST_REQUEST:
            return {
                loading: true,
            }
        case GROUP_LIST_SUCCESS:
            return {
                loading: false,
                groupList: action.payload,
            }
        case GROUP_LIST_FAILURE:
            return {
                loading: false,
            }
        default:
            return state
    }
};

export function myGroupReducer(state = {}, action) {
    switch (action.type) {
        case MY_GROUP_REQUEST:
            return {
                loading: true,
            }
        case MY_GROUP_SUCCESS:
            return {
                loading: false,
                myGroup: action.payload,
            }
        case MY_GROUP_FAILURE:
            return {
                loading: false,
            }
        default:
            return state
    }
};

export function addNewGroupReducer(state = {}, action) {
    switch (action.type) {
        case ADD_NEW_GROUP_REQUEST:
            return {
                loading: true,
            }
        case ADD_NEW_GROUP_SUCCESS:
            return {
                loading: false,
                addNewGroup: action.payload,
            }
        case ADD_NEW_GROUP_FAILURE:
            return {
                loading: false,
            }
        default:
            return state
    }
};