import {
    MY_REVIEW_REQUEST,
    MY_REVIEW_SUCCESS,
    MY_REVIEW_FAILURE,

    ADD_NEW_REVIEW_REQUEST,
    ADD_NEW_REVIEW_SUCCESS,
    ADD_NEW_REVIEW_FAILURE,

    HOST_EVENT_REVIEW_REQUEST,
    HOST_EVENT_REVIEW_SUCCESS,
    HOST_EVENT_REVIEW_FAILURE,

} from '../constants/reviewConstant';

export function myReviewReducer(state = {}, action) {
    switch (action.type) {
        case MY_REVIEW_REQUEST:
            return {
                loading: true,
            }
        case MY_REVIEW_SUCCESS:
            return {
                loading: false,
                myReview: action.payload,
            }
        case MY_REVIEW_FAILURE:
            return {
                loading: false,
            }
        default:
            return state
    }
};

export function addNewReviewReducer(state = {}, action) {
    switch (action.type) {
        case ADD_NEW_REVIEW_REQUEST:
            return {
                loading: true,
            }
        case ADD_NEW_REVIEW_SUCCESS:
            return {
                loading: false,
                addNewReview: action.payload,
            }
        case ADD_NEW_REVIEW_FAILURE:
            return {
                loading: false,
            }
        default:
            return state
    }
};

export function hostEventReviewReducer(state = {}, action) {
    switch (action.type) {
        case HOST_EVENT_REVIEW_REQUEST:
            return {
                loading: true,
            }
        case HOST_EVENT_REVIEW_SUCCESS:
            return {
                loading: false,
                hostEventReview: action.payload,
            }
        case HOST_EVENT_REVIEW_FAILURE:
            return {
                loading: false,
            }
        default:
            return state
    }
};