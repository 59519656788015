import {
    CORE_VALUES_REQUEST,
    CORE_VALUES_SUCCESS,
    CORE_VALUES_FAILURE,

    HOW_IT_WORKS_REQUEST,
    HOW_IT_WORKS_SUCCESS,
    HOW_IT_WORKS_FAILURE,

    WHY_WELLNAS_REQUEST,
    WHY_WELLNAS_SUCCESS,
    WHY_WELLNAS_FAILURE,

    PAGE_LIST_JOIN_REQUEST,
    PAGE_LIST_JOIN_SUCCESS,
    PAGE_LIST_JOIN_FAILURE,

    PAGE_LIST_ABOUT_REQUEST,
    PAGE_LIST_ABOUT_SUCCESS,
    PAGE_LIST_ABOUT_FAILURE,

} from "../constants/aboutConstant";

export function coreValuesReducer(state = {}, action) {
    switch (action.type) {
        case CORE_VALUES_REQUEST:
            return {
                loading: true,
            }
        case CORE_VALUES_SUCCESS:
            return {
                loading: false,
                coreValues: action.payload,
            }
        case CORE_VALUES_FAILURE:
            return {
                loading: false,
            }
        default:
            return state
    }
};

export function howItWorksReducer(state = {}, action) {
    switch (action.type) {
        case HOW_IT_WORKS_REQUEST:
            return {
                loading: true,
            }
        case HOW_IT_WORKS_SUCCESS:
            return {
                loading: false,
                howItWorks: action.payload,
            }
        case HOW_IT_WORKS_FAILURE:
            return {
                loading: false,
            }
        default:
            return state
    }
};

export function whyWellNasReducer(state = {}, action) {
    switch (action.type) {
        case WHY_WELLNAS_REQUEST:
            return {
                loading: true,
            }
        case WHY_WELLNAS_SUCCESS:
            return {
                loading: false,
                whyWellNas: action.payload,
            }
        case WHY_WELLNAS_FAILURE:
            return {
                loading: false,
            }
        default:
            return state
    }
};

export function pageListJoinReducer(state = {}, action) {
    switch (action.type) {
        case PAGE_LIST_JOIN_REQUEST:
            return {
                loading: true,
            }
        case PAGE_LIST_JOIN_SUCCESS:
            return {
                loading: false,
                pageListJoin: action.payload,
            }
        case PAGE_LIST_JOIN_FAILURE:
            return {
                loading: false,
            }
        default:
            return state
    }
};

export function pageListAboutReducer(state = {}, action) {
    switch (action.type) {
        case PAGE_LIST_ABOUT_REQUEST:
            return {
                loading: true,
            }
        case PAGE_LIST_ABOUT_SUCCESS:
            return {
                loading: false,
                pageListAbout: action.payload,
            }
        case PAGE_LIST_ABOUT_FAILURE:
            return {
                loading: false,
            }
        default:
            return state
    }
};