export const ACTIVITY_LIST_REQUEST = 'ACTIVITY_LIST_REQUEST'
export const ACTIVITY_LIST_SUCCESS = 'ACTIVITY_LIST_SUCCESS'
export const ACTIVITY_LIST_FAILURE = 'ACTIVITY_LIST_FAILURE'

export const PREFERRED_ACTIVITY_LIST_REQUEST = 'PREFERRED_ACTIVITY_LIST_REQUEST'
export const PREFERRED_ACTIVITY_LIST_SUCCESS = 'PREFERRED_ACTIVITY_LIST_SUCCESS'
export const PREFERRED_ACTIVITY_LIST_FAILURE = 'PREFERRED_ACTIVITY_LIST_FAILURE'

export const ADD_PREFERRED_ACTIVITY_REQUEST = 'ADD_PREFERRED_ACTIVITY_REQUEST'
export const ADD_PREFERRED_ACTIVITY_SUCCESS = 'ADD_PREFERRED_ACTIVITY_SUCCESS'
export const ADD_PREFERRED_ACTIVITY_FAILURE = 'ADD_PREFERRED_ACTIVITY_FAILURE'