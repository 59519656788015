import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPageListJoin } from "../../store/action/aboutAction";
import { Link } from "react-router-dom";

const JoinNow = () => {
  const vUniqueCode = sessionStorage.getItem("vUniqueCode");

  // const errorImg = "/assets/images/no_image.png";
//   const errorImg = "/assets/images/no-image-icon.png";
  const errorImg = "/assets/images/no-image.png";

  const dispatch = useDispatch();
  const { pageListJoin } = useSelector((state) => state.pageListJoin);

  // for Join pageList
  React.useEffect(() => {
    if (!pageListJoin) {
      dispatch(
        getPageListJoin({
          eSection: "Join Us",
        })
      );
    }
  }, [pageListJoin, dispatch]);

  return (
    <React.Fragment>
      <section className="join-now-box mb-100">
        <div className="container">
          {pageListJoin && (
            <div className="image-box">
              <img
                src={pageListJoin ? pageListJoin.vWebpImage : errorImg}
                alt="join-us-banner"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = errorImg;
                }}
                width={1250}
                height={470}
                style={{
                  objectFit:
                    pageListJoin.vWebpImage.split("/").pop() === "null"
                      ? "contain"
                      : "cover",
                }}
              />
              <div className="text">
                <h2 className="sub-title">{pageListJoin.vTitle}</h2>
                <p className="sub-content">{pageListJoin.tDescription}</p>
                {/* {vUniqueCode ? null : ( */}
                  <Link to={"/login"} className="secondary-btn">
                    Join Today
                  </Link>
                {/* )} */}
                {/* <Link to={"/login"} className="secondary-btn">
                           Join Today
                        </Link> */}
              </div>
            </div>
          )}

          {/* <div className="image-box">
                  <img src="assets/images/join-now-banner.png" alt="" />
                  <div className="text">
                     <h2 className="sub-title">Join WellNas</h2>
                     <p className="sub-content">
                        Lorem ipsum dolor sit amet consectetur. Odio laoreet facilisis
                        non viverra posuere nunc amet varius. Maecenas tempor aliquam
                        sem aliquam ut morbi massa. Praesenvitae aliquam massa vel.
                     </p>
                     <a href="/" className="secondary-btn">
                        Join Today
                     </a>
                  </div>
               </div> */}
        </div>
      </section>
    </React.Fragment>
  );
};

export default JoinNow;
