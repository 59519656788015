import {
  CUSTOMER_REGISTER_REQUEST,
  CUSTOMER_REGISTER_SUCCESS,
  CUSTOMER_REGISTER_FAILURE,
  CUSTOMER_LOGIN_REQUEST,
  CUSTOMER_LOGIN_SUCCESS,
  CUSTOMER_LOGIN_FAILURE,
  FORGET_PASSWORD_REQUEST,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAILURE,
  VERIFY_EMAIL_REQUEST,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILURE,
  CUSTOMER_PROFILE_REQUEST,
  CUSTOMER_PROFILE_SUCCESS,
  CUSTOMER_PROFILE_FAILURE,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILURE,
  PROFILE_UPDATE_REQUEST,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_FAILURE,
  CUSTOMER_SERVICE_REQUEST,
  CUSTOMER_SERVICE_SUCCESS,
  CUSTOMER_SERVICE_FAILURE,
  UPDATE_CUSTOMER_SERVICE_REQUEST,
  UPDATE_CUSTOMER_SERVICE_SUCCESS,
  UPDATE_CUSTOMER_SERVICE_FAILURE,
  CUSTOMER_EVENT_REQUEST,
  CUSTOMER_EVENT_SUCCESS,
  CUSTOMER_EVENT_FAILURE,
  GOOGLE_REGISTER_REQUEST,
  GOOGLE_REGISTER_SUCCESS,
  GOOGLE_REGISTER_FAILURE,
  CUSTOMER_ACCOUNT_DELETE_REQUEST,
  CUSTOMER_ACCOUNT_DELETE_SUCCESS,
  CUSTOMER_ACCOUNT_DELETE_FAILURE,
} from "../constants/customerConstant";

export function customerRegisterReducer(state = {}, action) {
  switch (action.type) {
    case CUSTOMER_REGISTER_REQUEST:
      return {
        loading: true,
      };
    case CUSTOMER_REGISTER_SUCCESS:
      return {
        loading: false,
        customerRegister: action.payload,
      };
    case CUSTOMER_REGISTER_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}

export function customerLoginReducer(state = {}, action) {
  switch (action.type) {
    case CUSTOMER_LOGIN_REQUEST:
      return {
        loading: true,
      };
    case CUSTOMER_LOGIN_SUCCESS:
      return {
        loading: false,
        customerLogin: action.payload,
      };
    case CUSTOMER_LOGIN_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}

export function customerForgetPassword(state = {}, action) {
  switch (action.type) {
    case FORGET_PASSWORD_REQUEST:
      return {
        loading: true,
      };
    case FORGET_PASSWORD_SUCCESS:
      return {
        loading: false,
        forgetPassword: action.payload,
      };
    case FORGET_PASSWORD_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}

export function customerEmailVerify(state = {}, action) {
  switch (action.type) {
    case VERIFY_EMAIL_REQUEST:
      return {
        loading: true,
      };
    case VERIFY_EMAIL_SUCCESS:
      return {
        loading: false,
        emailVerify: action.payload,
      };
    case VERIFY_EMAIL_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}

export function customerProfileReducer(state = {}, action) {
  switch (action.type) {
    case CUSTOMER_PROFILE_REQUEST:
      return {
        loading: true,
      };
    case CUSTOMER_PROFILE_SUCCESS:
      return {
        loading: false,
        customerProfile: action.payload,
      };
    case CUSTOMER_PROFILE_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}

export function updatePasswordReducer(state = {}, action) {
  switch (action.type) {
    case UPDATE_PASSWORD_REQUEST:
      return {
        loading: true,
      };
    case UPDATE_PASSWORD_SUCCESS:
      return {
        loading: false,
        updatePassword: action.payload,
      };
    case UPDATE_PASSWORD_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}

export function updateProfileReducer(state = {}, action) {
  switch (action.type) {
    case PROFILE_UPDATE_REQUEST:
      return {
        loading: true,
      };
    case PROFILE_UPDATE_SUCCESS:
      return {
        loading: false,
        updateProfile: action.payload,
      };
    case PROFILE_UPDATE_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}

export function customerServiceReducer(state = {}, action) {
  switch (action.type) {
    case CUSTOMER_SERVICE_REQUEST:
      return {
        loading: true,
      };
    case CUSTOMER_SERVICE_SUCCESS:
      return {
        loading: false,
        customerService: action.payload,
      };
    case CUSTOMER_SERVICE_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}

export function updateCustomerServiceReducer(state = {}, action) {
  switch (action.type) {
    case UPDATE_CUSTOMER_SERVICE_REQUEST:
      return {
        loading: true,
      };
    case UPDATE_CUSTOMER_SERVICE_SUCCESS:
      return {
        loading: false,
        updateCustomerService: action.payload,
      };
    case UPDATE_CUSTOMER_SERVICE_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}

export function customerEventReducer(state = {}, action) {
  switch (action.type) {
    case CUSTOMER_EVENT_REQUEST:
      return {
        loading: true,
      };
    case CUSTOMER_EVENT_SUCCESS:
      return {
        loading: false,
        customerEvent: action.payload,
      };
    case CUSTOMER_EVENT_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}

export function googleRegisterReducer(state = {}, action) {
  switch (action.type) {
    case GOOGLE_REGISTER_REQUEST:
      return {
        loading: true,
      };
    case GOOGLE_REGISTER_SUCCESS:
      return {
        loading: false,
        googleRegister: action.payload,
      };
    case GOOGLE_REGISTER_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}

export function customerAccountDeleteReducer(state = {}, action) {
  switch (action.type) {
    case CUSTOMER_ACCOUNT_DELETE_REQUEST:
      return {
        loading: true,
      };
    case CUSTOMER_ACCOUNT_DELETE_SUCCESS:
      return {
        loading: false,
        customerAccountDeleteData: action.payload,
      };
    case CUSTOMER_ACCOUNT_DELETE_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}
