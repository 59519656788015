import React from "react";
import Container from "../components/layouts/Container";
import SearchEvents from "../components/Search/SearchEvents";


const SearchPage = () => {

   return (
      <React.Fragment>
         <div className="dark-header">
            <Container>

               <SearchEvents />

            </Container>
         </div>
      </React.Fragment>
   );
}

export default SearchPage;