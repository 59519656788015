import React from "react";
import Setting from "../components/Setting/Setting";
import Container from "../components/layouts/Container";
import { useNavigate } from "react-router";

const SettingPage = () => {
  const navigate = useNavigate();
  const isAuthenticated =
  //  sessionStorage.getItem("vUniqueCode") ||  
  localStorage.getItem("vUniqueCode");

  React.useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated,navigate]);
  return (
    <Container>
      <div className="profile-layer">
        <Setting />
      </div>
    </Container>
  );
};

export default SettingPage;
