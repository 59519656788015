import React, { useRef } from "react";
import axios from "axios";
import ProfileMenu from "../Profile/ProfileMenu";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { getCategoryList } from "../../store/action/categoryAction";
import { getActivityList } from "../../store/action/activityAction";
import { getTagList } from "../../store/action/tagAction";
import { Toast } from "primereact/toast";

const loadScript = (url, callback) => {
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.src = url;

  script.onload = callback;

  document.head.appendChild(script);
};

const AddEvent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedLocation, setSelectedLocation] = React.useState(null);
  const [placeId, setPlaceId] = React.useState("");
  const [placeName, setPlaceName] = React.useState("");
  const [countryName, setCountryName] = React.useState("");
  const [countryId, setCountryId] = React.useState("");
  const [cityName, setCityName] = React.useState("");
  const [latitude, setLatitude] = React.useState("");
  const [longitude, setLongitude] = React.useState("");
  const [marker, setMarker] = React.useState(null);
  const [locationData, setLocationData] = React.useState(null);
  const toast = React.useRef(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const autocompleteRef = useRef();
  const mapRef = useRef();
  const autocomplete = useRef(null);
  const map = useRef(null);

  const { categoryList } = useSelector((state) => state.category);
  const { activityList } = useSelector((state) => state.activityList);
  const { tagList } = useSelector((state) => state.tagList);

  // variables for add new event
  const [categoryId, setCategoryId] = React.useState("");

  // const [groupId, setGroupId] = React.useState("");
  const [activityId, setActivityId] = React.useState("");
  const [event, setEvent] = React.useState("");
  const [limit, setLimit] = React.useState("");
  const [type, setType] = React.useState("");
  const [amount, setAmount] = React.useState("");

  // const [eventPurpose, setEventPurpose] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [eventDate, setEventDate] = React.useState("");
  const [selectedTags, setSelectedTags] = React.useState([]);
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [imageFileName, setImageFileName] = React.useState("");
  const [profilePictureUrl, setProfilePictureUrl] = React.useState("");

  // variables for success & error msg
  const [addEventSuccess, setAddEventSuccess] = React.useState(false);
  const [categoryIdError, setCategoryIdError] = React.useState("");
  const [activityIdError, setActivityIdError] = React.useState("");
  const [eventError, setEventError] = React.useState("");
  const [limitError, setLimitError] = React.useState("");

  const [typeError, setTypeError] = React.useState("");
  const [amountError, setAmountError] = React.useState("");

  // const [eventPurposeError, setEventPurposeError] = React.useState("");
  const [descriptionError, setDescriptionError] = React.useState("");
  const [locationError, setLocationError] = React.useState("");
  const [eventDateError, setEventDateError] = React.useState("");
  const [imageError, setImageError] = React.useState("");

  // handle functions
  const handleTagId = (selectedOptions) => {
    setSelectedTags(selectedOptions);
  };
  const handleCategoryChange = (event) => {
    setCategoryId(event.target.value);
    setCategoryIdError("");
  };
  const handleActivityChange = (event) => {
    setActivityId(event.target.value);
    setActivityIdError("");
  };
  const handleEventChange = (event) => {
    setEvent(event.target.value);
    setEventError("");
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setLimitError("");
  };
  // const handleLocationChange = (event) => {
  //   setLocation(event.target.value);
  //   setLocationError("");
  // };
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
    setDescriptionError("");
  };
  const handleTypeChange = (event) => {
    setType(event.target.value);
    setTypeError("");
  };

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
    setAmountError("");
  };

  // const handleEventPurposeChange = (event) => {
  //   setEventPurpose(event.target.value);
  //   setEventPurposeError("");
  // };
  const handleEventDateChange = (event) => {
    setEventDate(event.target.value);
    setEventDateError("");
  };
  const handleImage = (event) => {
    handleImageChange(event);
    setImageError("");
  };

  // handle image selection
  const allowedImageTypes = React.useMemo(
    () => ["image/jpeg", "image/png", "image/jpg"],
    []
  );
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (allowedImageTypes.includes(file.type)) {
        setSelectedImage(file);
        setImageFileName(file.name);
        const reader = new FileReader();
        reader.onload = (e) => {
          setProfilePictureUrl(e.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert(
          "Unsupported file type. Please select a .jpg, .jpeg, or .png file."
        );
        e.target.value = null;
      }
    }
  };

  // validation for add newEvent
  const validateEvent = React.useCallback(() => {
    let isValid = true;

    // Reset error messages
    setCategoryIdError("");
    setActivityIdError("");
    setEventError("");
    setLimitError("");
    setLocationError("");
    setDescriptionError("");
    setTypeError("");
    setAmountError("");
    // setEventPurposeError("");
    setEventDateError("");
    setImageError("");

    if (!categoryId) {
      setCategoryIdError("Category is required.");
      isValid = false;
    }
    if (!activityId) {
      setActivityIdError("Activity is required.");
      isValid = false;
    }
    if (event.length === 0) {
      setEventError("Event Name is required.");
      isValid = false;
    }
    if (limit.length === 0) {
      setLimitError("Limit is required.");
      isValid = false;
    }
    if (location.length === 0) {
      setLocationError("Location is required.");
      isValid = false;
    }
    if (description.length === 0) {
      setDescriptionError("Description is required.");
      isValid = false;
    }
    if (description.length > 1000) {
      setDescriptionError("Maximum limit is 1000 characters.");
      isValid = false;
    }
    if (!type) {
      setTypeError("Type is required.");
      isValid = false;
    }
    if (type === "Premium") {
      if (amount.length === 0) {
        setAmountError("Amount is required.");
        isValid = false;
      }
    }

    // if (!eventPurpose) {
    //   setEventPurposeError("Event purpose is required.");
    //   isValid = false;
    // }
    if (!eventDate) {
      setEventDateError("Date is required.");
      isValid = false;
    }
    if (!selectedImage) {
      setImageError("Image is required.");
      isValid = false;
    }
    // if (selectedImage && !allowedImageTypes.includes(selectedImage.type)) {
    //    setImageError("Unsupported file type. Please select a .jpg, .jpeg, or .png file.");
    //    isValid = false;
    // } else {
    //    setImageError("");
    // }

    return isValid;
  }, [
    categoryId,
    activityId,
    type,
    amount,
    location,
    event,
    limit,
    eventDate,
    // eventPurpose,
    description,
    selectedImage,
  ]);

  // for CategoryList
  React.useEffect(() => {
    if (!categoryList) {
      dispatch(
        getCategoryList({
          eFeature: "Yes",
          vCategory: "",
        })
      );
    }
  }, [categoryList, dispatch]);

  // for Activity List
  React.useEffect(() => {
    if (categoryId !== "") {
      dispatch(
        getActivityList({
          eFeature: "Yes",
          iCategoryId: categoryId,
        })
      );
    }
  }, [categoryId, dispatch]);

  // for Tag List
  React.useEffect(() => {
    if (!tagList) {
      dispatch(
        getTagList({
          eFeature: "",
        })
      );
    }
  }, [tagList, dispatch]);

  // for add New Event
  const addNewEvent = React.useCallback(
    async (e) => {
      e.preventDefault();
      const vUniqueCode =
        // sessionStorage.getItem("vUniqueCode") ||
        localStorage.getItem("vUniqueCode");

      const isValid = validateEvent();

      if (isValid && locationData) {
        const tagId = selectedTags.map((tag) => tag.value);

        const formData = new FormData();
        formData.append("vUniqueCode", vUniqueCode);
        // formData.append("iGroupId", groupId);
        formData.append("iCategoryId", categoryId);
        formData.append("iActivityId", activityId);
        formData.append("vEvent", event);
        formData.append("vLimit", limit);
        formData.append("eType", type);
        formData.append("vAmount", amount);

        formData.append("eEventPurpose", "Normal");
        formData.append("tDescription", description);
        formData.append("vLocation", location);
        // for (let i = 0; i < tagId?.length; i++) {
        //    formData.append("iTagId", tagId[i]);
        // }
        formData.append("iTagId", JSON.stringify(tagId));
        formData.append("dtEvent", eventDate);
        formData.append("vImage", selectedImage);

        if (
          locationData &&
          locationData.results &&
          locationData.results.length > 0 &&
          locationData.results[0].geometry &&
          locationData.results[0].geometry.location
        ) {
          const firstResult = locationData.results[0];
          const addressComponents = firstResult.address_components;

          //  if (
          //    locationData.address_components &&
          //    Array.isArray(locationData.address_components)
          //  ) {
          //    // Log all components for inspection

          // console.log("All Address Components:", addressComponents);

          // Extract city and country from the address components
          const cityComponent = addressComponents.find((component) =>
            component.types.includes("locality")
          );
          const countryComponent = addressComponents.find((component) =>
            component.types.includes("country")
          );

          if (cityComponent && countryComponent) {
            formData.append("vCity", cityComponent.long_name || "");
            formData.append("vCountry", countryComponent.long_name || "");
          } else {
            // console.error("City or Country component is undefined");
          }

          formData.append("vPlaceId", firstResult.place_id);
          formData.append("vLatitude", firstResult.geometry.location.lat);
          formData.append("vLongitude", firstResult.geometry.location.lng);

          if (
            // groupId.length > 0 ||
            categoryId.length > 0 ||
            activityId.length > 0 ||
            event.length > 0 ||
            limit.length > 0 ||
            type.length > 0 ||
            // eventPurpose.length > 0 ||
            description.length > 0 ||
            location.length > 0 ||
            cityName.length > 0 ||
            countryName.length > 0 ||
            placeId.length > 0 ||
            latitude.length > 0 ||
            longitude.length > 0 ||
            tagId.length > 0 ||
            eventDate.length > 0 ||
            selectedImage
          ) {
            try {
              const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/event/add`,
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              );

              // console.log("response------------------------->", response);
              setIsLoading(true);
              if (response.status === 200) {
                setAddEventSuccess(true);

                setSelectedTags([]);

                setCategoryId("");
                setActivityId("");
                setEvent("");
                setLimit("");
                setType("");
                setAmount("");
                // setEventPurpose("");
                setDescription("");
                setLocation("");
                setEventDate("");
                setSelectedImage(null);
                setImageFileName("");
                setProfilePictureUrl("");
                setCityName("");
                setCountryName("");
                setPlaceId("");
                setLatitude("");
                setLongitude("");
                toast.current.show({
                  severity: "success",
                  summary: "Your Event Has Been Sent For Review.",
                  life: 2000,
                });
                setTimeout(() => {
                  setIsLoading(false);
                  setAddEventSuccess(false);
                  navigate("/my-event");
                  window.location.reload();
                }, 3000);
              }
            } catch (error) {}
          }
          //  } else {
          //    console.error("locationData.address_components is undefined");
          //  }
        } else {
          // console.error(
          //   "locationData is undefined or has unexpected structure"
          // );
        }
      }
    },
    [
      categoryId,
      activityId,
      event,
      limit,
      type,
      amount,
      // eventPurpose,
      location,
      description,
      selectedImage,
      eventDate,
      validateEvent,
      setSelectedTags,
      selectedTags,
      navigate,
      cityName,
      countryName,
      placeId,
      latitude,
      longitude,
      setIsLoading,
    ]
  );

  //google map and google place api //

  React.useEffect(() => {
    const handlePlaceSelect = () => {
      const addressObject = autocomplete.current.getPlace();

      if (addressObject && addressObject.formatted_address) {
        const latlng = {
          lat: addressObject?.geometry?.location?.lat(),
          lng: addressObject?.geometry?.location?.lng(),
        };

        setLocation(addressObject.formatted_address);

        setMarker((prevMarker) => {
          if (prevMarker) {
            prevMarker.setMap(null);
          }
          const newMarker = new window.google.maps.Marker({
            position: latlng,
            map: map.current,
          });
          return newMarker;
        });

        setSelectedLocation(latlng);
        setPlaceId(addressObject.place_id || "");
        setPlaceName(addressObject.name || "");

        setCountryName(
          addressObject.address_components.find((component) =>
            component.types.includes("country")
          )?.long_name || ""
        );
        setCountryId(
          addressObject.address_components.find((component) =>
            component.types.includes("country")
          )?.short_name || ""
        );

        // Extract city from address components
        const city =
          addressObject.address_components.find((component) =>
            component.types.includes("locality")
          )?.long_name || "";

        setCityName(city);

        setLatitude(latlng.lat);
        setLongitude(latlng.lng);

        map.current.setCenter(latlng);
        map.current.setZoom(13);

        fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latlng.lat},${latlng.lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`
        )
          .then((response) => response.json())
          .then((data) => {
            // console.log("Reverse Geocoding API response:", data);
            setLocationData(data);
          })
          .catch((error) => {
            // console.error("Error:", error);
          });
      } else {
        // console.error("Invalid address object:", addressObject);
      }
    };

    const handleScriptLoad = () => {
      if (window.google && window.google.maps && window.google.maps.places) {
        autocomplete.current = new window.google.maps.places.Autocomplete(
          autocompleteRef.current,
          {}
        );
        autocomplete.current.addListener("place_changed", handlePlaceSelect);

        map.current = new window.google.maps.Map(mapRef.current, {
          center: { lat: 0, lng: 0 },
          zoom: 2,
        });
      } else {
        // console.error("Google Maps API not loaded properly");
      }
    };

    // Load Google Maps script
    const loadGoogleMapsScript = () => {
      if (!window.google || !window.google.maps || !window.google.maps.places) {
        loadScript(
          `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`,
          () => handleScriptLoad()
        );
      } else {
        handleScriptLoad();
      }
    };

    // Initialize with the first script load
    loadGoogleMapsScript();
  }, []);

  return (
    <React.Fragment>
      <Toast ref={toast} />
      <div className="profile-layer">
        <div className="profile-page">
          <div className="container-fluid">
            <div className="row g-4">
              <div className="col-lg-2 col-md-3">
                <ProfileMenu />
              </div>

              <div className="col-lg-7 col-md-9 col-12">
                <div className="add-event-form white-card attend-event-page">
                  <div className="title-top">
                    <h1>Add New Event</h1>
                  </div>
                  <form onSubmit={addNewEvent}>
                    <div className="row g-xl-4 g-3">
                      <div className="col-12">
                        <div className="form-group">
                          <label>Category</label>
                          <select
                            className="form-select form-control"
                            aria-label="Default select example"
                            id="exampleType"
                            value={categoryId}
                            onChange={handleCategoryChange}
                          >
                            <option value="Select category">
                              Select category
                            </option>
                            {categoryList &&
                              categoryList.map((categoryType) => (
                                <option
                                  key={categoryType.iCategoryId}
                                  value={categoryType.iCategoryId}
                                >
                                  {categoryType.vCategory}
                                </option>
                              ))}
                          </select>
                        </div>
                        {categoryIdError && (
                          <div
                            className="error-msg"
                            style={{
                              color: "red",
                              fontSize: "14px",
                              marginLeft: "20px",
                            }}
                          >
                            {categoryIdError}
                          </div>
                        )}
                      </div>

                      {/* select activity */}
                      <div className="col-12">
                        <div className="form-group">
                          <label>Activity</label>
                          <select
                            className="form-select form-control"
                            aria-label="Default select example"
                            id="exampleType"
                            value={activityId}
                            onChange={handleActivityChange}
                          >
                            <option value="Select activity">
                              Select activity
                            </option>
                            {activityList &&
                              activityList.map((activityType) => (
                                <option
                                  key={activityType.iActivityId}
                                  value={activityType.iActivityId}
                                >
                                  {activityType.vActivity}
                                </option>
                              ))}
                          </select>
                        </div>
                        {activityIdError && (
                          <div
                            className="error-msg"
                            style={{
                              color: "red",
                              fontSize: "14px",
                              marginLeft: "20px",
                            }}
                          >
                            {activityIdError}
                          </div>
                        )}
                      </div>

                      <div className="col-12">
                        <div className="form-group">
                          <label>Event</label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputFirstName"
                            placeholder="Enter Event"
                            value={event}
                            onChange={handleEventChange}
                          />
                        </div>
                        {eventError && (
                          <div
                            className="error-msg"
                            style={{
                              color: "red",
                              fontSize: "14px",
                              marginLeft: "20px",
                            }}
                          >
                            {eventError}
                          </div>
                        )}
                      </div>

                      <div className="col-12">
                        <div className="form-group">
                          <label>Location</label>
                          <input
                            ref={autocompleteRef}
                            className="form-control"
                            onChange={(event) => {
                              setLocation(event.target.value);
                            }}
                            placeholder={"Search Places.."}
                            value={location}
                          />

                          <div
                            ref={mapRef}
                            className="gmap_iframe"
                            style={{
                              width: "100%",
                              height: "370px",
                              marginTop: "20px",
                            }}
                          ></div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group">
                          <label>Description</label>
                          <textarea
                            name="description"
                            className="form-control"
                            placeholder="Enter Description"
                            value={description}
                            onChange={handleDescriptionChange}
                          ></textarea>
                        </div>
                        {descriptionError && (
                          <div
                            className="error-msg"
                            style={{
                              color: "red",
                              fontSize: "14px",
                              marginLeft: "20px",
                            }}
                          >
                            {descriptionError}
                          </div>
                        )}
                      </div>

                      <div
                        className={
                          type === "Premium" ? "col-md-6 col-12" : "col-12"
                        }
                      >
                        <div className="form-group">
                          <label>Type</label>
                          <select
                            className="form-select form-control"
                            aria-label="Default select example"
                            id="exampleType"
                            value={type}
                            onChange={handleTypeChange}
                          >
                            <option value="">Select Type</option>
                            <option value="Public">Public</option>

                            <option value="Premium">Premium</option>
                          </select>
                        </div>
                        {typeError && (
                          <div
                            className="error-msg"
                            style={{
                              color: "red",
                              fontSize: "14px",
                              marginLeft: "20px",
                            }}
                          >
                            {typeError}
                          </div>
                        )}
                      </div>

                      {type === "Premium" ? (
                        <div className="col-md-6 col-12">
                          <div className="form-group">
                            <label>Amount</label>
                            <input
                              type="text"
                              className="form-control"
                              id="exampleInputFirstName"
                              placeholder="Enter Amount"
                              value={amount}
                              onChange={handleAmountChange}
                            />
                          </div>
                          {amountError && (
                            <div
                              className="error-msg"
                              style={{
                                color: "red",
                                fontSize: "14px",
                                marginLeft: "20px",
                              }}
                            >
                              {amountError}
                            </div>
                          )}
                        </div>
                      ) : null}

                      {/* enter limit */}

                      <div className="col-12">
                        <div className="form-group">
                          <label>Number of Participant</label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputFirstName"
                            placeholder=" Enter Number of Participant"
                            value={limit}
                            onChange={handleLimitChange}
                          />
                        </div>
                        {limitError && (
                          <div
                            className="error-msg"
                            style={{
                              color: "red",
                              fontSize: "14px",
                              marginLeft: "20px",
                            }}
                          >
                            {limitError}
                          </div>
                        )}
                      </div>
{/* 
                      <div className="col-12">
                        <div className="form-group">
                          <label>Event Purpose</label>
                          <select
                            className="form-select form-control"
                            aria-label="Default select example"
                            id="exampleType"
                            value={eventPurpose}
                            onChange={handleEventPurposeChange}
                          >
                            <option value="">Select Event</option>
                            <option value="Normal">Normal</option>
                            <option value="OneToOne">OneToOne</option>
                          </select>
                        </div>
                        {eventPurposeError && (
                          <div
                            className="error-msg"
                            style={{
                              color: "red",
                              fontSize: "14px",
                              marginLeft: "20px",
                            }}
                          >
                            {eventPurposeError}
                          </div>
                        )}
                      </div> */}


                      {/* <div className="col-12">
                        <div className="form-group">
                        <label>Event Purpose</label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleType"
                            placeholder="Enter Event Purpose"
                            value={eventPurpose}
                            onChange={handleEventPurposeChange}
                          />
                        </div>
                        {eventPurposeError && (
                          <div
                            className="error-msg"
                            style={{
                              color: "red",
                              fontSize: "14px",
                              marginLeft: "20px",
                            }}
                          >
                            {eventPurposeError}
                          </div>
                        )}
                      </div> */}

                      <div className="col-md-6 col-12">
                        <div className="form-group">
                          <label>Event Date</label>
                          <input
                            type="date"
                            className="form-control"
                            id="exampleInputFirstName"
                            placeholder="Enter Date"
                            value={eventDate}
                            onChange={handleEventDateChange}
                            min={new Date().toISOString().split("T")[0]}
                          />
                        </div>
                        {eventDateError && (
                          <div
                            className="error-msg"
                            style={{
                              color: "red",
                              fontSize: "14px",
                              marginLeft: "20px",
                            }}
                          >
                            {eventDateError}
                          </div>
                        )}
                      </div>

                      <div className="col-md-6 col-12">
                        <div className="form-group">
                          <label>Tag List</label>
                          <Select
                            isMulti
                            value={selectedTags}
                            onChange={handleTagId}
                            className="form-control multi-select-control"
                            options={
                              tagList &&
                              tagList.map((tagType) => ({
                                value: tagType.iTagId,
                                label: tagType.vTag,
                              }))
                            }
                          />
                        </div>
                      </div>

                      <div className="col-md-6 col-12">
                        <div className="form-group">
                          <label>Image</label>
                          <div className="input-image-box">
                            <input
                              className="form-control"
                              type="file"
                              onChange={handleImage}
                              accept=".jpg, .jpeg, .png"
                            />
                            <p>Upload Image</p>
                          </div>
                          {imageError && (
                            <div
                              className="error-msg"
                              style={{
                                color: "red",
                                fontSize: "14px",
                                marginLeft: "20px",
                              }}
                            >
                              {imageError}
                            </div>
                          )}
                          {imageFileName && (
                            <p
                              style={{
                                fontSize: "13px",
                                marginLeft: "16px",
                                color: "#1e90ff",
                              }}
                            >
                              Selected image: {imageFileName}
                            </p>
                          )}
                          {profilePictureUrl && (
                            <div className="image-preview">
                              <img
                                src={profilePictureUrl}
                                alt="event img"
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "80px",
                                  marginLeft: "15px",
                                  borderRadius: "15px 15px 0px 15px",
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-12">
                        {isLoading ? (
                          <div class="text-center logo-container animate__animated animate__fadeIn">
                            <div className="ft-logo">
                              <img
                                src="/assets/images/logo.png"
                                alt="wellnes"
                                height={105}
                                width={130}
                              />
                            </div>
                            <h5
                              style={{
                                fontSize: "15px",
                                color: "#fd4700",
                                marginTop: "10px",
                                textAlign: "center",
                              }}
                            >
                              Submitting .....
                            </h5>
                          </div>
                        ) : (
                          <button type="submit" className="main-btn">
                            Submit
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddEvent;
