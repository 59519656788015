import {
    RECENT_NOTIFICATION_REQUEST,
    RECENT_NOTIFICATION_SUCCESS,
    RECENT_NOTIFICATION_FAILURE,

    VIEW_NOTIFICATION_REQUEST,
    VIEW_NOTIFICATION_SUCCESS,
    VIEW_NOTIFICATION_FAILURE,

    ADD_NOTIFICATION_REQUEST,
    ADD_NOTIFICATION_SUCCESS,
    ADD_NOTIFICATION_FAILURE,

} from "../constants/notificationConstant";


export function recentNotificationReducer(state = {}, action) {
    switch (action.type) {
        case RECENT_NOTIFICATION_REQUEST:
            return {
                loading: true,
            }
        case RECENT_NOTIFICATION_SUCCESS:
            return {
                loading: false,
                recentNotification: action.payload,
            }
        case RECENT_NOTIFICATION_FAILURE:
            return {
                loading: false,
            }
        default:
            return state
    }
};

export function viewNotificationReducer(state = {}, action) {
    switch (action.type) {
        case VIEW_NOTIFICATION_REQUEST:
            return {
                loading: true,
            }
        case VIEW_NOTIFICATION_SUCCESS:
            return {
                loading: false,
                viewNotification: action.payload,
            }
        case VIEW_NOTIFICATION_FAILURE:
            return {
                loading: false,
            }
        default:
            return state
    }
};

export function addNotificationReducer(state = {}, action) {
    switch (action.type) {
        case ADD_NOTIFICATION_REQUEST:
            return {
                loading: true,
            }
        case ADD_NOTIFICATION_SUCCESS:
            return {
                loading: false,
                addNotification: action.payload,
            }
        case ADD_NOTIFICATION_FAILURE:
            return {
                loading: false,
            }
        default:
            return state
    }
};