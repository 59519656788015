import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";

// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import { getTestimonialList } from "../../store/action/testimonialAction";

const Testimonial = () => {
  // const errorImg = "/assets/images/no_image.png";
//   const errorImg = "/assets/images/no-image-icon.png";
  const errorImg = "/assets/images/no-image.png";

  const dispatch = useDispatch();
  const [thumbsSwiper, setThumbsSwiper] = React.useState(null);
  const { testimonialList } = useSelector((state) => state.testimonial);

  // for testimonial list
  React.useEffect(() => {
    if (!testimonialList) {
      dispatch(
        getTestimonialList({
          eFeature: "yes",
        })
      );
    }
  }, [testimonialList, dispatch]);

  return (
    <React.Fragment>
      <section className="testimonial-slider p-100 bg-grey text-center">
        <div className="container">
          <h2 className="sub-title">Testimonial</h2>
          <div className="slider-box">
            {testimonialList && testimonialList.length > 0 ? (
              <Swiper
                spaceBetween={10}
                navigation={true}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper-quote"
              >
                {testimonialList
                  .slice(0, 7)
                  .map((testDescription, description) => (
                    <SwiperSlide key={description}>
                      <div className="text-quote">
                        <p>{testDescription.tDescription}</p>
                      </div>
                    </SwiperSlide>
                  ))}
              </Swiper>
            ) : (
              <div className="no-data-found">
                <p>No Data Found</p>
              </div>
            )}

            {testimonialList && testimonialList.length > 0 ? (
              <Swiper
                onSwiper={setThumbsSwiper}
                spaceBetween={10}
                slidesPerView={7}
                freeMode={false}
                watchSlidesProgress={true}
                className="mySwiper-user"
                modules={[FreeMode, Navigation, Thumbs]}
                breakpoints={{
                  0: {
                    slidesPerView: 2,
                  },
                  420: {
                    slidesPerView: 3,
                  },
                  575: {
                    slidesPerView: 3,
                  },
                  767: {
                    slidesPerView: 4,
                  },
                  991: {
                    slidesPerView: 7,
                  },
                }}
              >
                {testimonialList.slice(0, 7).map((testDetails, details) => (
                  <SwiperSlide key={details}>
                    {/* {console.log("**********", testDetails.vWebpImage)} */}
                    <div className="user-box" key={details}>
                      <img
                        src={
                          testDetails && testDetails.vWebpImage
                            ? testDetails.vWebpImage
                            : errorImg
                        }
                        alt={testDetails.vImageAlt}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = errorImg;
                        }}
                        width={70}
                        height={70}
                        // style={{
                        //   objectFit:
                        //     testDetails && testDetails.vWebpImage
                        //       ? "contain"
                        //       : "cover",
                        //   borderRadius: testDetails ? "0%" : "50%",
                        // }}
                        style={{
                          objectFit:
                            testDetails.vWebpImage.split("/").pop() === "null"
                              ? "contain"
                              : "cover",
                              borderRadius:  testDetails.vWebpImage.split("/").pop() === "null" ? "0%" : "50%",
                        }}
                      />
                      <p className="user-name">
                        {testDetails.vName} <br />
                        {/* <span>(CTO)</span> */}
                      </p>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : null}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Testimonial;
