import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getHowItWorks } from "../../store/action/aboutAction";


const HowItWork = () => {

   // const errorImg = "/assets/images/no_image.png"
   // const errorImg = "/assets/images/no-image-icon.png";
   const errorImg = "/assets/images/no-image.png";

   const dispatch = useDispatch();
   const { howItWorks } = useSelector((state) => state.howItWorks);

   // for how it works list
   React.useEffect(() => {
      if (!howItWorks) {
         dispatch(getHowItWorks({}));
      }
   }, [howItWorks, dispatch]);

   // handle show full/less description
   const [showMoreStates, setShowMoreStates] = React.useState({});
   const toggleShowMore = (index) => {
      setShowMoreStates((prevState) => ({
         ...prevState,
         [index]: !prevState[index],
      }));
   };


   return (
      <React.Fragment>
         <section className="how-work p-100">
            <div className="container">
               <div className="title-top">
                  <h2 className="sub-title">How it works</h2>
                  {howItWorks && howItWorks.length > 0 && (
                     <p>{howItWorks[1].tMainDescription}</p>
                  )}
               </div>

               <div className="row">
                  {
                     howItWorks && howItWorks.map((howWorks, howIt) => {
                        const count = howIt + 1;
                        return (
                           <div className="col-md-4 col-12" key={howIt}>
                              <div className="box">
                                 <div className="image-box">
                                    <img
                                       src={howWorks ? howWorks.vImage : errorImg}
                                       alt={howWorks.vImageAlt}
                                       onError={({ currentTarget }) => {
                                          currentTarget.onerror = null;
                                          currentTarget.src = errorImg;
                                       }}
                                       width={45}
                                       height={45}
                                       // style={{
                                       //    objectFit:
                                       //    howWorks.vImage.split("/").pop() ===
                                       //      "null"
                                       //        ? "contain"
                                       //        : "cover",
                                       //  }}
                                    />
                                 </div>
                                 <span className="count">{count < 10 ? `0${count}` : count}</span>
                                 {/* <span className="count">01</span> */}
                                 <h3>{howWorks.vTitle}</h3>
                                 {/* <p>{howWorks.tDescription}</p> */}
                                 <p>
                                    {showMoreStates[howIt]
                                       ? howWorks.tDescription
                                       : howWorks.tDescription.length > 100
                                          ? `${howWorks.tDescription.slice(0, 100)} ...`
                                          : howWorks.tDescription}
                                    <span>
                                       {
                                          howWorks.tDescription.length > 100 && (
                                             <button
                                                className="morelink"
                                                onClick={() => toggleShowMore(howIt)}
                                                style={{ border: "none", color: "#F87217", background: "none" }}
                                             >
                                                {showMoreStates[howIt] ? "Read Less" : "Read More"}
                                             </button>
                                          )
                                       }
                                    </span>
                                 </p>
                              </div>
                           </div>
                        )
                     })
                  }
               </div>
            </div>
         </section>
      </React.Fragment>
   );
};

export default HowItWork;














// Extra code
/* <>
   <div className="col-md-4 col-12">
      <div className="box">
         <div className="image-box">
            <img src="assets/images/instru-icon.png" alt="" />
         </div>
         <span className="count">02</span>
         <h3>Lorem ipsum dolor sit amet</h3>
         <p>
            Lorem ipsum dolor sit amet consectetur. Ultricies convallis
            consequat felis elementum bibendum lectus sodales ut. Sit sed
            quam scelerisque ullamcorper.
         </p>
      </div>
   </div>
   <div className="col-md-4 col-12">
      <div className="box">
         <div className="image-box">
            <img src="assets/images/instru-icon.png" alt="" />
         </div>
         <span className="count">03</span>
         <h3>Lorem ipsum dolor sit amet</h3>
         <p>
            Lorem ipsum dolor sit amet consectetur. Ultricies convallis
            consequat felis elementum bibendum lectus sodales ut. Sit sed
            quam scelerisque ullamcorper.
         </p>
      </div>
   </div>
</> */
