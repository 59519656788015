import React from "react";
import Container from "../components/layouts/Container";
import EventListing from "../components/Event/EventListing";


const EventPage = () => {

  React.useEffect(() => {
    document.title = "Events | WellNas";
  },);

  return (
    <React.Fragment>
      <div className="dark-header">
        <Container>

          <EventListing />

        </Container>
      </div>
    </React.Fragment>
  );
}

export default EventPage;