import React from "react";

const MissionVision = () => {

   React.useEffect(() => {
      document.title = "About-us | WellNas";
   },);

   return (
      <React.Fragment>
         <section className="mission-vision p-100">
            <div className="container">
               <div className="row">
                  <div className="col-md-6">
                     <div className="img-box">
                        <img src="assets/images/mision-vision.png" alt="sign of CEO" width="640" height="580" />
                     </div>
                  </div>
                  <div className="col-md-6 m-auto">
                     <div className="content-area">
                        <div>
                           <h2 className="sub-title">Our Mission</h2>
                           <p className="peregrap-style">
                              Our mission is to foster unity and well-being in an increasingly digital world.
                              We believe in the power of genuine connections and shared experiences.
                              WellNas provides the platform to create lasting friendships and memorable wellness journeys.
                           </p>
                        </div>
                        <div className="border-class"></div>
                        {/* <div className="mision-vision-text"> */}
                           {/* <div className="row g-4"> */}
                              {/* <div className="col-md-6">
                                 <div className="mi-vi-box"> */}
                                    {/* <h3 className="sub-title">Our Mission</h3>
                                    <p className="peregrap-style">Lorem ipsum dolor sit amet consectetur. Ultrices amet pretium commodo nec vulputate platea dignissim. </p> */}
                                 {/* </div>
                              </div> */}
                              {/* <div className="col-md-6">
                                 <div className="mi-vi-box"> */}
                                     {/* <h3 className="sub-title">Our Mission</h3>
                                    <p className="peregrap-style">Lorem ipsum dolor sit amet consectetur. Ultrices amet pretium commodo nec vulputate platea dignissim. </p> */}
                                 {/* </div> 
                              </div> */}
                           {/* </div> */}
                        {/* </div> */}
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </React.Fragment>
   );
};

export default MissionVision;