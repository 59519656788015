import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryList } from "../../store/action/categoryAction";
import { useNavigate } from "react-router-dom";

const CategoriesListing = () => {
  React.useEffect(() => {
    document.title = "Categories | WellNas";
  });

  // const errorImg = "/assets/images/no_image.png"
//   const errorImg = "/assets/images/no-image-icon.png";
  const errorImg = "/assets/images/no-image.png";

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { categoryList } = useSelector((state) => state.category);

  // const [limit, setLimit] = React.useState(8);

  // for handle events as per category
  const handleCategoryEvent = (categoryList) => {
    navigate(
      `/categories/${categoryList.vCategory.replace(/\s+/g, "-")}/events`,
      { state: { vCategory: categoryList.vCategory } }
    );
  };

  // for categories list
  React.useEffect(() => {
    if (!categoryList) {
      dispatch(
        getCategoryList({
          eFeature: "",
          vCategory: "",
        })
      );
    }
  }, [categoryList, dispatch]);

  return (
    <React.Fragment>
      <section
        className="inner-page-breadcamp categorie-banner"
        style={{ backgroundImage: "url(assets/images/categorie-banner.png)" }}
      >
        <div className="container">
          <div className="brad-camp-detail">
            <h1 className="sub-title">Our Categories</h1>
          </div>
        </div>
      </section>

      <section className="event-tab">
        <div className="filtter-area">
          <div className="container">
            <div className="filtter-categ">
              <div className="left-side">
                <div className="fillter-box">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                  >
                    <option defaultValue>Filter by</option>
                    <option value="1">Event</option>
                    <option value="2">Review</option>
                    <option value="3">Recent</option>
                  </select>
                </div>

                <div className="fillter-box">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                  >
                    <option defaultValue>Sort by: Relevance</option>
                    <option value="1">ASC</option>
                    <option value="2">DESC</option>
                  </select>
                </div>
              </div>

              <div className="right-side">
                <div className="fillter-box">
                  <a href="/" className="fillter-btn">
                    Clear all
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="categories-list-part p-100 pt-0">
        <div className="container">
          {categoryList && categoryList.length > 0 ? (
            <div className="row gy-4">
              {categoryList &&
                categoryList.map((categoryList, allCate) => {
                  return (
                    <div
                      className="col-lg-3 col-md-4 col-sm-6"
                      key={allCate}
                      onClick={() => handleCategoryEvent(categoryList)}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="item">
                        <div className="image-box">
                          <img
                            src={categoryList.vWebpImage}
                            alt={categoryList.vImageAlt}
                            width="300"
                            height="300"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src = errorImg;
                            }}
                            style={{
                              objectFit:
                              categoryList.vWebpImage.split("/").pop() === "null"
                                  ? "contain"
                                  : "cover",
                            }}
                          />
                        </div>
                        <div className="text">
                          <h2>{categoryList.vCategory}</h2>
                          <p>{categoryList.tDescription} </p>
                          <a href="javascript:;">
                            <i className="fal fa-chevron-right"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}

              {/* {categoryList && categoryList.length > 8 && limit !== null && (
                        <div className="col-lg-12 col-md-12">
                           <Link to={"#"} className="secondary-btn category-view-btn" onClick={() => { setLimit(null); }}>View All</Link>
                        </div>
                     )} */}
            </div>
          ) : (
            <p>No Data Found</p>
          )}
        </div>
      </section>
    </React.Fragment>
  );
};

export default CategoriesListing;

// --- Extra code ---
/* <>
  <div className="col-lg-3 col-md-4 col-sm-6">
    <div className="item">
      <div className="image-box"><img src="assets/images/categories-three.png" alt="vategories-img" width="300" height="300" />
      </div>
      <div className="text">
        <h2>Sports & Fitness</h2>
        <p>Sport refers to any physical activity or game </p>
        <a href="javascript:;">
          <i className="fal fa-chevron-right"></i>
        </a>
      </div>
    </div>
  </div>
  <div className="col-lg-3 col-md-4 col-sm-6">
    <div className="item">
      <div className="image-box"><img src="assets/images/categories-four.png" alt="vategories-img" width="300" height="300" />
      </div>
      <div className="text">
        <h2>Technology</h2>
        <p>Sport refers to any physical activity or game </p>
        <a href="javascript:;">
          <i className="fal fa-chevron-right"></i>
        </a>
      </div>
    </div>
  </div>
  <div className="col-lg-3 col-md-4 col-sm-6">
    <div className="item">
      <div className="image-box"><img src="assets/images/categories-one.png" alt="vategories-img" width="300" height="300" />
      </div>
      <div className="text">
        <h2>Art & Culture</h2>
        <p>Sport refers to any physical activity or game </p>
        <a href="javascript:;">
          <i className="fal fa-chevron-right"></i>
        </a>
      </div>
    </div>
  </div>
  <div className="col-lg-3 col-md-4 col-sm-6">
    <div className="item">
      <div className="image-box"><img src="assets/images/categories-four.png" alt="vategories-img" width="300" height="300" />
      </div>
      <div className="text">
        <h2>Social Activities</h2>
        <p>Sport refers to any physical activity or game </p>
        <a href="javascript:;">
          <i className="fal fa-chevron-right"></i>
        </a>
      </div>
    </div>
  </div>
  <div className="col-lg-3 col-md-4 col-sm-6">
    <div className="item">
      <div className="image-box"><img src="assets/images/categories-three.png" alt="vategories-img" width="300" height="300" />
      </div>
      <div className="text">
        <h2>Hobbies & Passions</h2>
        <p>Sport refers to any physical activity or game </p>
        <a href="javascript:;">
          <i className="fal fa-chevron-right"></i>
        </a>
      </div>
    </div>
  </div>
  <div className="col-lg-3 col-md-4 col-sm-6">
    <div className="item">
      <div className="image-box"><img src="assets/images/categories-two.png" alt="vategories-img" width="300" height="300" />
      </div>
      <div className="text">
        <h2>Health  & Wellbeing</h2>
        <p>Sport refers to any physical activity or game </p>
        <a href="javascript:;">
          <i className="fal fa-chevron-right"></i>
        </a>
      </div>
    </div>
  </div>
  <div className="col-lg-3 col-md-4 col-sm-6">
    <div className="item">
      <div className="image-box"><img src="assets/images/categories-one.png" alt="vategories-img" width="300" height="300" />
      </div>
      <div className="text">
        <h2>Games</h2>
        <p>Sport refers to any physical activity or game </p>
        <a href="javascript:;">
          <i className="fal fa-chevron-right"></i>
        </a>
      </div>
    </div>
  </div>
</> */
