import React from "react";
import Container from "../components/layouts/Container";
import TermCondition from "../components/Common/TermCondition";
import { useLocation } from "react-router-dom";

const TermAndConditionPage = () => {
  const location = useLocation();
  React.useEffect(() => {
    document.title = "Term-condition | WellNas";
  }, [location]);

  return (
    <React.Fragment>
      <div className="dark-header">
        <Container>
          <TermCondition />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TermAndConditionPage;
