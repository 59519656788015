// // SkeletonOneToOneEventList.js

// import React from "react";
// import "./Skeleton.css"; // Import the corresponding CSS file for styling

// const SkeletonMyEventList = ({ count }) => {
//   const skeletons = Array.from({ length: count }, (_, index) => (
//     <div key={index} className="skeleton-card">
//       <div className="skeleton-avatar"></div>
//       <div className="skeleton-rectangular-text"></div>
//       <div className="skeleton-text"></div>
//       <div className="skeleton-last-text"></div>
//       <div className="skeleton-rounded-text"></div>
//       <div className="skeleton-rounded"></div>
//     </div>
//   ));

//   return <div className="skeleton-container">{skeletons}</div>;
// };

// export default SkeletonMyEventList;

// import React from "react";
// import "./Skeleton.css"; // Import the corresponding CSS file for styling

// const SkeletonMyEventList = ({ count }) => {
//   const skeletons = Array.from({ length: count }, (_, index) => (
//     <div key={index} className="skeleton-card">
//       <div className="skeleton-avatar"></div>
//       <div className="skeleton-rectangular-text"></div>
//       <div className="skeleton-text"></div>
//       <div className="skeleton-last-text"></div>
//       <div className="skeleton-rounded-text"></div>
//       <div className="skeleton-rounded"></div>
//     </div>
//   ));

//   return (
//     <div className="skeleton-container">
//       <div className="skeleton-row">
//         {/* First Row */}
//         {skeletons.slice(0, 3)}
//       </div>
//       <div className="skeleton-row">
//         {/* Second Row */}
//         {skeletons.slice(3, 6)}
//       </div>
//       <div className="skeleton-row">
//         {/* Third Row */}
//         {skeletons.slice(6, 9)}
//       </div>
//       {/* Map div on the right side */}
//       <div className="skeleton-map"></div>
//     </div>
//   );
// };

// export default SkeletonMyEventList;

// SkeletonMyEventList.jsx
import React from "react";
import "./Skeleton.css"; // Import the corresponding CSS file for styling

const SkeletonOneToOneList = ({ count }) => {
  const skeletons = Array.from({ length: count }, (_, index) => (
    <div key={index} className="skeleton-card">
      <div className="skeleton-avatar"></div>
      <div className="skeleton-rectangular-text"></div>
      <div className="skeleton-text"></div>
      <div className="skeleton-last-text"></div>
      <div className="skeleton-rounded-text"></div>
      <div className="skeleton-rounded"></div>
    </div>
  ));

  return (
    <div className="skeleton-container">
      <div className="skeleton-row">
        {/* First Row */}
        {skeletons.slice(0, 3)}
      </div>
      <div className="skeleton-row">
        {/* Second Row */}
        {skeletons.slice(3, 6)}
      </div>
      <div className="skeleton-row">
        {/* Third Row */}
        {skeletons.slice(6, 9)}
      </div>
      {/* Map div on the right side */}
      <div className="skeleton-map"></div>
    </div>
  );
};

export default SkeletonOneToOneList;
