import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import { bannerReducer } from './bannerReducer';
import { categoryReducer } from './categoryReducer';
import { addNewEventReducer, allEventReviewReducer, eventDetailReducer, eventReducer, eventTopReviewReducer, myAttendeesEventReducer, myEventReducer, oneToOneEventReducer, recommendedEventReducer } from './eventReducer';
import { testimonialReducer } from './testimonialReducer';
import { activityReducer, addPreferredActivityReducer, preferredActivityListReducer } from './activityReducer';
import { addNewGroupReducer, groupReducer, myGroupReducer } from './groupReducer';
import { tagReducer } from './tagReducer';
import { customerAccountDeleteReducer, customerEmailVerify, customerEventReducer, customerForgetPassword, customerLoginReducer, customerProfileReducer, customerRegisterReducer, customerServiceReducer, googleRegisterReducer, updateCustomerServiceReducer, updatePasswordReducer, updateProfileReducer } from './customerReducer';
import { contactUsReducer } from './contactReducer';
import { addNewReviewReducer, hostEventReviewReducer, myReviewReducer } from './reviewReducer';
import { notificationUpdateReducer, settingListReducer } from './settingReducer';
import { joinNowReducer, undoEventReducer } from './attendeesReducer';
import { coreValuesReducer, howItWorksReducer, pageListAboutReducer, pageListJoinReducer, whyWellNasReducer } from './aboutReducer';
import { addNotificationReducer, recentNotificationReducer, viewNotificationReducer } from './notificationReducer';
import { searchListReducer } from './searchReducer';

const rootReducer = combineReducers({

  customerRegister: customerRegisterReducer,
  emailVerify: customerEmailVerify,
  customerLogin: customerLoginReducer,
  forgetPassword: customerForgetPassword,
  customerProfile: customerProfileReducer,
  updatePassword: updatePasswordReducer,
  updateProfile: updateProfileReducer,
  customerService: customerServiceReducer,
  updateCustomerService: updateCustomerServiceReducer,
  customerEvent: customerEventReducer,
  googleRegister: googleRegisterReducer,

  banner: bannerReducer,

  category: categoryReducer,

  event: eventReducer,
  eventDetail: eventDetailReducer,
  recommendedEvent: recommendedEventReducer,
  myEvent: myEventReducer,
  attendeesEvent: myAttendeesEventReducer,
  addNewEvent: addNewEventReducer,
  oneToOneEvent: oneToOneEventReducer,
  allEventReview: allEventReviewReducer,
  eventTopReview: eventTopReviewReducer,

  customerAccountDelete:customerAccountDeleteReducer,

  contactUs: contactUsReducer,

  testimonial: testimonialReducer,

  activityList: activityReducer,
  preferredActivityList: preferredActivityListReducer,
  addPreferredActivity: addPreferredActivityReducer,

  myReview: myReviewReducer,
  addNewReview: addNewReviewReducer,
  hostEventReview: hostEventReviewReducer,

  group: groupReducer,
  myGroup: myGroupReducer,
  addNewGroup: addNewGroupReducer,

  settingList: settingListReducer,
  notificationUpdate: notificationUpdateReducer,

  coreValues: coreValuesReducer,
  howItWorks: howItWorksReducer,
  whyWellNas: whyWellNasReducer,
  pageListJoin: pageListJoinReducer,
  pageListAbout: pageListAboutReducer,

  recentNotification: recentNotificationReducer,
  viewNotification: viewNotificationReducer,
  addNotification: addNotificationReducer,

  searchList: searchListReducer,

  tagList: tagReducer,

  joinNow: joinNowReducer,
  undoEvent: undoEventReducer,

});

const middleware = composeWithDevTools(applyMiddleware(thunk));

export const store = createStore(rootReducer, middleware);