import React from "react";
import { Link } from "react-router-dom";

const SuccessResetPasswordPage = () => {
   return (
      <>
         <div className="highlight-msg">
            <Link to={"/"} className="logo-box">
               <img src="/assets/images/logo.png" alt="" />
            </Link>
            <div className="text">
               <h1 className="success-color">Success !!</h1>
               <p>
                  We have sent you email with reset password link. Please check your email for reset password. If you don't see email, check spam folder once.
               </p>
               <Link to={"/login"} className="main-btn">
                  Continue
               </Link>
            </div>
         </div>
      </>
   );
};

export default SuccessResetPasswordPage;
