import React from "react";
// import logo from "../../../assets/img/logo.png"

function Loader() {
  const loaderStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh", // This ensures it covers the full height of the viewport
  };
  return (
    <div className="loader" style={loaderStyle}>
      <img
        src={"/assets/images/logo.png"}
        className="img-fluid"
        alt="loader logo"
        // width=""
        // height=""
        title="loader logo"
      />
    </div>
  );
}

export default Loader;
