import {
    TESTIMONIAL_LIST_REQUEST,
    TESTIMONIAL_LIST_SUCCESS,
    TESTIMONIAL_LIST_FAILURE,

} from "../constants/testimonialConstant";

import axios from "axios";


export const getTestimonialList = (data) => async (dispatch) => {
    // console.log("values inside action....", values)
    try {
        dispatch({ type: TESTIMONIAL_LIST_REQUEST });
        const resData = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/testimonial/get_all_data`, data
        );
        dispatch({ type: TESTIMONIAL_LIST_SUCCESS, payload: resData.data.data });
    } catch (err) {
        dispatch({ type: TESTIMONIAL_LIST_FAILURE });
    }
};