import {
    ACTIVITY_LIST_REQUEST,
    ACTIVITY_LIST_SUCCESS,
    ACTIVITY_LIST_FAILURE,

    PREFERRED_ACTIVITY_LIST_REQUEST,
    PREFERRED_ACTIVITY_LIST_SUCCESS,
    PREFERRED_ACTIVITY_LIST_FAILURE,

    ADD_PREFERRED_ACTIVITY_REQUEST,
    ADD_PREFERRED_ACTIVITY_SUCCESS,
    ADD_PREFERRED_ACTIVITY_FAILURE,

} from "../constants/activityConstant";

export function activityReducer(state = {}, action) {
    switch (action.type) {
        case ACTIVITY_LIST_REQUEST:
            return {
                loading: true,
            }
        case ACTIVITY_LIST_SUCCESS:
            return {
                loading: false,
                activityList: action.payload,
            }
        case ACTIVITY_LIST_FAILURE:
            return {
                loading: false,
            }
        default:
            return state
    }
};

export function preferredActivityListReducer(state = {}, action) {
    switch (action.type) {
        case PREFERRED_ACTIVITY_LIST_REQUEST:
            return {
                loading: true,
            }
        case PREFERRED_ACTIVITY_LIST_SUCCESS:
            return {
                loading: false,
                preferredActivityList: action.payload,
            }
        case PREFERRED_ACTIVITY_LIST_FAILURE:
            return {
                loading: false,
            }
        default:
            return state
    }
};

export function addPreferredActivityReducer(state = {}, action) {
    switch (action.type) {
        case ADD_PREFERRED_ACTIVITY_REQUEST:
            return {
                loading: true,
            }
        case ADD_PREFERRED_ACTIVITY_SUCCESS:
            return {
                loading: false,
                addPreferredActivity: action.payload,
            }
        case ADD_PREFERRED_ACTIVITY_FAILURE:
            return {
                loading: false,
            }
        default:
            return state
    }
};