import React from "react";
import ProfileMenu from "../Profile/ProfileMenu";
import axios from "axios";

import { useDispatch, useSelector } from "react-redux";
import { getSettingList } from "../../store/action/settingAction";
import { getCustomerAccountDelete } from "../../store/action/customerAction";
import { useNavigate } from "react-router";

const Setting = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { settingList } = useSelector((state) => state.settingList);
  const { customerAccountDeleteData } = useSelector(
    (state) => state.customerAccountDelete
  );

  //   console.log("customerAccountDeleteData ", customerAccountDeleteData);

  const [isPromotionChecked, setIsPromotionChecked] = React.useState(true);
  const [isUpdateChecked, setIsUpdateChecked] = React.useState(true);
  const [isEventChecked, setIsEventChecked] = React.useState(true);
  const [deleteCustomerSuccess, setDeleteCustomerSuccess] =
    React.useState(false);

  const vUniqueCode =
    // sessionStorage.getItem("vUniqueCode")||
    localStorage.getItem("vUniqueCode");

  //   console.log("vUniqueCode--------------------->", vUniqueCode);

  // Define success message states for individual settings
  const [successMessages, setSuccessMessages] = React.useState({
    ePromotion: false,
    eUpdate: false,
    eEvent: false,
  });

  // for capital 1st letter
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  React.useEffect(() => {
    const promotionChecked = JSON.parse(
      localStorage.getItem("isPromotionChecked")
    );
    const updateChecked = JSON.parse(localStorage.getItem("isUpdateChecked"));
    const eventChecked = JSON.parse(localStorage.getItem("isEventChecked"));

    if (promotionChecked !== null) {
      setIsPromotionChecked(promotionChecked);
    }
    if (updateChecked !== null) {
      setIsUpdateChecked(updateChecked);
    }
    if (eventChecked !== null) {
      setIsEventChecked(eventChecked);
    }
  }, []);

  // for save checkbox state
  const saveCheckboxState = (type, checked) => {
    localStorage.setItem(`is${type}Checked`, JSON.stringify(checked));
  };

  // handle checkbox for toggle
  const handleCheckboxToggle = async (type) => {
    let isChecked;
    switch (type) {
      case "Promotion":
        isChecked = !isPromotionChecked;
        setIsPromotionChecked(isChecked);
        break;
      case "Update":
        isChecked = !isUpdateChecked;
        setIsUpdateChecked(isChecked);
        break;
      case "Event":
        isChecked = !isEventChecked;
        setIsEventChecked(isChecked);
        break;
      default:
        break;
    }

    saveCheckboxState(type, isChecked);

    const vUniqueCode =
      // sessionStorage.getItem("vUniqueCode")||
      localStorage.getItem("vUniqueCode");
    const notificationStatus = isChecked ? "Yes" : "No";

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/notification/update`,
        {
          vUniqueCode: vUniqueCode,
          [`e${type}`]: notificationStatus,
        }
      );

      if (response.status === 200) {
        setSuccessMessages((prevMessages) => ({
          ...prevMessages,
          [`e${type}`]: true,
        }));

        setTimeout(() => {
          setSuccessMessages((prevMessages) => ({
            ...prevMessages,
            [`e${type}`]: false,
          }));
        }, 2000);
      }
    } catch (error) {}
  };

  // for setting list
  React.useEffect(() => {
    const vUniqueCode =
      //  sessionStorage.getItem("vUniqueCode")||
      localStorage.getItem("vUniqueCode");
    if (!settingList) {
      dispatch(
        getSettingList({
          vUniqueCode: vUniqueCode,
        })
      );
    }
  }, [dispatch, settingList]);

  const handleDelete = (vUniqueCode) => {
    //  console.log("vUniqueCode", vUniqueCode);
    dispatch(
      getCustomerAccountDelete({
        vUniqueCode: vUniqueCode,
      })
    );
    // Close the modal
    document.getElementById("close-modal").click();
  };

  React.useEffect(() => {
    if (customerAccountDeleteData && customerAccountDeleteData.code === "200") {
      setDeleteCustomerSuccess(true);

      setTimeout(() => {
        setDeleteCustomerSuccess(false);
        navigate("/login");
        //   window.location.reload();
      }, 3000);
    }
  }, [customerAccountDeleteData, navigate]);

  return (
    <div className="profile-page">
      <div className="container-fluid">
        <div className="row g-4">
          <div className="col-lg-2 col-md-3">
            <ProfileMenu />
          </div>
          <div className="col-lg-7 col-md-9 col-12">
            <div className="row g-4">
              <div className="col-12">
                <div className="layout-box">
                  {settingList &&
                    settingList?.map((setting, settingIndex) => {
                      return (
                        <React.Fragment key={`setting-${settingIndex}`}>
                          {/* Promotion part */}
                          <div
                            className="box"
                            key={`promotion-${settingIndex}`}
                          >
                            <div className="title-top">
                              <h2>Promotion</h2>
                              <div className="form-group">
                                <div className="form-check form-switch">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id={`promotion-switch-${settingIndex}`}
                                    checked={isPromotionChecked}
                                    onChange={() =>
                                      handleCheckboxToggle("Promotion")
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="layout-main-box">
                              <div className="row g-md-4 g-3">
                                {setting &&
                                  setting.notifications?.map(
                                    (promotion, promotionIndex) => {
                                      const promotionTexts =
                                        promotion.tPromotion
                                          .split(",")
                                          .map((promotionText, textIndex) => (
                                            <div
                                              className="col-12"
                                              key={`promotion-text-${settingIndex}-${promotionIndex}-${textIndex}`}
                                            >
                                              <div className="promotion-box">
                                                <div className="image-box">
                                                  <img
                                                    src="/assets/images/promotion-icon.png"
                                                    alt=""
                                                  />
                                                </div>
                                                <div className="text">
                                                  <h3>
                                                    {capitalizeFirstLetter(
                                                      promotionText.trim()
                                                    )}
                                                  </h3>
                                                </div>
                                              </div>
                                            </div>
                                          ));
                                      return promotionTexts;
                                    }
                                  )}
                              </div>
                            </div>
                            {successMessages.ePromotion && (
                              <p style={{ color: "green", fontSize: "14px" }}>
                                Notification records updated successfully.
                              </p>
                            )}
                          </div>

                          {/* Update part */}
                          <div className="box" key={`update-${settingIndex}`}>
                            <div className="title-top">
                              <h2>Update</h2>
                              <div className="form-group">
                                <div className="form-check form-switch">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id={`update-switch-${settingIndex}`}
                                    checked={isUpdateChecked}
                                    onChange={() =>
                                      handleCheckboxToggle("Update")
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="layout-main-box">
                              <div className="row g-md-4 g-3">
                                {setting &&
                                  setting.notifications?.map(
                                    (update, updateIndex) => {
                                      const updateTexts = update.tUpdate
                                        .split(",")
                                        .map((updateText, textIndex) => (
                                          <div
                                            className="col-md-6 col-12"
                                            key={`update-text-${settingIndex}-${updateIndex}-${textIndex}`}
                                          >
                                            <div className="promotion-box">
                                              <div className="image-box">
                                                <img
                                                  src="/assets/images/system-icon.png"
                                                  alt=""
                                                />
                                              </div>
                                              <div className="text">
                                                <h3>
                                                  {capitalizeFirstLetter(
                                                    updateText.trim()
                                                  )}
                                                </h3>
                                              </div>
                                            </div>
                                          </div>
                                        ));
                                      return updateTexts;
                                    }
                                  )}
                              </div>
                            </div>
                            {successMessages.eUpdate && (
                              <p style={{ color: "green", fontSize: "14px" }}>
                                Notification records updated successfully.
                              </p>
                            )}
                          </div>

                          {/* Event part */}
                          <div className="box" key={`event-${settingIndex}`}>
                            <div className="title-top">
                              <h2>Event</h2>
                              <div className="form-group">
                                <div className="form-check form-switch">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id={`event-switch-${settingIndex}`}
                                    checked={isEventChecked}
                                    onChange={() =>
                                      handleCheckboxToggle("Event")
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="layout-main-box">
                              <div className="row g-md-4 g-3">
                                {setting &&
                                  setting.notifications?.map(
                                    (event, eventIndex) => {
                                      const eventTexts = event.tEvent
                                        .split(",")
                                        .map((eventText, textIndex) => (
                                          <div
                                            className="col-md-6 col-12"
                                            key={`event-text-${settingIndex}-${eventIndex}-${textIndex}`}
                                          >
                                            <div className="promotion-box">
                                              <div className="image-box">
                                                <img
                                                  src="/assets/images/privacy-icon.png"
                                                  alt=""
                                                />
                                              </div>
                                              <div className="text">
                                                <h3>
                                                  {capitalizeFirstLetter(
                                                    eventText.trim()
                                                  )}
                                                </h3>
                                              </div>
                                            </div>
                                          </div>
                                        ));
                                      return eventTexts;
                                    }
                                  )}
                              </div>
                            </div>
                            {successMessages.eEvent && (
                              <p style={{ color: "green", fontSize: "14px" }}>
                                Notification records updated successfully.
                              </p>
                            )}
                          </div>
                        </React.Fragment>
                      );
                    })}
                </div>
              </div>
              <div className="col-12">
                <h4 className="sub-title mb-3">Delete Account</h4>

                <div
                  className="layout-box"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    {/* <button
                      className="main-btn"
                      onClick={() => {
                        if (
                          window.confirm(
                            "Are You Sure You Want To Delete Your Account ?"
                          )
                        ) {
                          // handleDelete(vUniqueCode);
                        }
                      }}
                    >
                      Delete My Account
                    </button> */}

                    <button
                      type="button"
                      class="main-btn"
                      data-target="#deleteModal"
                      data-toggle="modal"
                      // onClick={() => {
                      //   handleDelete(vUniqueCode);
                      // }}
                    >
                      Delete My Account
                    </button>

                    <div class="modal fade" id="deleteModal">
                      <div class="modal-dialog">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">
                              Delete Account
                            </h5>
                            <button
                              type="button"
                              class="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body">
                            <p>
                              Are You Sure You Want To Delete Your Account ?
                            </p>
                          </div>
                          <div class="modal-footer">
                            <button
                              type="button"
                              class="btn btn-secondary"
                              id="close-modal"
                              data-dismiss="modal"
                            >
                              No
                            </button>
                            <button
                              type="button"
                              class="btn btn-danger"
                              onClick={() => {
                                handleDelete(vUniqueCode);
                              }}
                            >
                              Yes
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {deleteCustomerSuccess && (
                      <p style={{ color: "green" }}>
                        Customer Deleted successfully.
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="col-lg-7 offset-lg-1 col-md-6 offset-md-5 col-10 ">
            <div className="layout-box">hiiiiiiiii</div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Setting;
