import React from "react";
import ProfileMenu from "../Profile/ProfileMenu";
import { useDispatch, useSelector } from "react-redux";
import { getViewNotification } from "../../store/action/notificationAction";

const Notification = () => {
  // const errorImg = "/assets/images/no_image.png";
  //   const errorImg = "/assets/images/no-image-icon.png";
  const errorImg = "/assets/images/no-image.png";

  const dispatch = useDispatch();
  const { viewNotification } = useSelector((state) => state.viewNotification);

  // handle today notification
  const today = new Date().toISOString().slice(0, 10);
  const filteredNotifications =
    viewNotification &&
    viewNotification.filter(
      (notification) => notification.dtDateTime.slice(0, 10) === today
    );

  // handle yesterday notification
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const yesterdayDate = yesterday.toISOString().slice(0, 10);
  const filteredYesterdayNotification =
    viewNotification &&
    viewNotification.filter(
      (notification) => notification.dtDateTime.slice(0, 10) === yesterdayDate
    );

  // handle bygone days notification
  const filteredBygoneNotification =
    viewNotification &&
    viewNotification.filter((notification) => {
      const notificationDate = notification.dtDateTime.slice(0, 10);
      return notificationDate !== today && notificationDate !== yesterdayDate;
    });

  // for recent notification
  React.useEffect(() => {
    const vUniqueCode =
      // sessionStorage.getItem("vUniqueCode") ||
      localStorage.getItem("vUniqueCode");
    if (!viewNotification) {
      dispatch(
        getViewNotification({
          vUniqueCode: vUniqueCode,
        })
      );
    }
  }, [viewNotification, dispatch]);

  // for format Timedate
  const formatNotificationDate = (dtDateTime) => {
    const notificationDate = new Date(dtDateTime);
    const timezoneOffset = notificationDate.getTimezoneOffset();
    const adjustedDate = new Date(
      notificationDate.getTime() + timezoneOffset * 60 * 1000
    );

    // Format the time and day
    const hours = adjustedDate.getHours();
    const minutes = String(adjustedDate.getMinutes()).padStart(2, "0");
    const amOrPm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;

    const formattedTime = `${formattedHours}:${minutes} ${amOrPm}`;
    const formattedDay = adjustedDate.toDateString();

    return { formattedTime, formattedDay };
  };

  return (
    <div className="profile-page">
      <div className="container-fluid">
        <div className="row g-4">
          <div className="col-lg-2 col-md-3">
            <ProfileMenu />
          </div>

          {/* view notification part */}
          <div className="col-lg-7 col-md-9 col-12">
            {viewNotification && viewNotification.length > 0 ? (
              <div className="notification-msg">
                {/* today's notification */}
                {filteredNotifications && filteredNotifications.length > 0 && (
                  <div className="white-card">
                    <h2>Notification</h2>
                    <div className="notify-box">
                      {filteredNotifications &&
                        filteredNotifications.map(
                          (todayNotification, notifyToday) => {
                            const { formattedTime, formattedDay } =
                              formatNotificationDate(
                                todayNotification.dtDateTime
                              );

                            return (
                              <div className="box" key={notifyToday}>
                                <div className="image-box">
                                  <img
                                    className="img-cover"
                                    src={
                                      todayNotification
                                        ? todayNotification.vWebpImage
                                        : errorImg
                                    }
                                    alt=""
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null;
                                      currentTarget.src = errorImg;
                                    }}
                                    style={{
                                      objectFit:
                                        todayNotification.vWebpImage
                                          .split("/")
                                          .pop() === "null"
                                          ? "contain"
                                          : "cover",
                                    }}
                                  />
                                </div>
                                <div className="msg-text">
                                  <div className="msg">
                                    <span className="bold">
                                      {todayNotification.vFirstName}{" "}
                                      {todayNotification.vLastName}
                                    </span>
                                    {todayNotification.tAlert}
                                    <span className="bold">
                                      {todayNotification.vEvent}
                                    </span>
                                  </div>
                                  <div className="time">
                                    {formattedTime}
                                    <span className="dots"></span>
                                    {formattedDay}
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )}
                    </div>
                  </div>
                )}

                {/* yesterday's notification */}
                {filteredYesterdayNotification &&
                  filteredYesterdayNotification.length > 0 && (
                    <div className="white-card">
                      <h2>Yesterday</h2>
                      <div className="notify-box">
                        {filteredYesterdayNotification &&
                          filteredYesterdayNotification.map(
                            (yesterdayNotification, notifyYesterday) => {
                              {
                                /* const { formattedTime, formattedDay } = formatNotificationDate(yesterdayNotification.dtDateTime); */
                              }

                              return (
                                <div className="box" key={notifyYesterday}>
                                  <div className="image-box">
                                    <img
                                      className="img-cover"
                                      src={
                                        yesterdayNotification
                                          ? yesterdayNotification.vWebpImage
                                          : errorImg
                                      }
                                      alt=""
                                      onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = errorImg;
                                      }}
                                      style={{
                                        objectFit:
                                          yesterdayNotification.vWebpImage
                                            .split("/")
                                            .pop() === "null"
                                            ? "contain"
                                            : "cover",
                                      }}
                                    />
                                  </div>
                                  <div className="msg-text">
                                    <div className="msg">
                                      <span className="bold">
                                        {yesterdayNotification.vFirstName}{" "}
                                        {yesterdayNotification.vLastName}
                                      </span>
                                      {yesterdayNotification.tAlert}
                                      <span className="bold">
                                        {yesterdayNotification.vEvent}
                                      </span>
                                    </div>
                                    {/* <div className="time">{formattedTime}<span className="dots"></span>{formattedDay}</div> */}
                                  </div>
                                </div>
                              );
                            }
                          )}
                      </div>
                    </div>
                  )}

                {/* few days ago notification */}
                {filteredBygoneNotification &&
                  filteredBygoneNotification.length > 0 && (
                    <div className="white-card">
                      <h2>Few days ago</h2>
                      <div className="notify-box">
                        {filteredBygoneNotification &&
                          filteredBygoneNotification.map(
                            (bygoneNotification, notifyBygone) => {
                              return (
                                <div className="box" key={notifyBygone}>
                                  <div className="image-box">
                                    <img
                                      className="img-cover"
                                      src={
                                        bygoneNotification
                                          ? bygoneNotification.vWebpImage
                                          : errorImg
                                      }
                                      alt=""
                                      onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = errorImg;
                                      }}
                                      style={{
                                        objectFit:
                                          bygoneNotification.vWebpImage
                                            .split("/")
                                            .pop() === "null"
                                            ? "contain"
                                            : "cover",
                                      }}
                                    />
                                  </div>
                                  <div className="msg-text">
                                    <div className="msg">
                                      <span className="bold">
                                        {bygoneNotification.vFirstName}{" "}
                                        {bygoneNotification.vLastName}
                                      </span>
                                      {bygoneNotification.tAlert}
                                      <span className="bold">
                                        {bygoneNotification.vEvent}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )}
                      </div>
                    </div>
                  )}
              </div>
            ) : viewNotification && viewNotification.length === 0 ? (
              <div
                className="no-notification-msg"
                style={{ fontSize: "18px", margin: "10px 20px" }}
              >
                No notification available
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;

// Extra code for today
/* <>
   <div className="box">
      <div className="image-box">
         <img src="/assets/images/user-profile.png" alt="" className="img-cover" />
      </div>
      <div className="msg-text">
         <div className="msg"><span className="bold">Snuck</span>Commented on<span className="bold">Paris Travel Planning </span><span className="comment-msg">“Lorem ipsum dolor sit amet consectetur. Hendrerit at eu sit posuere ipsum sed. Neque enim vel”</span></div>
         <div class="time">9:21 pm<span class="dots"></span>Today</div>
      </div>
   </div>
   <div className="box">
      <div className="image-box">
         <img src="/assets/images/testimonial-one.png" alt="" className="img-cover" />
      </div>
      <div className="msg-text">
         <div className="msg"><span className="bold">Mellisa Jones</span> added you to the board <span className="bold">The treasure of the three witches</span></div>
         <div class="time">9:21 pm<span class="dots"></span>Today</div>
      </div>
   </div>
</> */

// Extra code for yesterday
/* <>
   <div className="box">
      <div className="image-box">
         <img src="/assets/images/testimonial-one.png" alt="" className="img-cover" />
      </div>
      <div className="msg-text">
         <div className="msg"><span className="bold">Mellisa Jones</span> added you to the board <span className="bold">The treasure of the three witches</span></div>
         <div className="img-msg">
            <img src="/assets/images/categories-one.png" alt="" />
            <img src="/assets/images/event-3.png" alt="" />
            <img src="/assets/images/Rectangle 59714.png" alt="" />
            <img src="/assets/images/why-us-four.png" alt="" />
         </div>
      </div>
   </div>
   <div className="box">
      <div className="image-box">
         <img src="/assets/images/user-profile.png" alt="" className="img-cover" />
      </div>
      <div className="msg-text">
         <div className="msg"><span className="bold">Snuck</span>Commented on<span className="bold">Paris Travel Planning </span><span className="comment-msg">“Lorem ipsum dolor sit amet consectetur. Hendrerit at eu sit posuere ipsum sed. Neque enim vel”</span></div>
      </div>
   </div>
   <div className="box">
      <div className="image-box">
         <img src="/assets/images/why-us-five.png" alt="" className="img-cover" />
      </div>
      <div className="msg-text">
         <div className="msg"><span className="bold">Mellisa Jones</span> added you to the board <span className="bold">The treasure of the three witches</span></div>
      </div>
   </div>
</> */

// Extra code for few days ago notification
/* <>
   <div className="box">
      <div className="image-box">
         <img src="/assets/images/user-profile.png" alt="" className="img-cover" />
      </div>
      <div className="msg-text">
         <div className="msg"><span className="bold">Snuck</span>Commented on<span className="bold">Paris Travel Planning </span><span className="comment-msg">“Lorem ipsum dolor sit amet consectetur. Hendrerit at eu sit posuere ipsum sed. Neque enim vel”</span></div>
      </div>
   </div>
   <div className="box">
      <div className="image-box">
         <img src="/assets/images/why-us-five.png" alt="" className="img-cover" />
      </div>
      <div className="msg-text">
         <div className="msg"><span className="bold">Mellisa Jones</span> added you to the board <span className="bold">The treasure of the three witches</span></div>
      </div>
   </div>
</> */
