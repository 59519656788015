import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryList } from "../../store/action/categoryAction";
import { useNavigate } from "react-router-dom";

const ActivityListing = () => {
  //   const errorImg = "/assets/images/no_image.png";
  const errorImg = "/assets/images/no-image.png";

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { categoryList } = useSelector((state) => state.category);

  // for handle events as per activity
  const handleActivityEvent = (activity) => {
    // const formattedActivity = activity.vActivity.replace(/\//g, " ");
    if (activity && activity.vActivity.includes("/")) {
      const formattedActivity = activity.vActivity
        .replace(/\//g, "-")
        .replace(/\s+/g, " ");

      navigate(`/activities/${formattedActivity}/events`, {
        state: { vActivity: activity.vActivity },
      });
    } else {
      navigate(
        `/activities/${activity.vActivity.replace(/\s+/g, "-")}/events`,
        {
          state: { vActivity: activity.vActivity },
        }
      );
    }
  };

  // for category name + activities list
  React.useEffect(() => {
    if (!categoryList) {
      dispatch(
        getCategoryList({
          eFeature: "",
          vCategory: "",
        })
      );
    }
  }, [categoryList, dispatch]);

  return (
    <React.Fragment>
      <section
        className="inner-page-breadcamp categorie-banner"
        style={{ backgroundImage: "url(assets/images/categorie-banner.png)" }}
      >
        <div className="container">
          <div className="brad-camp-detail">
            <h1 className="sub-title">Our Activities</h1>
          </div>
        </div>
      </section>

      <section className="activities-page p-100">
        <div className="container">
          <div className="activities-box">
            {categoryList && categoryList.length > 0 ? (
              categoryList.map((category) => (
                <div className="inner-box" key={category.vCategory}>
                  <div className="row">
                    <div className="col-lg-3 col-12">
                      <h2>{category.vCategory}</h2>
                    </div>
                    <div className="col-lg-9">
                      {category.activities && category.activities.length > 0 ? (
                        <div className="row g-4">
                          {category.activities &&
                            category.activities
                              .slice(0, 4)
                              .map((activity, activityList) => (
                                <div
                                  className="col-md-3 col-sm-4 col-6"
                                  key={activityList}
                                  onClick={() => handleActivityEvent(activity)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <div className="activities-game">
                                    <div className="image-box">
                                      <img
                                        className="img-contain"
                                        src={activity.vWebpImage}
                                        alt={activity.vImageAlt}
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null;
                                          currentTarget.src = errorImg;
                                        }}
                                        style={{
                                          objectFit:
                                            activity.vWebpImage
                                              .split("/")
                                              .pop() === "null"
                                              ? "contain"
                                              : "cover",
                                        }}
                                      />
                                    </div>
                                    <p>{activity.vActivity}</p>
                                  </div>
                                </div>
                              ))}
                        </div>
                      ) : (
                        <p style={{ marginTop: "85px", color: "#888888" }}>
                          No data found for activities in this category
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div>No data found</div>
            )}

            {/* {categoryList && categoryList.map((category) => {
                     return (
                        <div className="inner-box" key={category}>
                           <div className="row">
                              <div className="col-lg-3 col-12">
                                 <h2>{category.vCategory}</h2>
                              </div>
                              <div className="col-lg-9">
                                 <div className="row g-4">
                                    {category.activities && category.activities.slice(0, 4).map((activity) => {
                                       return (
                                          <div className="col-md-3 col-sm-4 col-6" key={activity}>
                                             <div className="activities-game">
                                                <div className="image-box">
                                                   <img
                                                      className="img-contain"
                                                      src={activity.vWebpImage}
                                                      alt={activity.vImageAlt}
                                                      onError={({ currentTarget }) => {
                                                         currentTarget.onerror = null;
                                                         currentTarget.src = errorImg;
                                                      }}
                                                   />
                                                </div>
                                                <p>{activity.vActivity}</p>
                                             </div>
                                          </div>
                                       )
                                    })}
                                 </div>
                              </div>
                           </div>
                        </div>
                     )
                  })} */}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default ActivityListing;

// Extra 1 activity code
/* <>
   <div className="col-md-3 col-sm-4 col-6">
      <div className="activities-game">
         <div className="image-box">
            <img src="./assets/images/Trail Running.png" className="img-contain" alt="" />
         </div>
         <p>Trail Running</p>
      </div>
   </div>
   <div className="col-md-3 col-sm-4 col-6">
      <div className="activities-game">
         <div className="image-box">
            <img src="./assets/images/Sprint Intervals.png" className="img-contain" alt="" />
         </div>
         <p>Sprint Intervals</p>
      </div>
   </div>
   <div className="col-md-3 col-sm-4 col-6">
      <div className="activities-game">
         <div className="image-box">
            <img src="./assets/images/Half Marathon Training.png" className="img-contain" alt="" />
         </div>
         <p>Half Marathon Training</p>
      </div>
   </div>
</> */

// Extra all activity code
/* <>
   <div className="inner-box">
      <div className="row">
         <div className="col-lg-3 col-12">
            <h2>Cycling</h2>
         </div>
         <div className="col-lg-9">
            <div className="row g-4">
               <div className="col-md-3 col-sm-4 col-6">
                  <div className="activities-game">
                     <div className="image-box">
                        <img src="./assets/images/Road Biking.png" className="img-contain" alt="" />
                     </div>
                     <p>Road Biking</p>
                  </div>
               </div>
               <div className="col-md-3 col-sm-4 col-6">
                  <div className="activities-game">
                     <div className="image-box">
                        <img src="./assets/images/Mountain Biking.png" className="img-contain" alt="" />
                     </div>
                     <p>Mountain Biking</p>
                  </div>
               </div>
               <div className="col-md-3 col-sm-4 col-6">
                  <div className="activities-game">
                     <div className="image-box">
                        <img src="./assets/images/Indoor Cycling.png" className="img-contain" alt="" />
                     </div>
                     <p>Indoor Cycling</p>
                  </div>
               </div>
               <div className="col-md-3 col-sm-4 col-6">
                  <div className="activities-game">
                     <div className="image-box">
                        <img src="./assets/images/Bike Commuting.png" className="img-contain" alt="" />
                     </div>
                     <p>Bike Commuting</p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div className="inner-box">
      <div className="row">
         <div className="col-lg-3 col-12">
            <h2>Mental Wellness</h2>
         </div>
         <div className="col-lg-9">
            <div className="row g-4">
               <div className="col-md-3 col-sm-4 col-6">
                  <div className="activities-game">
                     <div className="image-box">
                        <img src="./assets/images/Stress Reduction Techniques.png" className="img-contain" alt="" />
                     </div>
                     <p>Stress Reduction Techniques</p>
                  </div>
               </div>
               <div className="col-md-3 col-sm-4 col-6">
                  <div className="activities-game">
                     <div className="image-box">
                        <img src="./assets/images/Cognitive Behavioral Therapy (CBT).png" className="img-contain" alt="" />
                     </div>
                     <p>Cognitive Behavioral Therapy (CBT)</p>
                  </div>
               </div>
               <div className="col-md-3 col-sm-4 col-6">
                  <div className="activities-game">
                     <div className="image-box">
                        <img src="./assets/images/Relaxation and Breathing Exercises.png" className="img-contain" alt="" />
                     </div>
                     <p>Relaxation and Breathing Exercises</p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div className="inner-box">
      <div className="row">
         <div className="col-lg-3 col-12">
            <h2>Strength & Conditioning</h2>
         </div>
         <div className="col-lg-9">
            <div className="row g-4">
               <div className="col-md-3 col-sm-4 col-6">
                  <div className="activities-game">
                     <div className="image-box">
                        <img src="./assets/images/Jogging.png" className="img-contain" alt="" />
                     </div>
                     <p>Jogging</p>
                  </div>
               </div>
               <div className="col-md-3 col-sm-4 col-6">
                  <div className="activities-game">
                     <div className="image-box">
                        <img src="./assets/images/Jogging.png" className="img-contain" alt="" />
                     </div>
                     <p>Jogging</p>
                  </div>
               </div>
               <div className="col-md-3 col-sm-4 col-6">
                  <div className="activities-game">
                     <div className="image-box">
                        <img src="./assets/images/Jogging.png" className="img-contain" alt="" />
                     </div>
                     <p>Jogging</p>
                  </div>
               </div>
               <div className="col-md-3 col-sm-4 col-6">
                  <div className="activities-game">
                     <div className="image-box">
                        <img src="./assets/images/Jogging.png" className="img-contain" alt="" />
                     </div>
                     <p>Jogging</p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div className="inner-box">
      <div className="row">
         <div className="col-lg-3 col-12">
            <h2>Swimming & Water</h2>
         </div>
         <div className="col-lg-9">
            <div className="row g-4">
               <div className="col-md-3 col-sm-4 col-6">
                  <div className="activities-game">
                     <div className="image-box">
                        <img src="./assets/images/Jogging.png" className="img-contain" alt="" />
                     </div>
                     <p>Jogging</p>
                  </div>
               </div>
               <div className="col-md-3 col-sm-4 col-6">
                  <div className="activities-game">
                     <div className="image-box">
                        <img src="./assets/images/Jogging.png" className="img-contain" alt="" />
                     </div>
                     <p>Jogging</p>
                  </div>
               </div>
               <div className="col-md-3 col-sm-4 col-6">
                  <div className="activities-game">
                     <div className="image-box">
                        <img src="./assets/images/Jogging.png" className="img-contain" alt="" />
                     </div>
                     <p>Jogging</p>
                  </div>
               </div>
               <div className="col-md-3 col-sm-4 col-6">
                  <div className="activities-game">
                     <div className="image-box">
                        <img src="./assets/images/Jogging.png" className="img-contain" alt="" />
                     </div>
                     <p>Jogging</p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div className="inner-box">
      <div className="row">
         <div className="col-lg-3 col-12">
            <h2>Team Sports</h2>
         </div>
         <div className="col-lg-9">
            <div className="row g-4">
               <div className="col-md-3 col-sm-4 col-6">
                  <div className="activities-game">
                     <div className="image-box">
                        <img src="./assets/images/Jogging.png" className="img-contain" alt="" />
                     </div>
                     <p>Jogging</p>
                  </div>
               </div>
               <div className="col-md-3 col-sm-4 col-6">
                  <div className="activities-game">
                     <div className="image-box">
                        <img src="./assets/images/Jogging.png" className="img-contain" alt="" />
                     </div>
                     <p>Jogging</p>
                  </div>
               </div>
               <div className="col-md-3 col-sm-4 col-6">
                  <div className="activities-game">
                     <div className="image-box">
                        <img src="./assets/images/Jogging.png" className="img-contain" alt="" />
                     </div>
                     <p>Jogging</p>
                  </div>
               </div>
               <div className="col-md-3 col-sm-4 col-6">
                  <div className="activities-game">
                     <div className="image-box">
                        <img src="./assets/images/Jogging.png" className="img-contain" alt="" />
                     </div>
                     <p>Jogging</p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div className="inner-box">
      <div className="row">
         <div className="col-lg-3 col-12">
            <h2>Outdoor Adventures</h2>
         </div>
         <div className="col-lg-9">
            <div className="row g-4">
               <div className="col-md-3 col-sm-4 col-6">
                  <div className="activities-game">
                     <div className="image-box">
                        <img src="./assets/images/Jogging.png" className="img-contain" alt="" />
                     </div>
                     <p>Jogging</p>
                  </div>
               </div>
               <div className="col-md-3 col-sm-4 col-6">
                  <div className="activities-game">
                     <div className="image-box">
                        <img src="./assets/images/Jogging.png" className="img-contain" alt="" />
                     </div>
                     <p>Jogging</p>
                  </div>
               </div>
               <div className="col-md-3 col-sm-4 col-6">
                  <div className="activities-game">
                     <div className="image-box">
                        <img src="./assets/images/Jogging.png" className="img-contain" alt="" />
                     </div>
                     <p>Jogging</p>
                  </div>
               </div>
               <div className="col-md-3 col-sm-4 col-6">
                  <div className="activities-game">
                     <div className="image-box">
                        <img src="./assets/images/Jogging.png" className="img-contain" alt="" />
                     </div>
                     <p>Jogging</p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div className="inner-box">
      <div className="row">
         <div className="col-lg-3 col-12">
            <h2>Martial Arts</h2>
         </div>
         <div className="col-lg-9">
            <div className="row g-4">
               <div className="col-md-3 col-sm-4 col-6">
                  <div className="activities-game">
                     <div className="image-box">
                        <img src="./assets/images/Jogging.png" className="img-contain" alt="" />
                     </div>
                     <p>Jogging</p>
                  </div>
               </div>
               <div className="col-md-3 col-sm-4 col-6">
                  <div className="activities-game">
                     <div className="image-box">
                        <img src="./assets/images/Jogging.png" className="img-contain" alt="" />
                     </div>
                     <p>Jogging</p>
                  </div>
               </div>
               <div className="col-md-3 col-sm-4 col-6">
                  <div className="activities-game">
                     <div className="image-box">
                        <img src="./assets/images/Jogging.png" className="img-contain" alt="" />
                     </div>
                     <p>Jogging</p>
                  </div>
               </div>
               <div className="col-md-3 col-sm-4 col-6">
                  <div className="activities-game">
                     <div className="image-box">
                        <img src="./assets/images/Jogging.png" className="img-contain" alt="" />
                     </div>
                     <p>Jogging</p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div className="inner-box">
      <div className="row">
         <div className="col-lg-3 col-12">
            <h2>Dance & Fitness Classes</h2>
         </div>
         <div className="col-lg-9">
            <div className="row g-4">
               <div className="col-md-3 col-sm-4 col-6">
                  <div className="activities-game">
                     <div className="image-box">
                        <img src="./assets/images/Jogging.png" className="img-contain" alt="" />
                     </div>
                     <p>Jogging</p>
                  </div>
               </div>
               <div className="col-md-3 col-sm-4 col-6">
                  <div className="activities-game">
                     <div className="image-box">
                        <img src="./assets/images/Jogging.png" className="img-contain" alt="" />
                     </div>
                     <p>Jogging</p>
                  </div>
               </div>
               <div className="col-md-3 col-sm-4 col-6">
                  <div className="activities-game">
                     <div className="image-box">
                        <img src="./assets/images/Jogging.png" className="img-contain" alt="" />
                     </div>
                     <p>Jogging</p>
                  </div>
               </div>
               <div className="col-md-3 col-sm-4 col-6">
                  <div className="activities-game">
                     <div className="image-box">
                        <img src="./assets/images/Jogging.png" className="img-contain" alt="" />
                     </div>
                     <p>Jogging</p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div className="inner-box">
      <div className="row">
         <div className="col-lg-3 col-12">
            <h2>Winter Sports</h2>
         </div>
         <div className="col-lg-9">
            <div className="row g-4">
               <div className="col-md-3 col-sm-4 col-6">
                  <div className="activities-game">
                     <div className="image-box">
                        <img src="./assets/images/Jogging.png" className="img-contain" alt="" />
                     </div>
                     <p>Jogging</p>
                  </div>
               </div>
               <div className="col-md-3 col-sm-4 col-6">
                  <div className="activities-game">
                     <div className="image-box">
                        <img src="./assets/images/Jogging.png" className="img-contain" alt="" />
                     </div>
                     <p>Jogging</p>
                  </div>
               </div>
               <div className="col-md-3 col-sm-4 col-6">
                  <div className="activities-game">
                     <div className="image-box">
                        <img src="./assets/images/Jogging.png" className="img-contain" alt="" />
                     </div>
                     <p>Jogging</p>
                  </div>
               </div>
               <div className="col-md-3 col-sm-4 col-6">
                  <div className="activities-game">
                     <div className="image-box">
                        <img src="./assets/images/Jogging.png" className="img-contain" alt="" />
                     </div>
                     <p>Jogging</p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div className="inner-box">
      <div className="row">
         <div className="col-lg-3 col-12">
            <h2>Diet & Nutrition</h2>
         </div>
         <div className="col-lg-9">
            <div className="row g-4">
               <div className="col-md-3 col-sm-4 col-6">
                  <div className="activities-game">
                     <div className="image-box">
                        <img src="./assets/images/Jogging.png" className="img-contain" alt="" />
                     </div>
                     <p>Jogging</p>
                  </div>
               </div>
               <div className="col-md-3 col-sm-4 col-6">
                  <div className="activities-game">
                     <div className="image-box">
                        <img src="./assets/images/Jogging.png" className="img-contain" alt="" />
                     </div>
                     <p>Jogging</p>
                  </div>
               </div>
               <div className="col-md-3 col-sm-4 col-6">
                  <div className="activities-game">
                     <div className="image-box">
                        <img src="./assets/images/Jogging.png" className="img-contain" alt="" />
                     </div>
                     <p>Jogging</p>
                  </div>
               </div>
               <div className="col-md-3 col-sm-4 col-6">
                  <div className="activities-game">
                     <div className="image-box">
                        <img src="./assets/images/Jogging.png" className="img-contain" alt="" />
                     </div>
                     <p>Jogging</p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div className="inner-box">
      <div className="row">
         <div className="col-lg-3 col-12">
            <h2>Water Sports</h2>
         </div>
         <div className="col-lg-9">
            <div className="row g-4">
               <div className="col-md-3 col-sm-4 col-6">
                  <div className="activities-game">
                     <div className="image-box">
                        <img src="./assets/images/Jogging.png" className="img-contain" alt="" />
                     </div>
                     <p>Jogging</p>
                  </div>
               </div>
               <div className="col-md-3 col-sm-4 col-6">
                  <div className="activities-game">
                     <div className="image-box">
                        <img src="./assets/images/Jogging.png" className="img-contain" alt="" />
                     </div>
                     <p>Jogging</p>
                  </div>
               </div>
               <div className="col-md-3 col-sm-4 col-6">
                  <div className="activities-game">
                     <div className="image-box">
                        <img src="./assets/images/Jogging.png" className="img-contain" alt="" />
                     </div>
                     <p>Jogging</p>
                  </div>
               </div>
               <div className="col-md-3 col-sm-4 col-6">
                  <div className="activities-game">
                     <div className="image-box">
                        <img src="./assets/images/Jogging.png" className="img-contain" alt="" />
                     </div>
                     <p>Jogging</p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</> */
