import React from "react";

const ErrorPage = () => {
  return (
    <div className="highlight-msg">
      <a href="/" className="logo-box">
        <img src="/assets/images/logo.png" alt="" className="img-contain" />
      </a>
      <div className="text">
        <h1 className="error-color">Error !!</h1>
        <p>Sorry, but something went wrong please try again after sometime.</p>
        <a href="/login" className="main-btn">Continue button</a>
      </div>
    </div>
  );
};

export default ErrorPage;
