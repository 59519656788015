import React from "react";
import ProfileMenu from "./ProfileMenu";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getAttendeesEvent } from "../../store/action/eventAction";
import SkeletonMyEventList from "../../Skeleton/SkeletonMyEventList";

const AttendedEvent = () => {
  // const errorImg = "/assets/images/no_image.png";
  // const errorImg = "/assets/images/no-image-icon.png";
  const errorImg = "/assets/images/no-image.png";

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { attendeesEvent } = useSelector((state) => state.attendeesEvent);

  // Loading state to determine whether to show content or skeleton
  const [loading, setLoading] = React.useState(true);
  const [showFullAddress, setShowFullAddress] = React.useState(false);
  const [addressVisibility, setAddressVisibility] = React.useState({});

  // dateTime format
  const formatDateTime = (dateTimeString) => {
    const options = {
      weekday: "short",
      month: "short",
      day: "numeric",
      // hour: "numeric",
      // minute: "numeric",
      // hour12: true,
    };

    const formattedDate = new Date(dateTimeString).toLocaleString(
      "en-US",
      options
    );
    const commaIndex = formattedDate.indexOf(
      ",",
      formattedDate.indexOf(",") + 1
    );
    if (commaIndex !== -1) {
      return (
        formattedDate.substring(0, commaIndex) +
        " -" +
        formattedDate.substring(commaIndex + 1)
      );
    }
    return formattedDate;
  };

  const handleDetailClick = (myEventDel) => {
    navigate(`/event-details/${myEventDel.vEvent.replace(/\s+/g, "-")}`, {
      state: { iEventId: myEventDel.iEventId },
    });
  };

  const handleAddressClick = (eventId) => {
    setAddressVisibility((prevVisibility) => ({
      ...prevVisibility,
      [eventId]: !prevVisibility[eventId],
    }));
    setShowFullAddress((prevShowFullAddress) =>
      prevShowFullAddress === eventId ? null : eventId
    );
  };

  // Simulate an API call delay with useEffect
  React.useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // Adjust the delay as needed or remove this if you have a real API call
    return () => clearTimeout(timer);
  }, []);

  // for attended event list
  React.useEffect(() => {
    const vUniqueCode =
      // sessionStorage.getItem("vUniqueCode") ||
      localStorage.getItem("vUniqueCode");
    const code = localStorage.getItem("code");
    if (!attendeesEvent) {
      dispatch(
        getAttendeesEvent({
          vUniqueCode: vUniqueCode ? vUniqueCode : code,
        })
      );
    }
  }, [attendeesEvent, dispatch]);

  return (
    <React.Fragment>
      <div className="profile-page">
        <div className="container-fluid">
          <div className="row g-4">
            <div className="col-lg-2 col-md-3">
              <ProfileMenu />
            </div>

            <div className="col-lg-10 col-md-9 col-12">
              <div className="attend-event-page white-card">
                <div className="title-top">
                  <h1>Attended Events</h1>
                </div>
                {loading &&
                attendeesEvent &&
                attendeesEvent.attendees_event.length > 0 ? (
                  // Show Skeleton while loading
                  <SkeletonMyEventList count={12} />
                ) : (
                  <div>
                    {attendeesEvent &&
                    attendeesEvent.attendees_event.length > 0 ? (
                      <div className="row g-xl-4 g-3">
                        {attendeesEvent.attendees_event.map(
                          (attendCust, attendees) => (
                            <div
                              className="col-xl-3 col-lg-4 col-sm-6 col-12"
                              key={attendees}
                            >
                              <div
                                className="box"
                                // onClick={() => handleDetailClick(attendCust)}
                                style={{ cursor: "pointer" }}
                              >
                                <img
                                  src={
                                    attendCust
                                      ? attendCust.vWebpImage
                                      : errorImg
                                  }
                                  alt={attendCust.vImageAlt}
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = errorImg;
                                  }}
                                  style={{
                                    objectFit:
                                      attendCust.vWebpImage.split("/").pop() ===
                                      "null"
                                        ? "contain"
                                        : "cover",
                                  }}
                                  onClick={() => handleDetailClick(attendCust)}
                                />
                                <Link to={""} className="share">
                                  <i className="fas fa-share"></i>
                                </Link>
                                <div className="text">
                                  <div
                                    className="time"
                                    onClick={() =>
                                      handleDetailClick(attendCust)
                                    }
                                  >
                                    {formatDateTime(attendCust.dtEvent)}
                                  </div>
                                  <h2
                                    onClick={() =>
                                      handleDetailClick(attendCust)
                                    }
                                  >
                                    {attendCust.vEvent}
                                  </h2>
                                  <p
                                    className="description"
                                    onClick={() =>
                                      handleDetailClick(attendCust)
                                    }
                                  >
                                    {attendCust.tDescription}
                                  </p>
                                  {attendCust.categories &&
                                    attendCust.categories.map(
                                      (eventCate, category) => (
                                        <React.Fragment key={category}>
                                          <p
                                            className="location"
                                            onClick={() =>
                                              handleDetailClick(attendCust)
                                            }
                                            style={{
                                              marginBottom: "10px",
                                              fontWeight: "bold",
                                              color: "#fd4700",
                                            }}
                                          >
                                            {eventCate.vCategory}
                                          </p>
                                          <p
                                            className="point-location"
                                            onClick={() => {
                                              handleAddressClick(
                                                attendCust.iEventId
                                              );
                                            }}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="16"
                                              height="16"
                                              fill="currentColor"
                                              className="bi bi-geo-alt-fill"
                                              viewBox="0 0 16 16"
                                            >
                                              <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                                            </svg>
                                            &nbsp;
                                            {/* {attendCust.vLocation} */}
                                            {addressVisibility[
                                              attendCust.iEventId
                                            ]
                                              ? attendCust.vLocation
                                              : attendCust.vLocation.slice(
                                                  0,
                                                  20
                                                ) +
                                                (attendCust.vLocation.length >
                                                20
                                                  ? "..."
                                                  : "")}
                                          </p>
                                        </React.Fragment>
                                      )
                                    )}
                                  <p
                                    className="attendeds"
                                    onClick={() =>
                                      handleDetailClick(attendCust)
                                    }
                                  >
                                    {attendCust.attendees_count} attendees
                                  </p>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    ) : attendeesEvent &&
                      attendeesEvent.attendees_event.length === 0 ? (
                      <div className="no-data-found">
                        <p>No Data Found</p>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AttendedEvent;
