import React from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const GoogleVerifyPage = () => {
   const navigate = useNavigate();

   // login with Google
   const loginWithGoogle = React.useCallback(
      async (values) => {
         try {
            const response = await axios.post(
               `${process.env.REACT_APP_API_URL}/api/customer/login`,
               values
            );
            if (response.status === 200) {
               sessionStorage.setItem('vUniqueCode', response.data.data);
               navigate("/profile");
            }
         } catch (error) {
            if (error.response && error.response.status === 404) {
               alert("Customer not found.");
               navigate("/login");
            }
         }
      },
      [navigate]
   );

   // register with Google
   const registerWithGoogle = React.useCallback(
      async (values) => {
         try {
            try {
               // Attempt to log in with the provided email
               const loginResponse = await axios.post(
                  `${process.env.REACT_APP_API_URL}/api/customer/login`,
                  values
               );

               if (loginResponse.status === 200) {
                  sessionStorage.setItem('vUniqueCode', loginResponse.data.data);
                  navigate("/profile");
               }
            } catch (error) {
               if (error.response && error.response.status === 404) {
                  // Customer not found, proceed with registration
                  const response = await axios.post(
                     `${process.env.REACT_APP_API_URL}/api/customer/google_register`,
                     values
                  );

                  if (response.status === 200) {
                     navigate("/account-verified");
                  }
               }
            }
         } catch (error) {
            if (error.response && error.response.status === 422) {
               alert("User Email Already Exists.");
               navigate("/login");
            }
         }
      },
      [navigate]
   );

   // verify userInfo through google auth
   React.useEffect(() => {
      if (window.location.href.indexOf("#") > -1) {
         const currentUrl = window.location.href;
         const temp = currentUrl.split("#");
         const content = temp[1].split("&");
         const access_token = content[1].split("=")[1];

         localStorage.setItem("access_token", access_token);

         fetch("https://www.googleapis.com/oauth2/v3/userinfo", {
            headers: {
               Authorization: "Bearer " + access_token,
            },
         })
            .then((response) => response.json())
            .then((data) => {
               const values = {
                  Name: data.name,
                  vEmail: data.email,
                  // vEmail: "marvel@mailinator.com",
               };

               registerWithGoogle(values);
            })
            .catch((error) => {
               console.error("Error fetching user data:", error);
            });
      }
   }, [registerWithGoogle]);


   return (
      <React.Fragment>
         {/* Your component JSX goes here */}
      </React.Fragment>
   );
};

export default GoogleVerifyPage;











// --------- new exist cust login ---------
// import React from "react";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";

// const GoogleVerifyPage = () => {
//    const navigate = useNavigate();

//    // Define the loginWithGoogle function
//    const loginWithGoogle = React.useCallback(
//       async (values) => {
//          try {
//             const response = await axios.post(
//                `${process.env.REACT_APP_API_URL}/api/customer/login`,
//                values
//             );
//             if (response.status === 200) {
//                sessionStorage.setItem('vUniqueCode', response.data.data);
//                navigate("/profile");
//             }
//          } catch (error) {
//             if (error.response && error.response.status === 404) {
//                alert("Customer not found.");
//                navigate("/login");
//             }
//          }
//       },
//       [navigate]
//    );

//    const registerWithGoogle = React.useCallback(
//       async (values) => {
//          try {
//             // Check if the user with the given email already exists
//             const checkUserResponse = await axios.post(
//                `${process.env.REACT_APP_API_URL}/api/customer/login`,
//                { vEmail: values.vEmail }
//             );

//             if (checkUserResponse.status === 200) {
//                // User exists, call loginWithGoogle to log them in
//                const loginValues = {
//                   vEmail: values.vEmail,
//                   vPassword: "", // You may need to provide the password
//                };
//                loginWithGoogle(loginValues);
//                navigate("/profile");
//             } else {
//                // User doesn't exist, proceed with registration
//                const response = await axios.post(
//                   `${process.env.REACT_APP_API_URL}/api/customer/google_register`,
//                   values
//                );

//                if (response.status === 200) {
//                   navigate("/account-verified");
//                }
//             }
//          } catch (error) {
//             if (error.response && error.response.status === 422) {
//                alert("User Email Already Exists.");
//                navigate("/login");
//             }
//          }
//       },
//       [navigate, loginWithGoogle]
//    );

//    React.useEffect(() => {
//       if (window.location.href.indexOf("#") > -1) {
//          const currentUrl = window.location.href;
//          const temp = currentUrl.split("#");
//          const content = temp[1].split("&");
//          const access_token = content[1].split("=")[1];

//          localStorage.setItem("access_token", access_token);

//          fetch("https://www.googleapis.com/oauth2/v3/userinfo", {
//             headers: {
//                Authorization: "Bearer " + access_token,
//             },
//          })
//             .then((response) => response.json())
//             .then((data) => {
//                const values = {
//                   Name: data.name,
//                   vEmail: "frank10@mailinator.com",
//                };

//                registerWithGoogle(values);
//             })
//             .catch((error) => {
//                console.error("Error fetching user data:", error);
//             });
//       }
//    }, [registerWithGoogle]);

//    return (
//       <React.Fragment>
//          {/* Your component JSX goes here */}
//       </React.Fragment>
//    );
// };

// export default GoogleVerifyPage;





// --------- updated direct login ---------
// import React from "react";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";

// const GoogleVerifyPage = () => {
//    const navigate = useNavigate();

//    // Define the loginWithGoogle function
//    const loginWithGoogle = React.useCallback(
//       async (values) => {
//          try {
//             const response = await axios.post(
//                `${process.env.REACT_APP_API_URL}/api/customer/login`,
//                values
//             );
//             if (response.status === 200) {
//                sessionStorage.setItem('vUniqueCode', response.data.data);
//                navigate("/profile");
//             }
//          } catch (error) {
//             if (error.response && error.response.status === 404) {
//                alert("Customer not found.");
//                navigate("/login");
//             }
//          }
//       },
//       [navigate]
//    );

//    const registerWithGoogle = React.useCallback(
//       async (values) => {
//          try {
//             const response = await axios.post(
//                `${process.env.REACT_APP_API_URL}/api/customer/google_register`,
//                values
//             );
//             if (response.status === 200) {
//                // Success: Call the loginWithGoogle function directly
//                const loginValues = {
//                   vEmail: values.vEmail,
//                   vPassword: "", // You may need to provide the password
//                };
//                loginWithGoogle(loginValues);
//                navigate("/account-verified");
//             }
//          } catch (error) {
//             if (error.response && error.response.status === 422) {
//                alert("User Email Already Exists.");
//                navigate("/login");
//             }
//          }
//       },
//       [navigate, loginWithGoogle]
//    );

//    React.useEffect(() => {
//       if (window.location.href.indexOf("#") > -1) {
//          const currentUrl = window.location.href;
//          const temp = currentUrl.split("#");
//          const content = temp[1].split("&");
//          const access_token = content[1].split("=")[1];

//          localStorage.setItem("access_token", access_token);

//          fetch("https://www.googleapis.com/oauth2/v3/userinfo", {
//             headers: {
//                Authorization: "Bearer " + access_token,
//             },
//          })
//             .then((response) => response.json())
//             .then((data) => {
//                const values = {
//                   Name: data.name,
//                   vEmail: "chaplin@mailinator.com",
//                };

//                registerWithGoogle(values);
//             })
//             .catch((error) => {
//                console.error("Error fetching user data:", error);
//             });
//       }
//    }, [registerWithGoogle]);

//    return (
//       <React.Fragment>
//          {/* Your component JSX goes here */}
//       </React.Fragment>
//    );
// };

// export default GoogleVerifyPage;