import React from "react";
import { Link, useLocation } from "react-router-dom";

const ProfileMenu = () => {

   const location = useLocation();
   const [activeTab, setActiveTab] = React.useState("");

   const handleTabClick = (tabName) => {
      setActiveTab(tabName);
   }

   const menuItems = [
      { path: "/profile", label: "My Profile", iconClass: "fal fa-user" },
      { path: "/my-event", label: "My Event", iconClass: "fal fa-calendar-star" },
      // { path: "/my-group", label: "My Group", iconClass: "fal fa-user-friends" },
      { path: "/attended-event", label: "Attended Event", iconClass: "fal fa-heart" },
      // { path: "/onetoone-event", label: "One To One", iconClass: "fal fa-calendar-star" },
      { path: "/setting", label: "Settings", iconClass: "fal fa-cog" },
      { path: "/notification", label: "Notification", iconClass: "fal fa-bell" },
   ];

   return (
      <React.Fragment>
         <div className="profile-links white-card">
            {menuItems.map((item) => (
               <ul key={item.path}>
                  <li
                     className={location && location.pathname === item.path ? "active" : ""}
                  >
                     <Link to={item.path} onClick={() => handleTabClick(item.path)}>
                        <i className={item.iconClass}></i>
                        <span>{item.label}</span>
                     </Link>
                  </li>
               </ul>
            ))}

         </div>
      </React.Fragment>
   )
}

export default ProfileMenu;