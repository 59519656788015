import React from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllEventReview } from "../../store/action/eventAction";

const ReviewListing = () => {
  // const errorImg = "/assets/images/no_image.png";
  // const errorImg = "/assets/images/no-image-icon.png";
  const errorImg = "/assets/images/no-image.png";

  const { state } = useLocation();
  const { iEventId } = state;

  const dispatch = useDispatch();
  const { allEventReview } = useSelector((state) => state.allEventReview);

  // dateTime format
  const formatDateTime = (dateTimeString) => {
    const options = {
      weekday: "short",
      month: "short",
      day: "numeric",
    };

    const formattedDate = new Date(dateTimeString).toLocaleString(
      "en-US",
      options
    );
    const commaIndex = formattedDate.indexOf(
      ",",
      formattedDate.indexOf(",") + 1
    );
    if (commaIndex !== -1) {
      return (
        formattedDate.substring(0, commaIndex) +
        " -" +
        formattedDate.substring(commaIndex + 1)
      );
    }
    return formattedDate;
  };

  // for all event Review
  const fetchAllEventReview = React.useCallback(
    (eventId) => {
      dispatch(getAllEventReview({ iEventId: eventId }));
    },
    [dispatch]
  );

  React.useEffect(() => {
    if (iEventId) {
      fetchAllEventReview(iEventId);
    }
  }, [iEventId, fetchAllEventReview]);

  // handle show full/less description
  const [showMoreStates, setShowMoreStates] = React.useState({});
  const toggleShowMore = (index) => {
    setShowMoreStates((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  return (
    <React.Fragment>
      <section
        className="inner-page-breadcamp event-banner"
        style={{ backgroundImage: "url(assets/images/event-banner.png)" }}
      >
        <div className="container">
          {allEventReview && (
            <div className="brad-camp-detail">
              <p className="sub-title">{allEventReview.vEvent}</p>
            </div>
          )}
        </div>
      </section>

      <div className="section-with-background">
        <div className="container">
          <div className="review-boxes p-100">
            <div className="top-bar">
              <h2>Reviews</h2>
            </div>

            <div className="inner-box row gy-lg-5 gy-4">
              {allEventReview &&
              allEventReview.reviews &&
              allEventReview.reviews.length > 0 ? (
                allEventReview.reviews.map((allReview, reviewList) => (
                  <div className="col-lg-4 col-sm-6" key={reviewList}>
                    <div className="box">
                      <div className="review-msg">
                        <svg
                          className="quote-icon"
                          xmlns="http://www.w3.org/2000/svg"
                          width="19"
                          height="14"
                          viewBox="0 0 19 14"
                          fill="none"
                        >
                          <path
                            d="M7.23541 3.64151C7.23541 8.23576 7.11326 9.22705 6.34273 10.7712C5.71316 12.0294 4.70772 12.9825 3.49555 13.4877C2.88477 13.7451 1.35312 14.0787 1.25915 13.9833C1.15579 13.888 0.761127 11.6862 0.826903 11.6195C0.86449 11.5909 1.13699 11.4765 1.43768 11.3621C3.0633 10.7521 3.909 9.67504 4.01236 8.09279L4.06874 7.33979H2.02967H0V3.67011V0.000427246H3.61771H7.23541V3.64151ZM18.953 4.02278C18.906 8.35968 18.8872 8.61703 18.361 10.0849C17.7127 11.9054 16.3971 13.1446 14.5178 13.7165C14.1607 13.8308 13.6533 13.9262 13.3902 13.9452L12.9298 13.9643L12.7136 12.8681C12.4599 11.5146 12.4599 11.5337 12.6761 11.5337C12.77 11.5337 13.1929 11.3621 13.6157 11.1429C15.0252 10.428 15.6266 9.56066 15.7582 8.04513L15.8145 7.33979H13.7755H11.7458V3.67011V0.000427246H15.3729H19L18.953 4.02278Z"
                            fill="#151515"
                          />
                        </svg>
                        {/* <h3>{allReview.tDescription}</h3> */}
                        <h3>
                          {showMoreStates[reviewList]
                            ? allReview.tDescription
                            : allReview.tDescription.length > 100
                            ? `${allReview.tDescription.slice(0, 100)} ...`
                            : allReview.tDescription}
                          <span>
                            {allReview.tDescription.length > 100 && (
                              <button
                                className="morelink"
                                onClick={() => toggleShowMore(reviewList)}
                                style={{
                                  border: "none",
                                  color: "#F87217",
                                  background: "none",
                                }}
                              >
                                {showMoreStates[reviewList]
                                  ? "Read Less"
                                  : "Read More"}
                              </button>
                            )}
                          </span>
                        </h3>

                        <div className="arrow-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="42"
                            height="37"
                            viewBox="0 0 42 37"
                            fill="none"
                          >
                            <path
                              d="M21.5008 36.1892L0.865978 1.10425L41.5679 0.776495L21.5008 36.1892Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                      </div>

                      {/* customer info */}
                      <div className="review-user">
                        <img
                          className="img-cover"
                          src={
                            allReview.customer
                              ? allReview.customer.vWebpImage
                              : errorImg
                          }
                          alt=""
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = errorImg;
                          }}
                          style={{
                            objectFit:
                              allReview.customer &&
                              allReview.customer.vWebpImage.split("/").pop() ===
                                "null"
                                ? "contain"
                                : "cover",
                          }}
                        />
                        <div className="u-title">
                          <p className="name">
                            {allReview.customer.vFirstName}{" "}
                            {allReview.customer.vLastName}
                          </p>
                          <p className="sub-n">
                            {formatDateTime(allReview.dDateTime)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="col-12">
                  <p>No review available</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ReviewListing;

// Extra code
/* <>
   <div className="col-lg-4 col-sm-6">
      <div className="box">
         <div className="review-msg">
            <svg className="quote-icon" xmlns="http://www.w3.org/2000/svg" width="19" height="14" viewBox="0 0 19 14" fill="none">
               <path d="M7.23541 3.64151C7.23541 8.23576 7.11326 9.22705 6.34273 10.7712C5.71316 12.0294 4.70772 12.9825 3.49555 13.4877C2.88477 13.7451 1.35312 14.0787 1.25915 13.9833C1.15579 13.888 0.761127 11.6862 0.826903 11.6195C0.86449 11.5909 1.13699 11.4765 1.43768 11.3621C3.0633 10.7521 3.909 9.67504 4.01236 8.09279L4.06874 7.33979H2.02967H0V3.67011V0.000427246H3.61771H7.23541V3.64151ZM18.953 4.02278C18.906 8.35968 18.8872 8.61703 18.361 10.0849C17.7127 11.9054 16.3971 13.1446 14.5178 13.7165C14.1607 13.8308 13.6533 13.9262 13.3902 13.9452L12.9298 13.9643L12.7136 12.8681C12.4599 11.5146 12.4599 11.5337 12.6761 11.5337C12.77 11.5337 13.1929 11.3621 13.6157 11.1429C15.0252 10.428 15.6266 9.56066 15.7582 8.04513L15.8145 7.33979H13.7755H11.7458V3.67011V0.000427246H15.3729H19L18.953 4.02278Z" fill="#151515" />
            </svg>
            <h3>Lorem ipsum dolor sit amet consectetur. Dapibus ipsum tortor volutpat vulputate elit volutpat. Eu porttitor tortor maecenas ac augue eget augue ullamcorper nunc.</h3>
            <div className="arrow-icon">
               <svg xmlns="http://www.w3.org/2000/svg" width="42" height="37" viewBox="0 0 42 37" fill="none">
                  <path d="M21.5008 36.1892L0.865978 1.10425L41.5679 0.776495L21.5008 36.1892Z" fill="white" />
               </svg>
            </div>
         </div>
         <div className="review-user">
            <img src="/assets/images/user-profile.png" alt="" className="img-cover" />
            <div className="u-title">
               <p className="name">John C. Tuggle</p>
               <p className="sub-n">John C. Tuggle</p>
            </div>
         </div>

      </div>
   </div>
   <div className="col-lg-4 col-sm-6">
      <div className="box">
         <div className="review-msg">
            <svg className="quote-icon" xmlns="http://www.w3.org/2000/svg" width="19" height="14" viewBox="0 0 19 14" fill="none">
               <path d="M7.23541 3.64151C7.23541 8.23576 7.11326 9.22705 6.34273 10.7712C5.71316 12.0294 4.70772 12.9825 3.49555 13.4877C2.88477 13.7451 1.35312 14.0787 1.25915 13.9833C1.15579 13.888 0.761127 11.6862 0.826903 11.6195C0.86449 11.5909 1.13699 11.4765 1.43768 11.3621C3.0633 10.7521 3.909 9.67504 4.01236 8.09279L4.06874 7.33979H2.02967H0V3.67011V0.000427246H3.61771H7.23541V3.64151ZM18.953 4.02278C18.906 8.35968 18.8872 8.61703 18.361 10.0849C17.7127 11.9054 16.3971 13.1446 14.5178 13.7165C14.1607 13.8308 13.6533 13.9262 13.3902 13.9452L12.9298 13.9643L12.7136 12.8681C12.4599 11.5146 12.4599 11.5337 12.6761 11.5337C12.77 11.5337 13.1929 11.3621 13.6157 11.1429C15.0252 10.428 15.6266 9.56066 15.7582 8.04513L15.8145 7.33979H13.7755H11.7458V3.67011V0.000427246H15.3729H19L18.953 4.02278Z" fill="#151515" />
            </svg>
            <h3>Lorem ipsum dolor sit amet consectetur. Dapibus ipsum tortor volutpat vulputate elit volutpat. Eu porttitor tortor maecenas ac augue eget augue ullamcorper nunc.</h3>
            <div className="arrow-icon">
               <svg xmlns="http://www.w3.org/2000/svg" width="42" height="37" viewBox="0 0 42 37" fill="none">
                  <path d="M21.5008 36.1892L0.865978 1.10425L41.5679 0.776495L21.5008 36.1892Z" fill="white" />
               </svg>
            </div>
         </div>
         <div className="review-user">
            <img src="/assets/images/Ellipse 126.png" alt="" className="img-cover" />
            <div className="u-title">
               <p className="name">John C. Tuggle</p>
               <p className="sub-n">John C. Tuggle</p>
            </div>
         </div>

      </div>
   </div>
   <div className="col-lg-4 col-sm-6">
      <div className="box">
         <div className="review-msg">
            <svg className="quote-icon" xmlns="http://www.w3.org/2000/svg" width="19" height="14" viewBox="0 0 19 14" fill="none">
               <path d="M7.23541 3.64151C7.23541 8.23576 7.11326 9.22705 6.34273 10.7712C5.71316 12.0294 4.70772 12.9825 3.49555 13.4877C2.88477 13.7451 1.35312 14.0787 1.25915 13.9833C1.15579 13.888 0.761127 11.6862 0.826903 11.6195C0.86449 11.5909 1.13699 11.4765 1.43768 11.3621C3.0633 10.7521 3.909 9.67504 4.01236 8.09279L4.06874 7.33979H2.02967H0V3.67011V0.000427246H3.61771H7.23541V3.64151ZM18.953 4.02278C18.906 8.35968 18.8872 8.61703 18.361 10.0849C17.7127 11.9054 16.3971 13.1446 14.5178 13.7165C14.1607 13.8308 13.6533 13.9262 13.3902 13.9452L12.9298 13.9643L12.7136 12.8681C12.4599 11.5146 12.4599 11.5337 12.6761 11.5337C12.77 11.5337 13.1929 11.3621 13.6157 11.1429C15.0252 10.428 15.6266 9.56066 15.7582 8.04513L15.8145 7.33979H13.7755H11.7458V3.67011V0.000427246H15.3729H19L18.953 4.02278Z" fill="#151515" />
            </svg>
            <h3>Lorem ipsum dolor sit amet consectetur. Dapibus ipsum tortor volutpat vulputate elit volutpat. Eu porttitor tortor maecenas ac augue eget augue ullamcorper nunc.</h3>
            <div className="arrow-icon">
               <svg xmlns="http://www.w3.org/2000/svg" width="42" height="37" viewBox="0 0 42 37" fill="none">
                  <path d="M21.5008 36.1892L0.865978 1.10425L41.5679 0.776495L21.5008 36.1892Z" fill="white" />
               </svg>
            </div>
         </div>
         <div className="review-user">
            <img src="/assets/images/Ellipse 126.png" alt="" className="img-cover" />
            <div className="u-title">
               <p className="name">John C. Tuggle</p>
               <p className="sub-n">John C. Tuggle</p>
            </div>
         </div>
      </div>
   </div>
   <div className="col-lg-4 col-sm-6">
      <div className="box">
         <div className="review-msg">
            <svg className="quote-icon" xmlns="http://www.w3.org/2000/svg" width="19" height="14" viewBox="0 0 19 14" fill="none">
               <path d="M7.23541 3.64151C7.23541 8.23576 7.11326 9.22705 6.34273 10.7712C5.71316 12.0294 4.70772 12.9825 3.49555 13.4877C2.88477 13.7451 1.35312 14.0787 1.25915 13.9833C1.15579 13.888 0.761127 11.6862 0.826903 11.6195C0.86449 11.5909 1.13699 11.4765 1.43768 11.3621C3.0633 10.7521 3.909 9.67504 4.01236 8.09279L4.06874 7.33979H2.02967H0V3.67011V0.000427246H3.61771H7.23541V3.64151ZM18.953 4.02278C18.906 8.35968 18.8872 8.61703 18.361 10.0849C17.7127 11.9054 16.3971 13.1446 14.5178 13.7165C14.1607 13.8308 13.6533 13.9262 13.3902 13.9452L12.9298 13.9643L12.7136 12.8681C12.4599 11.5146 12.4599 11.5337 12.6761 11.5337C12.77 11.5337 13.1929 11.3621 13.6157 11.1429C15.0252 10.428 15.6266 9.56066 15.7582 8.04513L15.8145 7.33979H13.7755H11.7458V3.67011V0.000427246H15.3729H19L18.953 4.02278Z" fill="#151515" />
            </svg>
            <h3>Lorem ipsum dolor sit amet consectetur. Dapibus ipsum tortor volutpat vulputate elit volutpat. Eu porttitor tortor maecenas ac augue eget augue ullamcorper nunc.</h3>
            <div className="arrow-icon">
               <svg xmlns="http://www.w3.org/2000/svg" width="42" height="37" viewBox="0 0 42 37" fill="none">
                  <path d="M21.5008 36.1892L0.865978 1.10425L41.5679 0.776495L21.5008 36.1892Z" fill="white" />
               </svg>
            </div>
         </div>
         <div className="review-user">
            <img src="/assets/images/testimonial-one.png" alt="" className="img-cover" />
            <div className="u-title">
               <p className="name">John C. Tuggle</p>
               <p className="sub-n">John C. Tuggle</p>
            </div>
         </div>

      </div>
   </div>
   <div className="col-lg-4 col-sm-6">
      <div className="box">
         <div className="review-msg">
            <svg className="quote-icon" xmlns="http://www.w3.org/2000/svg" width="19" height="14" viewBox="0 0 19 14" fill="none">
               <path d="M7.23541 3.64151C7.23541 8.23576 7.11326 9.22705 6.34273 10.7712C5.71316 12.0294 4.70772 12.9825 3.49555 13.4877C2.88477 13.7451 1.35312 14.0787 1.25915 13.9833C1.15579 13.888 0.761127 11.6862 0.826903 11.6195C0.86449 11.5909 1.13699 11.4765 1.43768 11.3621C3.0633 10.7521 3.909 9.67504 4.01236 8.09279L4.06874 7.33979H2.02967H0V3.67011V0.000427246H3.61771H7.23541V3.64151ZM18.953 4.02278C18.906 8.35968 18.8872 8.61703 18.361 10.0849C17.7127 11.9054 16.3971 13.1446 14.5178 13.7165C14.1607 13.8308 13.6533 13.9262 13.3902 13.9452L12.9298 13.9643L12.7136 12.8681C12.4599 11.5146 12.4599 11.5337 12.6761 11.5337C12.77 11.5337 13.1929 11.3621 13.6157 11.1429C15.0252 10.428 15.6266 9.56066 15.7582 8.04513L15.8145 7.33979H13.7755H11.7458V3.67011V0.000427246H15.3729H19L18.953 4.02278Z" fill="#151515" />
            </svg>
            <h3>Lorem ipsum dolor sit amet consectetur. Dapibus ipsum tortor volutpat vulputate elit volutpat. Eu porttitor tortor maecenas ac augue eget augue ullamcorper nunc.</h3>
            <div className="arrow-icon">
               <svg xmlns="http://www.w3.org/2000/svg" width="42" height="37" viewBox="0 0 42 37" fill="none">
                  <path d="M21.5008 36.1892L0.865978 1.10425L41.5679 0.776495L21.5008 36.1892Z" fill="white" />
               </svg>
            </div>
         </div>
         <div className="review-user">
            <img src="/assets/images/user-profile.png" alt="" className="img-cover" />
            <div className="u-title">
               <p className="name">John C. Tuggle</p>
               <p className="sub-n">John C. Tuggle</p>
            </div>
         </div>

      </div>
   </div>
</> */
