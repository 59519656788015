import {
    SETTING_LIST_REQUEST,
    SETTING_LIST_SUCCESS,
    SETTING_LIST_FAILURE,

    NOTIFICATION_UPDATE_REQUEST,
    NOTIFICATION_UPDATE_SUCCESS,
    NOTIFICATION_UPDATE_FAILURE,

} from "../constants/settingConstant";

import axios from "axios";


export const getSettingList = (data) => async (dispatch) => {
    try {
        dispatch({ type: SETTING_LIST_REQUEST });
        const resData = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/notification/get_all_data`, data
        );
        dispatch({ type: SETTING_LIST_SUCCESS, payload: resData.data.data });
    } catch (err) {
        dispatch({ type: SETTING_LIST_FAILURE });
    }
};

export const getNotificationUpdate = (data) => async (dispatch) => {
    try {
        dispatch({ type: NOTIFICATION_UPDATE_REQUEST });
        const resData = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/notification/update`, data
        );
        dispatch({ type: NOTIFICATION_UPDATE_SUCCESS, payload: resData.data.data });
    } catch (err) {
        dispatch({ type: NOTIFICATION_UPDATE_FAILURE });
    }
};