export const RECENT_NOTIFICATION_REQUEST = 'RECENT_NOTIFICATION_REQUEST'
export const RECENT_NOTIFICATION_SUCCESS = 'RECENT_NOTIFICATION_SUCCESS'
export const RECENT_NOTIFICATION_FAILURE = 'RECENT_NOTIFICATION_FAILURE'

export const VIEW_NOTIFICATION_REQUEST = 'VIEW_NOTIFICATION_REQUEST'
export const VIEW_NOTIFICATION_SUCCESS = 'VIEW_NOTIFICATION_SUCCESS'
export const VIEW_NOTIFICATION_FAILURE = 'VIEW_NOTIFICATION_FAILURE'

export const ADD_NOTIFICATION_REQUEST = 'ADD_NOTIFICATION_REQUEST'
export const ADD_NOTIFICATION_SUCCESS = 'ADD_NOTIFICATION_SUCCESS'
export const ADD_NOTIFICATION_FAILURE = 'ADD_NOTIFICATION_FAILURE'