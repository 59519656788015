import React from "react";

import Container from "../components/layouts/Container";
import Banner from "../components/Banner/Banner";
import ActivitySearch from "../components/Activity/Search";
import FeatureEvent from "../components/Event/FeatureEvent";
import NearByEvent from "../components/Event/NearByEvent";
import JoinNow from "../components/Common/JoinNow";
import FeatureCategory from "../components/Category/FeatureCategory";
import HowItWork from "../components/Common/HowItWork";
import Testimonial from "../components/Testimonial/testimonial";

const HomePage = () => {
  return (
    <Container>

      <Banner />
      <ActivitySearch />
      <FeatureEvent />
      <NearByEvent />
      <JoinNow />
      <FeatureCategory />
      <HowItWork />
      <Testimonial />

    </Container>
  );
}

export default HomePage;
