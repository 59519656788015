import {
  CUSTOMER_REGISTER_REQUEST,
  CUSTOMER_REGISTER_SUCCESS,
  CUSTOMER_REGISTER_FAILURE,
  CUSTOMER_LOGIN_REQUEST,
  CUSTOMER_LOGIN_SUCCESS,
  CUSTOMER_LOGIN_FAILURE,
  FORGET_PASSWORD_REQUEST,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAILURE,
  VERIFY_EMAIL_REQUEST,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILURE,
  CUSTOMER_PROFILE_REQUEST,
  CUSTOMER_PROFILE_SUCCESS,
  CUSTOMER_PROFILE_FAILURE,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILURE,
  PROFILE_UPDATE_REQUEST,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_FAILURE,
  CUSTOMER_SERVICE_REQUEST,
  CUSTOMER_SERVICE_SUCCESS,
  CUSTOMER_SERVICE_FAILURE,
  UPDATE_CUSTOMER_SERVICE_REQUEST,
  UPDATE_CUSTOMER_SERVICE_SUCCESS,
  UPDATE_CUSTOMER_SERVICE_FAILURE,
  CUSTOMER_EVENT_REQUEST,
  CUSTOMER_EVENT_SUCCESS,
  CUSTOMER_EVENT_FAILURE,
  GOOGLE_REGISTER_REQUEST,
  GOOGLE_REGISTER_SUCCESS,
  GOOGLE_REGISTER_FAILURE,
  CUSTOMER_ACCOUNT_DELETE_REQUEST,
  CUSTOMER_ACCOUNT_DELETE_SUCCESS,
  CUSTOMER_ACCOUNT_DELETE_FAILURE,
} from "../constants/customerConstant";

import axios from "axios";

export const getCustomerRegister = (data) => async (dispatch) => {
  try {
    dispatch({ type: CUSTOMER_REGISTER_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/customer/register`,
      data
    );
    dispatch({ type: CUSTOMER_REGISTER_SUCCESS, payload: resData.data.data });
  } catch (err) {
    dispatch({ type: CUSTOMER_REGISTER_FAILURE });
  }
};

export const getCustomerLogin = (data) => async (dispatch) => {
  try {
    dispatch({ type: CUSTOMER_LOGIN_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/customer/login`,
      data
    );
    dispatch({ type: CUSTOMER_LOGIN_SUCCESS, payload: resData.data.data });
  } catch (err) {
    dispatch({ type: CUSTOMER_LOGIN_FAILURE });
  }
};

export const getForgetPassword = (data) => async (dispatch) => {
  try {
    dispatch({ type: FORGET_PASSWORD_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/customer/forgot-password`,
      data
    );
    dispatch({ type: FORGET_PASSWORD_SUCCESS, payload: resData.data.data });
  } catch (err) {
    dispatch({ type: FORGET_PASSWORD_FAILURE });
  }
};

export const getEmailVerify = (data) => async (dispatch) => {
  try {
    dispatch({ type: VERIFY_EMAIL_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/verify`,
      data
    );
    dispatch({ type: VERIFY_EMAIL_SUCCESS, payload: resData.data.data });
  } catch (err) {
    dispatch({ type: VERIFY_EMAIL_FAILURE });
  }
};

export const getCustomerProfile = (data) => async (dispatch) => {
  try {
    dispatch({ type: CUSTOMER_PROFILE_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/customer/profile`,
      data
    );
    // console.log("resData---------------->", resData.data.data.vUniqueCode);
    dispatch({ type: CUSTOMER_PROFILE_SUCCESS, payload: resData.data.data });
    localStorage.setItem("vUniqueCode", resData.data.data.vUniqueCode);
  } catch (err) {
    dispatch({ type: CUSTOMER_PROFILE_FAILURE });
  }
};

export const getUpdatePassword = (data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PASSWORD_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/customer/update-password`,
      data
    );
    dispatch({ type: UPDATE_PASSWORD_SUCCESS, payload: resData.data.data });
  } catch (err) {
    dispatch({ type: UPDATE_PASSWORD_FAILURE });
  }
};

export const getUpdateProfile = (data) => async (dispatch) => {
  try {
    dispatch({ type: PROFILE_UPDATE_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/customer/update-profile`,
      data
    );
    dispatch({ type: PROFILE_UPDATE_SUCCESS, payload: resData.data.data });
  } catch (err) {
    dispatch({ type: PROFILE_UPDATE_FAILURE });
  }
};

export const getCustomerService = (data) => async (dispatch) => {
  try {
    dispatch({ type: CUSTOMER_SERVICE_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/customer/get_all_service`,
      data
    );
    dispatch({ type: CUSTOMER_SERVICE_SUCCESS, payload: resData.data.data });
  } catch (err) {
    dispatch({ type: CUSTOMER_SERVICE_FAILURE });
  }
};

export const getUpdateCustomerService = (data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_CUSTOMER_SERVICE_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/customer/update_customer_service`,
      data
    );
    dispatch({
      type: UPDATE_CUSTOMER_SERVICE_SUCCESS,
      payload: resData.data.data,
    });
  } catch (err) {
    dispatch({ type: UPDATE_CUSTOMER_SERVICE_FAILURE });
  }
};

export const getCustomerEvent = (data) => async (dispatch) => {
  try {
    dispatch({ type: CUSTOMER_EVENT_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/customer/get_all_customer_events`,
      data
    );
    dispatch({ type: CUSTOMER_EVENT_SUCCESS, payload: resData.data.data });
  } catch (err) {
    dispatch({ type: CUSTOMER_EVENT_FAILURE });
  }
};

export const getGoogleRegister = (data) => async (dispatch) => {
  try {
    dispatch({ type: GOOGLE_REGISTER_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/customer/google_register`,
      data
    );
    dispatch({ type: GOOGLE_REGISTER_SUCCESS, payload: resData.data.data });
  } catch (err) {
    dispatch({ type: GOOGLE_REGISTER_FAILURE });
  }
};

export const getCustomerAccountDelete = (data) => async (dispatch) => {
  try {
    dispatch({ type: CUSTOMER_ACCOUNT_DELETE_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/customer/delete`,
      data
    );
    dispatch({ type: CUSTOMER_ACCOUNT_DELETE_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: CUSTOMER_ACCOUNT_DELETE_FAILURE });
  }
};
