import {
    RECENT_NOTIFICATION_REQUEST,
    RECENT_NOTIFICATION_SUCCESS,
    RECENT_NOTIFICATION_FAILURE,

    VIEW_NOTIFICATION_REQUEST,
    VIEW_NOTIFICATION_SUCCESS,
    VIEW_NOTIFICATION_FAILURE,

    ADD_NOTIFICATION_REQUEST,
    ADD_NOTIFICATION_SUCCESS,
    ADD_NOTIFICATION_FAILURE,

} from "../constants/notificationConstant";

import axios from "axios";


export const getRecentNotification = (data) => async (dispatch) => {
    try {
        dispatch({ type: RECENT_NOTIFICATION_REQUEST });
        const resData = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/notificationAlert/get_recent_notification_alert`, data
        );
        dispatch({ type: RECENT_NOTIFICATION_SUCCESS, payload: resData.data.data });
    } catch (err) {
        dispatch({ type: RECENT_NOTIFICATION_FAILURE });
    }
};

export const getViewNotification = (data) => async (dispatch) => {
    try {
        dispatch({ type: VIEW_NOTIFICATION_REQUEST });
        const resData = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/notificationAlert/get_by_id`, data
        );
        dispatch({ type: VIEW_NOTIFICATION_SUCCESS, payload: resData.data.data });
    } catch (err) {
        dispatch({ type: VIEW_NOTIFICATION_FAILURE });
    }
};

export const getAddNotification = (data) => async (dispatch) => {
    try {
        dispatch({ type: ADD_NOTIFICATION_REQUEST });
        const resData = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/notificationAlert/add`, data
        );
        dispatch({ type: ADD_NOTIFICATION_SUCCESS, payload: resData.data.data });
    } catch (err) {
        dispatch({ type: ADD_NOTIFICATION_FAILURE });
    }
};