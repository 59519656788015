import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getRecommendedEvent } from "../../store/action/eventAction";

const SimilarEvent = () => {
  // const errorImg = "/assets/images/no_image.png"
//   const errorImg = "/assets/images/no-image-icon.png";
  const errorImg = "/assets/images/no-image.png";

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { recommendedEvent } = useSelector((state) => state.recommendedEvent);

  const handleDetailClick = (eventList) => {
    navigate(`/event-details/${eventList.vEvent.replace(/\s+/g, "-")}`, {
      state: { iEventId: eventList.iEventId },
    });
  };

  // dateTime format
  const formatDateTime = (dateTimeString) => {
    const options = {
      weekday: "short",
      month: "short",
      day: "numeric",
      // hour: "numeric",
      // minute: "numeric",
      // hour12: true,
    };

    const formattedDate = new Date(dateTimeString).toLocaleString(
      "en-US",
      options
    );
    const commaIndex = formattedDate.indexOf(
      ",",
      formattedDate.indexOf(",") + 1
    );
    if (commaIndex !== -1) {
      return (
        formattedDate.substring(0, commaIndex) +
        " -" +
        formattedDate.substring(commaIndex + 1)
      );
    }
    return formattedDate;
  };

  const { state } = useLocation();
  const { iEventId } = state ?? {};

  React.useEffect(() => {
    if (!recommendedEvent) {
      dispatch(
        getRecommendedEvent({
          iEventId: iEventId,
        })
      );
    }
  }, [recommendedEvent, iEventId, dispatch]);

  return (
    <React.Fragment>
      <section className="recommended-event p-100">
        <div className="container">
          <h2 className="sub-title">Recommended Event</h2>
          <div className="row gy-4 pt-40">
            {recommendedEvent && recommendedEvent.length > 0 ? (
              recommendedEvent &&
              recommendedEvent
                .slice(0, 4)
                .map((recommendEvent, recommEvent) => {
                  return (
                    <div
                      className="col-lg-3 col-md-6"
                      key={recommEvent}
                      onClick={() => handleDetailClick(recommendEvent)}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="event-box">
                        <div className="img-box">
                          <img
                            src={
                              recommendEvent
                                ? recommendEvent.vWebpImage
                                : errorImg
                            }
                            alt={recommendEvent.vImageAlt}
                            height="306"
                            width="220"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src = errorImg;
                            }}
                            style={{
                              objectFit:
                                recommendEvent.vWebpImage.split("/").pop() ===
                                "null"
                                  ? "contain"
                                  : "cover",
                            }}
                          />
                          <Link
                            to={`/event-details/${recommendEvent.vEvent.replace(
                              /\s+/g,
                              "-"
                            )}`}
                            state={{ iEventId: recommendEvent.iEventId }}
                            className="redirect-btn"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="37"
                              height="37"
                              viewBox="0 0 37 37"
                              fill="none"
                            >
                              <circle
                                cx="18.5"
                                cy="18.5"
                                r="18.5"
                                fill="white"
                              />
                              <path
                                d="M20.3889 15.7333V12L27 18.5333L20.3889 25.0667V21.3333C15.6667 21.3333 12.3611 22.7333 10 26L10.7556 23.2L10.9444 22.8267C11.7019 20.8975 12.9855 19.2135 14.6526 17.9614C16.3197 16.7093 18.3052 15.9381 20.3889 15.7333Z"
                                fill="#616161"
                              />
                            </svg>
                          </Link>
                        </div>
                        <div className="contain">
                          <p className="date-time">
                            {formatDateTime(recommendEvent.dtEvent)}
                          </p>
                          <h2>{recommendEvent.vEvent}</h2>
                          <p className="description">
                            {recommendEvent.tDescription}
                          </p>
                          {recommendEvent.categories &&
                            recommendEvent.categories.map(
                              (eventCate, category) => {
                                return (
                                  <>
                                    <p className="location" key={category}>
                                      {eventCate.vCategory}
                                    </p>
                                    <p className="point-location">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-geo-alt-fill"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                                      </svg>
                                      &nbsp;
                                      {recommendEvent.vLocation}
                                    </p>
                                  </>
                                );
                              }
                            )}
                          {/* <p className="location">{recommendEvent.vEvent} - {recommendEvent.vLocation}</p> */}
                          <p className="attendees">
                            {recommendEvent.attendees_count} attendees
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })
            ) : (
              <p>No Data Found</p>
            )}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default SimilarEvent;

// --- Extra Code ---
/* <>
   <div className="col-lg-3 col-md-6">
      <div className="event-box">
         <div className="img-box">
            <img src="/assets/images/event-2.png" alt="contact-us" height="210" width="320" />
            <a href="/Event-detail" className="redirect-btn"><svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37" fill="none">
               <circle cx="18.5" cy="18.5" r="18.5" fill="white" />
               <path d="M20.3889 15.7333V12L27 18.5333L20.3889 25.0667V21.3333C15.6667 21.3333 12.3611 22.7333 10 26L10.7556 23.2L10.9444 22.8267C11.7019 20.8975 12.9855 19.2135 14.6526 17.9614C16.3197 16.7093 18.3052 15.9381 20.3889 15.7333Z" fill="#616161" />
            </svg>
            </a>
         </div>
         <div className="contain">
            <p className="date-time"><span>Fri, Aug 25 </span> · <span>4:30 Am</span></p>
            <h2>Lorem ipsum dolor sit amet consectetur Eget vive urna.</h2>
            <p className="location">The Side Hustle - New York, NY</p>
            <p className="attendees">27 attendees</p>
         </div>
      </div>
   </div>
   <div className="col-lg-3 col-md-6">
      <div className="event-box">
         <div className="img-box">
            <img src="/assets/images/event-1.png" alt="contact-us" height="210" width="320" />
            <a href="/Event-detail" className="redirect-btn"><svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37" fill="none">
               <circle cx="18.5" cy="18.5" r="18.5" fill="white" />
               <path d="M20.3889 15.7333V12L27 18.5333L20.3889 25.0667V21.3333C15.6667 21.3333 12.3611 22.7333 10 26L10.7556 23.2L10.9444 22.8267C11.7019 20.8975 12.9855 19.2135 14.6526 17.9614C16.3197 16.7093 18.3052 15.9381 20.3889 15.7333Z" fill="#616161" />
            </svg>
            </a>
         </div>
         <div className="contain">
            <p className="date-time"><span>Fri, Aug 25 </span> · <span>4:30 Am</span></p>
            <h2>Lorem ipsum dolor sit amet consectetur Eget vive urna.</h2>
            <p className="location">The Side Hustle - New York, NY</p>
            <p className="attendees">27 attendees</p>
         </div>
      </div>
   </div>
   <div className="col-lg-3 col-md-6">
      <div className="event-box">
         <div className="img-box">
            <img src="/assets/images/event-3.png" alt="contact-us" height="210" width="320" />
            <a href="/Event-detail" className="redirect-btn"><svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37" fill="none">
               <circle cx="18.5" cy="18.5" r="18.5" fill="white" />
               <path d="M20.3889 15.7333V12L27 18.5333L20.3889 25.0667V21.3333C15.6667 21.3333 12.3611 22.7333 10 26L10.7556 23.2L10.9444 22.8267C11.7019 20.8975 12.9855 19.2135 14.6526 17.9614C16.3197 16.7093 18.3052 15.9381 20.3889 15.7333Z" fill="#616161" />
            </svg>
            </a>
         </div>
         <div className="contain">
            <p className="date-time"><span>Fri, Aug 25 </span> · <span>4:30 Am</span></p>
            <h2>Lorem ipsum dolor sit amet consectetur Eget vive urna.</h2>
            <p className="location">The Side Hustle - New York, NY</p>
            <p className="attendees">27 attendees</p>
         </div>
      </div>
   </div>
</> */
