import React from "react";
import Container from "../components/layouts/Container";
import Add from "../components/MyEvent/Add";
import { useNavigate } from "react-router";

const AddEventPage = () => {
  const navigate = useNavigate();
  const isAuthenticated =
    // sessionStorage.getItem("vUniqueCode") ||
    localStorage.getItem("vUniqueCode");

  React.useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);
  return (
    <Container>
      <Add />
    </Container>
  );
};

export default AddEventPage;
