export const CUSTOMER_REGISTER_REQUEST = 'CUSTOMER_REGISTER_REQUEST'
export const CUSTOMER_REGISTER_SUCCESS = 'CUSTOMER_REGISTER_SUCCESS'
export const CUSTOMER_REGISTER_FAILURE = 'CUSTOMER_REGISTER_FAILURE'

export const CUSTOMER_LOGIN_REQUEST = 'CUSTOMER_LOGIN_REQUEST'
export const CUSTOMER_LOGIN_SUCCESS = 'CUSTOMER_LOGIN_SUCCESS'
export const CUSTOMER_LOGIN_FAILURE = 'CUSTOMER_LOGIN_FAILURE'

export const FORGET_PASSWORD_REQUEST = 'FORGET_PASSWORD_REQUEST'
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS'
export const FORGET_PASSWORD_FAILURE = 'FORGET_PASSWORD_FAILURE'

export const VERIFY_EMAIL_REQUEST = 'VERIFY_EMAIL_REQUEST'
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS'
export const VERIFY_EMAIL_FAILURE = 'VERIFY_EMAIL_FAILURE'

export const CUSTOMER_PROFILE_REQUEST = 'CUSTOMER_PROFILE_REQUEST'
export const CUSTOMER_PROFILE_SUCCESS = 'CUSTOMER_PROFILE_SUCCESS'
export const CUSTOMER_PROFILE_FAILURE = 'CUSTOMER_PROFILE_FAILURE'

export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST'
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE'

export const PROFILE_UPDATE_REQUEST = 'PROFILE_UPDATE_REQUEST'
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS'
export const PROFILE_UPDATE_FAILURE = 'PROFILE_UPDATE_FAILURE'

export const CUSTOMER_SERVICE_REQUEST = 'CUSTOMER_SERVICE_REQUEST'
export const CUSTOMER_SERVICE_SUCCESS = 'CUSTOMER_SERVICE_SUCCESS'
export const CUSTOMER_SERVICE_FAILURE = 'CUSTOMER_SERVICE_FAILURE'

export const UPDATE_CUSTOMER_SERVICE_REQUEST = 'UPDATE_CUSTOMER_SERVICE_REQUEST'
export const UPDATE_CUSTOMER_SERVICE_SUCCESS = 'UPDATE_CUSTOMER_SERVICE_SUCCESS'
export const UPDATE_CUSTOMER_SERVICE_FAILURE = 'UPDATE_CUSTOMER_SERVICE_FAILURE'

export const CUSTOMER_EVENT_REQUEST = 'CUSTOMER_EVENT_REQUEST'
export const CUSTOMER_EVENT_SUCCESS = 'CUSTOMER_EVENT_SUCCESS'
export const CUSTOMER_EVENT_FAILURE = 'CUSTOMER_EVENT_FAILURE'

export const GOOGLE_REGISTER_REQUEST = 'GOOGLE_REGISTER_REQUEST'
export const GOOGLE_REGISTER_SUCCESS = 'GOOGLE_REGISTER_SUCCESS'
export const GOOGLE_REGISTER_FAILURE = 'GOOGLE_REGISTER_FAILURE'

export const CUSTOMER_ACCOUNT_DELETE_REQUEST = 'CUSTOMER_ACCOUNT_DELETE_REQUEST'
export const CUSTOMER_ACCOUNT_DELETE_SUCCESS = 'CUSTOMER_ACCOUNT_DELETE_SUCCESS'
export const CUSTOMER_ACCOUNT_DELETE_FAILURE = 'CUSTOMER_ACCOUNT_DELETE_FAILURE'