import React from "react";
import Resetpassword from "../components/Common/Resetpassword";

const ForgetPasswordPage = () => {
  return (

    <Resetpassword />

  );
}

export default ForgetPasswordPage;
