import React from "react";
import Container from "../components/layouts/Container";
import PrivacyPolicy from "../components/Common/PrivacyPolicy";
import { useLocation } from "react-router";

const PrivacyPolicyPage = () => {
  const location = useLocation();
  React.useEffect(() => {
    document.title = "Privacy-policy | WellNas";
  }, [location]);

  return (
    <React.Fragment>
      <div className="dark-header">
        <Container>
          <PrivacyPolicy />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PrivacyPolicyPage;
