import React from "react";
import AttendedEvent from "../components/Profile/AttendedEvent";
import Container from "../components/layouts/Container";
import { useNavigate } from "react-router";

const AttendedEventPage = () => {
  const navigate = useNavigate();
  const isAuthenticated =
    // sessionStorage.getItem("vUniqueCode") ||
    localStorage.getItem("vUniqueCode");

  React.useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);
  return (
    <Container>
      <div className="profile-layer">
        <AttendedEvent />
      </div>
    </Container>
  );
};

export default AttendedEventPage;
