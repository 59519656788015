import React from "react";
import Forgetpassword from "../components/Common/Forgetpassword";

const ForgetPasswordPage = () => {
  return (

    <Forgetpassword />

  );
}

export default ForgetPasswordPage;
