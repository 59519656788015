import React from "react";
import Container from "../components/layouts/Container";
import UserInformation from "../components/Profile/UserInformation";
// import MyEvent from "../components/Profile/MyEvent";
import { useNavigate } from "react-router";



const UserInformationPage = () => {
   const navigate = useNavigate();
   const isAuthenticated =
     // sessionStorage.getItem("vUniqueCode") ||
     localStorage.getItem("vUniqueCode");
 
   React.useEffect(() => {
     if (!isAuthenticated) {
       navigate("/");
     }
   }, [isAuthenticated]);
   return (
      <Container>
         <div className="profile-layer">
            <div className="profile-page">
               <div className="container-fluid">
                  <UserInformation />
               </div>
            </div>
         </div>
      </Container>
   );
}

export default UserInformationPage;