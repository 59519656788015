import {
    SETTING_LIST_REQUEST,
    SETTING_LIST_SUCCESS,
    SETTING_LIST_FAILURE,

    NOTIFICATION_UPDATE_REQUEST,
    NOTIFICATION_UPDATE_SUCCESS,
    NOTIFICATION_UPDATE_FAILURE,

} from "../constants/settingConstant";

export function settingListReducer(state = {}, action) {
    switch (action.type) {
        case SETTING_LIST_REQUEST:
            return {
                loading: true,
            }
        case SETTING_LIST_SUCCESS:
            return {
                loading: false,
                settingList: action.payload,
            }
        case SETTING_LIST_FAILURE:
            return {
                loading: false,
            }
        default:
            return state
    }
};

export function notificationUpdateReducer(state = {}, action) {
    switch (action.type) {
        case NOTIFICATION_UPDATE_REQUEST:
            return {
                loading: true,
            }
        case NOTIFICATION_UPDATE_SUCCESS:
            return {
                loading: false,
                notificationUpdate: action.payload,
            }
        case NOTIFICATION_UPDATE_FAILURE:
            return {
                loading: false,
            }
        default:
            return state
    }
};