import {
    JOIN_NOW_REQUEST,
    JOIN_NOW_SUCCESS,
    JOIN_NOW_FAILURE,

    UNDO_EVENT_REQUEST,
    UNDO_EVENT_SUCCESS,
    UNDO_EVENT_FAILURE,

} from "../constants/attendeesConstant";

import axios from "axios";


export const getJoinNow = (data) => async (dispatch) => {
    try {
        dispatch({ type: JOIN_NOW_REQUEST });
        const resData = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/event_details/join_now`, data
        );
        dispatch({ type: JOIN_NOW_SUCCESS, payload: resData.data.data });
    } catch (err) {
        dispatch({ type: JOIN_NOW_FAILURE });
    }
};

export const getUndoEvent = (data) => async (dispatch) => {
    try {
        dispatch({ type: UNDO_EVENT_REQUEST });
        const resData = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/event_details/delete`, data
        );
        dispatch({ type: UNDO_EVENT_SUCCESS, payload: resData.data.data });
    } catch (err) {
        dispatch({ type: UNDO_EVENT_FAILURE });
    }
};