import React from "react";
import "./Skeleton.css"; // Import the corresponding CSS file for styling

const SkeletonMyEventList = ({ count }) => {
  const skeletons = Array.from({ length: count }, (_, index) => (
    <div key={index} className="skeleton-card">
      <div className="skeleton-avatar"></div>
      <div className="skeleton-rectangular-text"></div>
      <div className="skeleton-text"></div>
      <div className="skeleton-last-text"></div>
      <div className="skeleton-rounded-text"></div>
      <div className="skeleton-rounded"></div>
    </div>
  ));

  return <div className="skeleton-container">{skeletons}</div>;
};

export default SkeletonMyEventList;
