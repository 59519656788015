import React from "react";
import Container from "../components/layouts/Container";
import JoinNowForm from "../components/Common/JoinNowForm";
import { useNavigate } from "react-router";

function JoinNowPage() {
  const navigate = useNavigate();
  const isAuthenticated =
    // sessionStorage.getItem("vUniqueCode") ||
    localStorage.getItem("vUniqueCode");

  React.useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);
  return (
    <Container>
      <JoinNowForm />
    </Container>
  );
}

export default JoinNowPage;
